<template>
  <AppLayout>
    <div class="lg:flex lg:h-full lg:flex-col">
      <header class="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          <time :datetime="currentMonth">{{ currentMonthFormatted }}</time>
        </h1>
        <div class="flex items-center">
          <div class="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button type="button" @click="previousMonth"
              class="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50">
              <span class="sr-only">Previous month</span>
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button type="button" @click="gotoToday"
              class="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">
              Aujourd'hui
            </button>
            <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button type="button" @click="nextMonth"
              class="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50">
              <span class="sr-only">Next month</span>
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div class="hidden md:ml-4 md:flex md:items-center">
            <div class="ml-6 h-6 w-px bg-gray-300" />
            <button type="button" @click="gotoTrainings"
              class="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
              Formations
            </button>
          </div>
        </div>
      </header>
      <div>
        <MonthView :days="days" :selectedDay="selectedDay" />
      </div>
    </div>
    <BigLoader v-if="isLoading" title="Chargement du calendrier en cours" text="" />
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import MonthView from '@/components/calendar/MonthView.vue';
import BigLoader from '@/components/app/BigLoader.vue';

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/vue/20/solid';
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import { useRouter } from 'vue-router';
import client from '@/api/client';

const router = useRouter();

const gotoTrainings = () => {
  router.push({ name: 'Liste des formations' });
};
const isLoading = ref(false);
const relevantTrainings = ref([]);
const currentDate = new Date();
const currentMonth = ref(currentDate.toISOString().split('T')[0].substring(0, 7));
const currentMonthFormatted = computed(() => {
  const formatter = new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: 'long' });
  return formatter.format(new Date(currentMonth.value));
});

const days = ref([]);

const getDayOfWeek = (date) => {
  const day = date.getDay();
  return (day === 0) ? 6 : day - 1;
};

const getDaysofCurrentMonth = () => {
  const [year, month] = currentMonth.value.split('-').map(Number);
  const firstDay = new Date(year, month - 1, 1);
  const lastDay = new Date(year, month, 0);
  const daysInMonth = lastDay.getDate();
  const firstDayIndex = getDayOfWeek(firstDay);
  const lastDayIndex = lastDay.getDay();
  const prevLastDay = new Date(year, month - 1, 0);
  const prevLastDayIndex = prevLastDay.getDay();
  const nextDays = 7 - lastDayIndex - 1;

  const daysArray = [];
  for (let x = firstDayIndex; x > 0; x -= 1) {
    daysArray.push({
      date: `${year}-${String(month - 1).padStart(2, '0')}-${String(prevLastDay.getDate() - x + 1).padStart(2, '0')}`,
      isCurrentMonth: false,
      isToday: false,
      isSelected: false,
      events: [],
    });
  }

  for (let i = 1; i <= daysInMonth; i += 1) {
    const date = `${year}-${String(month).padStart(2, '0')}-${String(i).padStart(2, '0')}`;
    daysArray.push({
      date,
      isCurrentMonth: true,
      isToday: date === currentDate.toISOString().split('T')[0],
      isSelected: false,
      events: [],
    });
  }

  for (let j = 1; j <= nextDays; j += 1) {
    daysArray.push({
      date: `${year}-${String(month + 1).padStart(2, '0')}-${String(j).padStart(2, '0')}`,
      isCurrentMonth: false,
      isToday: false,
      isSelected: false,
      events: [],
    });
  }
  for (const day of daysArray) {
    const dayEvents = relevantTrainings.value.find(date => date.date === day.date)?.trainings || [];
    day.events = dayEvents;
  }

  days.value = daysArray;
  isLoading.value = false;
};

const previousMonth = () => {
  const [year, month] = currentMonth.value.split('-').map(Number);
  const previousMonthDate = new Date(year, month - 1, 1);
  currentMonth.value = previousMonthDate.toISOString().split('T')[0].substring(0, 7);
  fetchMonthTrainings(previousMonthDate.getMonth(), new Date(previousMonthDate).getFullYear());
};

const nextMonth = () => {
  const [year, month] = currentMonth.value.split('-').map(Number);
  const nextMonthDate = new Date(year, month, 1);
  currentMonth.value = `${nextMonthDate.getFullYear()}-${String(nextMonthDate.getMonth() + 1).padStart(2, '0')}`;
  fetchMonthTrainings(nextMonthDate.getMonth() + 1, new Date(nextMonthDate).getFullYear());
};

const selectedDay = ref(days.value[2]);

onMounted(() => {
  fetchMonthTrainings(currentMonth.value.split('-')[1], currentMonth.value.split('-')[0]);
});

const gotoToday = () => {
  currentMonth.value = currentDate.toISOString().split('T')[0].substring(0, 7);
  getDaysofCurrentMonth();
};

const fetchMonthTrainings = async (month, year) => {
  isLoading.value = true;
  try {
    const response = await client.get(`api/trainings/month/${month}/${year}`);
    relevantTrainings.value = response.data;
    getDaysofCurrentMonth();
  } catch (error) {
    console.error('Failed to fetch trainings:', error);
  }
};

</script>
