<template>
    <BigLoader v-if="isLoading" title="Chargement des formations" text="" />
    <AppLayout>
        <div class="mx-auto max-w-10xl px-4 sm:px-20 lg:px-8 mt-12 mb-12">
            <div class="lg:flex w-full items-center">
                <h1 class="text-base font-semibold leading-6 text-gray-900 mb-10">
                    Catalogue de formations
                </h1>
                <div class="mb-10 lg:max-w-96 ml-0 lg:ml-auto">
                    <Listbox as="div" v-model="categorySelected" @update:model-value="changePage(1)"
                        v-if="relevantTrainingCategories.length">
                        <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">
                        </ListboxLabel>
                        <div class="relative mt-2">
                            <ListboxButton
                                class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span class="block truncate" v-if="categorySelected">{{ categorySelected.name }}</span>
                                <span v-else class="text-gray-500">Sélectionnez une catégorie</span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100"
                                leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions
                                    class="absolute z-1000 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption as="template" v-slot="{ active, selected }">
                                        <li
                                            :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                            <span
                                                :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">Toutes</span>
                                            <span v-if="selected"
                                                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </li>
                                    </ListboxOption>
                                    <ListboxOption as="template" v-for="itemList in relevantTrainingCategories"
                                        :key="itemList.id" :value="itemList" v-slot="{ active, selected }">
                                        <li
                                            :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                            <span
                                                :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                                    itemList.name }}</span>
                                            <span v-if="selected"
                                                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </li>
                                    </ListboxOption>

                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>
            </div>
            <div class="mx-auto max-w-10xl">
                <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:grid-cols-3 xl:gap-x-8">
                    <li v-for="training in relevantTrainings" :key="training.id"
                        class="overflow-hidden rounded-xl border border-gray-200">
                        <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                            <img :src="require(`@/assets/img/icons/iconTraining${training.category?.id}.svg`)"
                                :alt="training.title"
                                class="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
                            <div class="text-sm font-semibold leading-5 text-gray-900"><router-link
                                    :to="'/formations/' + training.id">{{ training.title }}</router-link></div>
                            <div>
                            </div>
                        </div>
                        <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div class="flex justify-between gap-x-4 py-3">
                                <dt class="text-gray-500">Catégorie</dt>
                                <dd class="text-gray-700 text-right">{{ training.category?.name }}</dd>
                            </div>
                            <div class="flex justify-between gap-x-4 py-3">
                                <dt class="text-gray-500">Syndicat</dt>
                                <dd class="text-gray-700 text-right">
                                    <span class="status status4 mr-2 mb-1" v-for="union in training.unions"
                                        :key="union.id">{{
                                            union.name
                                        }}</span>

                                </dd>
                            </div>
                            <div class="flex justify-between gap-x-4 py-3">
                                <dt class="text-gray-500">Formation réservée</dt>
                                <dd class="text-gray-700 text-right">
                                    <span class="status" :class="training.reserved ? 'status3' : 'status5'"> {{
                                        training.reserved ? 'réservée' : 'non réservée' }}</span>
                                </dd>
                            </div>
                            <div class="flex justify-between gap-x-4 py-3">
                                <dt class="text-gray-500">Localisation</dt>
                                <dd class="text-gray-700 text-right">{{ training.place?.name }}</dd>
                            </div>
                            <div class="flex justify-between gap-x-4 py-3">
                                <dt class="text-gray-500">Début</dt>
                                <dd class="text-gray-700">
                                    {{ moment(training.start_date).format("Do MMM YY") }}
                                </dd>
                            </div>
                            <div class="flex justify-between gap-x-4 py-3">
                                <dt class="text-gray-500">Fin</dt>
                                <dd class="text-gray-700">{{ moment(training.end_date).format("Do MMM YY") }}

                                </dd>
                            </div>
                            <div class="w-full mb-4 mt-4 pt-4">
                                <router-link :to="'/inscription/' + training.id"
                                    v-if="profile?.role?.slug === 'stagiaire'"><button
                                        class="btn-secondary relative ml-auto">m'inscrire</button></router-link>
                            </div>
                        </dl>
                    </li>
                </ul>
                <AlertMessage type="info" title="Aucune formation n'est disponible pour cette catégorie"
                    v-if="relevantTrainings.length === 0" />
            </div>
            <Pagination class="mt-12" :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
        </div>
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import {
    Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import { EllipsisHorizontalIcon } from '@heroicons/vue/20/solid';
import Pagination from '@/components/lists/PaginationTables.vue';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import client from '@/api/client';
import BigLoader from '@/components/app/BigLoader.vue';
import moment from 'moment';
import 'moment/locale/fr';
import SelectComponent from '@/components/form/SelectComponent.vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

moment.locale('fr');

// Pagination data
const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = 20;
const totalItems = ref(0);
const isLoading = ref(false);
const selectedTraining = ref([]);
const profile = ref([]);
const relevantTrainingCategories = ref([]);
const componentKey = ref(0);
const categorySelected = ref(null);

const statuses = {
    Prévue: 'text-green-700 bg-green-50 ring-green-600/20',
    Ouverte: 'text-orange-600 bg-orange-50 ring-orange-500/10',
    Fermée: 'text-red-700 bg-red-50 ring-red-600/10',
};
const displayRegistrationDialog = ref(false);
const relevantTrainings = ref([]);

const changePage = async (page) => {
    const params = new URLSearchParams();
    isLoading.value = true;
    params.append('page', page);
    params.append('per_page', pageSize);
    if (categorySelected.value) {
        params.append('category_id', categorySelected.value.id);
    }
    try {
        const response = await client.get(`/api/catalog/trainings?${params}`);
        relevantTrainings.value = response.data.data;
        currentPage.value = response.data.current_page;
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
        isLoading.value = false;
        return response.data;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};

onMounted(() => {
    fetchTrainingsServices.fetchTrainingCategories().then((data) => {
        relevantTrainingCategories.value = data;
    });
    profile.value = JSON.parse(localStorage.getItem('user'));
    changePage(currentPage.value);
});
</script>
