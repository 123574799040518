<template>
  <nav></nav>
  <router-view :key="$route.fullPath" />
</template>

<style lang="scss">
#app {
  font-family: Sora, sans-serif;
}
</style>
