<template>
  <BigLoader v-if="isLoading" title="Chargement des stagiaires" text="" />
  <AppLayout>
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">
            Stagiaires
          </h1>
          <p class="mt-2 text-sm text-gray-700">
            Vous trouverez, ici la liste de tous les stagiaires enregistrés dans
            l'application.
          </p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        </div>
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Nom
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Syndicat actuel
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Mandat(s)
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Entreprise
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Taille de l'entreprise
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Détails</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="trainee in trainees" :key="trainee.id">
                  <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div class="flex items-center">
                      <div class="h-11 w-11 flex-shrink-0">
                        <template v-if="trainee.temporary_profile_picture_url">
                          <img class="h-11 w-11 rounded-full object-cover" :src="trainee.temporary_profile_picture_url"
                            alt="" />
                        </template>
                        <template v-else>
                          <UserCircleIcon class="h-11 w-11 text-gray-400" />
                        </template>
                      </div>
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          {{ trainee.user.name }}
                        </div>
                        <div class="mt-1 text-gray-500">{{ trainee.user.email }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div class="mt-1 text-gray-500">
                      {{ trainee.current_union?.name }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div class="mt-1 text-gray-500">
                      <ul v-if="trainee.mandates.length > 0">
                        <li v-for="mandate in trainee.mandates" :key="mandate.id"><span
                            v-if="mandate.pivot?.is_valid === 1">{{
                            mandate.name }}</span></li>
                      </ul>
                      <span v-else>Aucun mandat</span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    {{ trainee.company_name }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    {{ trainee.company_size?.name }}
                  </td>
                  <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <a href="#" @click.prevent="goToTraineeDetails(trainee.id)">Détails<span class="sr-only">, {{
                        trainee.user.name
                        }}</span></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
  </AppLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import { useRouter } from 'vue-router';
import Pagination from '@/components/lists/PaginationTables.vue';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/outline';
import BigLoader from '@/components/app/BigLoader.vue';

const trainees = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = 20;
const isLoading = ref(false);
const router = useRouter();

const fetchTrainees = async (page = 1) => {
  isLoading.value = true;
  try {
    const response = await client.get('/api/users/by-role/stagiaire', {
      params: { page, per_page: pageSize },
    });
    trainees.value = response.data.data;
    currentPage.value = response.data.current_page;
    totalPages.value = response.data.last_page;
    isLoading.value = false;
  } catch (error) {
    console.error('Failed to fetch trainees:', error);
  }
};

const goToTraineeDetails = (id) => {
  router.push({ name: 'Détails du stagiaire', params: { id } });
};

const changePage = (page) => {
  fetchTrainees(page);
};

onMounted(() => {
  fetchTrainees();
});
</script>
