<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    style="z-index:3000" :detail="notificationMessage" @close="showNotification = false" />
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="$emit('close')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ title }} de {{
                    teacher.user?.name }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ message }}</p>
                  </div>

                  <div class="text-left mt-10 mb-10">
                    <div class="sm:col-span-3">
                      <label for="needs_accommodation" class="block text-sm font-medium leading-6 text-gray-900">A
                        besoin d'un hébergement
                      </label>
                      <div class="mt-2">
                        <SwitchComponent v-model="needs_accommodation" :trueValue="'Oui'" :falseValue="'Non'"
                          :key="componentKey" />
                      </div>
                    </div>
                  </div>
                  <div class="text-left mt-10 mb-10">
                    <div class="sm:col-span-3">
                      <label for="reimbursed_transportation" class="block text-sm font-medium leading-6 text-gray-900">A
                        besoin d'un remboursement des frais de transport
                      </label>
                      <div class="mt-2">
                        <SwitchComponent v-model="reimbursed_transportation" :trueValue="'Oui'" :falseValue="'Non'"
                          :key="componentKey" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:gap-3">
                <button type="button" v-if="primaryButtonLabel"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="attachTeacher">{{ primaryButtonLabel }}</button>
                <button type="button" v-if="secondaryButtonLabel"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  @click="closeDialog" ref="cancelButtonRef">{{ secondaryButtonLabel }}</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/24/outline';
import SwitchComponent from '@/components/form/SwitchComponent.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';

const emit = defineEmits(['close', 'confirm', 'error']);

/* eslint-disable */
const props = defineProps({
  title: String,
  message: String,
  primaryButtonLabel: String,
  secondaryButtonLabel: String,
  teacher: [Array, Object],
  trainingId: [Number, String],
  type: {
    type: String,
    required: true,
  },
});

const open = ref(true);
const titleTraining = ref(null);
const componentKey = ref(0);
const needs_accommodation = ref(false);
const reimbursed_transportation = ref(false);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const closeDialog = () => {
  emit('close')
};

const isFilled = computed(() => {
  return needs_accommodation.value !== null && reimbursed_transportation.value !== null;
});

const saveReimbursmentInfos = () => {
  emit('confirm', props.teacher.id, needs_accommodation.value, reimbursed_transportation.value);
};

const attachTeacher = async () => {
  let urlws = '';
  if (props.type === 'intervenant') {
    urlws = `api/trainings/${props.trainingId}/teachers`;
  } else if (props.type === 'responsable-pedagogique') {
    urlws = `api/trainings/${props.trainingId}/pedagogical-supervisors`;
  }
  try {
    const response = await client.post(`${urlws}`, {
      profile_id: props.teacher.id,
      reimbursed_transportation: reimbursed_transportation.value,
      needs_accommodation: needs_accommodation.value,
      ares_status_id: null,
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value = '';
    emit('confirm', props.type);
  } catch (error) {
    if (error.response.data) {
      emit('error', error.response.data?.message);
    } else {
      emit('error');
    }
    emit('close');
  }
};

onMounted(() => {

});
</script>
