<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="$emit('close')">
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Filtrage</DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="$emit('close')">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Former</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Vous pouvez filtrer ici la liste des
                                                    formateurs.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <div>
                                                        <div class="mt-2">
                                                            <Combobox as="div" v-model="selectedTraining"
                                                                @update:modelValue="filterTrainings">
                                                                <ComboboxLabel
                                                                    class="block text-sm font-medium leading-6 text-gray-900">
                                                                    Par formation</ComboboxLabel>
                                                                <div class="relative mt-2">
                                                                    <ComboboxInput
                                                                        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        @change="queryFormation = $event.target.value; searchTraining($event.target.value)"
                                                                        :display-value="(formation) => formation?.title" />
                                                                    <ComboboxButton
                                                                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                                        <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                                            aria-hidden="true" />
                                                                    </ComboboxButton>

                                                                    <ComboboxOptions v-if="filteredTrainings.length > 0"
                                                                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                        <ComboboxOption
                                                                            v-for="formation in filteredTrainings"
                                                                            :key="formation.id" :value="formation"
                                                                            as="template" v-slot="{ active, selected }">
                                                                            <li
                                                                                :class="['relative cursor-default select-none py-2 pl-8 pr-4', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                                                <span
                                                                                    :class="['block truncate', selected && 'font-semibold']">
                                                                                    {{ formation.title }}
                                                                                </span>

                                                                                <span v-if="selected"
                                                                                    :class="['absolute inset-y-0 left-0 flex items-center pl-1.5', active ? 'text-white' : 'text-indigo-600']">
                                                                                    <CheckIcon class="h-5 w-5"
                                                                                        aria-hidden="true" />
                                                                                </span>
                                                                            </li>
                                                                        </ComboboxOption>
                                                                    </ComboboxOptions>
                                                                </div>
                                                            </Combobox>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="mt-2">
                                                            <Combobox as="div" v-model="selectedTeacher"
                                                                @update:modelValue="filterTrainings">
                                                                <ComboboxLabel
                                                                    class="block text-sm font-medium leading-6 text-gray-900">
                                                                    Par formateur</ComboboxLabel>
                                                                <span></span>
                                                                <div class="relative mt-2">
                                                                    <ComboboxInput
                                                                        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        @change="queryTeacher = $event.target.value; searchPerson($event.target.value)"
                                                                        :display-value="(teacher) => teacher?.user.name" />
                                                                    <ComboboxButton
                                                                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                                        <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                                            aria-hidden="true" />
                                                                    </ComboboxButton>

                                                                    <ComboboxOptions
                                                                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                        <ComboboxOption
                                                                            v-for="teacher in filteredPeople"
                                                                            :key="teacher.id" :value="teacher"
                                                                            as="template" v-slot="{ active, selected }">
                                                                            <li
                                                                                :class="['relative cursor-default select-none py-2 pl-8 pr-4', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                                                <span
                                                                                    :class="['block truncate', selected && 'font-semibold']">
                                                                                    {{ teacher.user.name }}
                                                                                </span>

                                                                                <span v-if="selected"
                                                                                    :class="['absolute inset-y-0 left-0 flex items-center pl-1.5', active ? 'text-white' : 'text-indigo-600']">
                                                                                    <CheckIcon class="h-5 w-5"
                                                                                        aria-hidden="true" />
                                                                                </span>
                                                                            </li>
                                                                        </ComboboxOption>
                                                                    </ComboboxOptions>
                                                                </div>
                                                            </Combobox>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label for="filter-start-date"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Par
                                                            date de début de formation</label>
                                                        <div class="mt-2">
                                                            <input type="date" id="start-date" name="start-date"
                                                                v-model="paramsSearch.start_date"
                                                                @change="filterTrainings"
                                                                class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label for="filter-end-date"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Par
                                                            date de fin de formation</label>
                                                        <div class="mt-2">
                                                            <input type="date" id="end-date" name="end-date"
                                                                v-model="paramsSearch.end_date"
                                                                @change="filterTrainings"
                                                                class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label for="status"
                                                            class="block text-sm font-medium text-gray-900">Par statut
                                                            Ares</label>
                                                        <select id="status" name="status"
                                                            class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                                                            v-model="paramsSearch.ares_status_ids"
                                                            @change="filterTrainings">
                                                            <option value="">Tous</option>
                                                            <option v-for="status in aresStatuses" :key="status.value"
                                                                :value="status.id">{{ status.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                        <button type="button" @click.prevent="clearFilters"
                                            class="btn-secondary mr-4">Effacer</button>
                                        <button type="button"
                                            class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            @click="$emit('close')">Annuler</button>
                                        <button type="button" @click.prevent="filterTrainings"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Filtrer</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, defineEmits, onMounted, watch } from 'vue';
import {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { debounce } from 'lodash';
import client from '@/api/client';
import fetchTrainingsServices from '@/services/TrainingsServices.js';

const model = defineModel();
const emit = defineEmits(['update:modelValue', 'close', 'filter', 'clear']);

const open = ref(true);

const queryFormation = ref('');
const selectedTraining = ref(null);
const filteredTrainings = ref([]);

const queryTeacher = ref('');
const selectedTeacher = ref(null);
const filteredPeople = ref([]);

const paramsSearch = ref({
    training_id: null,
    profile_id: null,
    start_date: null,
    end_date: null,
    ares_status_ids: [],
});

const clearFilters = () => {
    selectedTeacher.value = null;
    selectedTraining.value = null;
    paramsSearch.value = {
        training_id: null,
        profile_id: null,
        startDate: null,
        endDate: null,
        ares_status_ids: null,
    };
    filterTrainings();
};

const searchPerson = debounce(async (keyword) => {
    try {
        const response = await client.get(`api/profiles-search?name=${keyword}&role_ids=[3,4]`);
        filteredPeople.value = response.data;
    } catch (error) {
        console.error('Failed to fetch persons', error);
    }
}, 600);

const searchTraining = debounce(async (keyword) => {
    fetchTrainingsServices.fetchTrainingsBySearchTerm(1, 100, keyword).then((response) => {
        console.log(response.data);
        filteredTrainings.value = response.data;
    });
}, 600);

const aresStatuses = ref([]);

const filterTrainings = () => {
    if (selectedTeacher.value) {
        paramsSearch.value.profile_id = selectedTeacher.value.id;
    }
    if (selectedTraining.value) {
        paramsSearch.value.training_id = selectedTraining.value.id;
    }
    emit('update:modelValue', paramsSearch.value);
    emit('filter', paramsSearch.value);
};

onMounted(() => {
    paramsSearch.value = model.value;
    fetchTrainingsServices.fetchAresStatuses().then((data) => {
        aresStatuses.value = data;
    });
    if (model.value.profile_id) {
        selectedTeacher.value = filteredPeople.value.find((person) => person.id === model.value.profile_id);
    }
    if (model.value.training_id) {
        fetchTrainingsServices.fetchTraining(model.value.training_id).then((response) => {
            filteredTrainings.value = response.data;
            const trainingSelected = [{
                id: model.value.training_id,
                title: response.data.title,
            }];
            selectedTraining.value.push(trainingSelected);
        });
    }
});
</script>
