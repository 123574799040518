<template>
  <div class="mt-12">
    <h3 class="text-base font-semibold leading-6 text-gray-900">Indicateurs financiers</h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div v-for="item in stats" :key="item.name" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">{{ item.name }}</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ item.stat }}</dd>
      </div>
    </dl>
  </div>
  <div class="mt-12 grid grid-cols-1 sm:grid-cols-2 gap-5">
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900">Participation</h3>
      <PieChart :chart-data="attendanceData" />
    </div>
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900">Hébergement</h3>
      <DoughnutChart :chart-data="hostingData" />
    </div>
  </div>
  <div class="mt-12">
    <h3 class="text-base font-semibold leading-6 text-gray-900">Frais</h3>
    <PolarAreaChart :chart-data="expensesData" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { PieChart, DoughnutChart, PolarAreaChart } from 'vue-chart-3';
import {
  Chart, DoughnutController, ArcElement, Tooltip, Legend, PieController, PolarAreaController, RadialLinearScale,
} from 'chart.js';

Chart.register(DoughnutController, PieController, ArcElement, Tooltip, Legend, PolarAreaController, RadialLinearScale);

const stats = [
  { name: 'Nombre de participants', stat: '45' },
  { name: 'Présents', stat: '42' },
  { name: 'Absents', stat: '3' },
  { name: 'Facturation', stat: '€ 2,500.00' },
  { name: 'Dépenses', stat: '€ 1,000.00' },
  { name: 'Bénéfice', stat: '€ 1,500.00' },
];

const totalParticipants = 45;
const presentParticipants = 42;
const absentParticipants = 3;

const participationRate = ((presentParticipants / totalParticipants) * 100).toFixed(2);

const participationData = [
  { name: `Présents (${participationRate}%)`, value: presentParticipants },
  { name: 'Absents', value: absentParticipants },
];

const attendanceData = ref({
  labels: participationData.map((item) => item.name),
  datasets: [
    {
      data: participationData.map((item) => item.value),
      backgroundColor: ['#240771', '#9183B8', '#DED9E9'],
    },
  ],
});

const chartData = ref([
  { name: 'Coût prévisionnel', value: 50 },
  { name: 'Coût réel', value: 50 },
]);

const hostingData = ref({
  labels: chartData.value.map((item) => item.name),
  datasets: [
    {
      data: chartData.value.map((item) => item.value),
      backgroundColor: ['#8AE7DC', '#60A19A'],
    },
  ],
});

const expensesData = ref({
  labels: ['Total frais payés', 'Moyenne par apprenant', 'Total frais repas soir', 'Total frais hébergement', 'Total frais transports'],
  datasets: [
    {
      label: 'Frais',
      data: [5000, 111.11, 1500, 2000, 1500],
      backgroundColor: ['#FF6384', '#A2D2DF', '#334C66', '#405459', '#6699CC'],
    },
  ],
});
</script>
