/* eslint-disable */
import client from '@/api/client';

async function fetchTrainingExpenseStatuses() {
  try {
    const response = await client.get('/api/training-expense-statuses');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch expesnes statuses:', error);
  }
};

async function fetchTrainingExpensesByTraining(id) {
  try {
    const response = await client.get(`/api/training-expenses/by-training/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch expenses:', error);
  }
}

async function fetchTrainingExpense(id) {
  try {
    const response = await client.get(`/api/training-expenses/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch expense:', error);
  } x
};

async function calculateTransportCostForProfile(trainingId, profileId, distanceKm) {
  try {
    const response = await client.patch(`/api/trainings-expenses/${trainingId}/profiles/${profileId}/calculate-transport-cost`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch expense:', error);
    return error;
  }
};

async function calculateMealsCostForProfile(trainingId, profileId, distanceKm) {
  try {
    const response = await client.get(`/api/training-expenses/${trainingId}/${profileId}/calculate-evening-meals`, {
      params: { distance: distanceKm },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch expense:', error);
  }
};

// Export all trainings services
const fetchExpensesServices = {
  fetchTrainingExpenseStatuses,
  fetchTrainingExpense,
  fetchTrainingExpensesByTraining,
  calculateTransportCostForProfile,
  calculateMealsCostForProfile,
};

export default fetchExpensesServices;