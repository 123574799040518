<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    style="z-index:3000" :detail="notificationMessage" @close="showNotification = false" />
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="$emit('close')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ title }} de {{
                    traineeName }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ message }}</p>
                  </div>

                  <div class="text-center mt-10 mb-10">
                    <SwitchGroup as="div" class="flex items-center justify-center mb-5">
                      <Switch v-model="reimburseTrainee"
                        :class="[reimburseTrainee ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                        <span aria-hidden="true"
                          :class="[reimburseTrainee ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                      </Switch>
                      <SwitchLabel as="span" class="ml-3 text-sm">
                        <span class="font-medium text-gray-900">{{ reimburseTrainee ? 'A rembourser' :
                          'Ne pas rembourser'
                          }}</span>
                      </SwitchLabel>
                    </SwitchGroup>
                    <SwitchGroup as="div" class="flex items-center justify-center">
                      <Switch v-model="accommodationTrainee"
                        :class="[accommodationTrainee ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                        <span aria-hidden="true"
                          :class="[accommodationTrainee ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                      </Switch>
                      <SwitchLabel as="span" class="ml-3 text-sm">
                        <span class="font-medium text-gray-900">{{ accommodationTrainee ? 'Hébergement demandé' :
                          'Pas besoin d\'hébergement'
                          }}</span>
                      </SwitchLabel>
                    </SwitchGroup>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:gap-3">
                <button type="button" v-if="primaryButtonLabel"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="saveReimbursement">{{ primaryButtonLabel }}</button>
                <button type="button" v-if="secondaryButtonLabel"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  @click="closeDialog" ref="cancelButtonRef">{{ secondaryButtonLabel }}</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Switch, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

/* eslint-disable */
const props = defineProps({
  title: String,
  message: String,
  primaryButtonLabel: String,
  secondaryButtonLabel: String,
  traineeId: [String, Number],
  traineeName: String,
  trainingId: [String, Number],
  reimbursed: [Boolean, Number],
  accommodation: [Boolean, Number],
});

const open = ref(true);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const emit = defineEmits(['close', 'confirm']);
const reimburseTrainee = ref(null);
const componentKey = ref(0);
const accommodationTrainee = ref(null);

const closeDialog = () => {
  emit('close')
};
const saveReimbursement = async () => {
  try {
    const response = await client.patch(`api/trainings/${props.trainingId}/trainees/${props.traineeId}/reimbursed`, {
      reimbursed: reimburseTrainee.value,
      needs_accommodation: accommodationTrainee.value,
    });
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationTitle.value += response.data.message;
    notificationMessage.value = '';
    emit('confirm');
    emit('close');
    closeDialog();
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};

const isReimbursed = computed(() => {
  if (reimburseTrainee.value === true && props.reimbursed === 1) {
    return true;
  } else if (reimburseTrainee.value === false && props.reimbursed === 0) {
    return true;
  } else {
    return false;
  }
});

onMounted(() => {
  if (props.reimbursed === 1 || props.reimbursed === '1' || props.reimbursed === true) {
    reimburseTrainee.value = true;
    componentKey.value += 1;
  } else {
    reimburseTrainee.value = false;
    componentKey.value += 1;
  }
  if (props.accommodation === 1 || props.accommodation === '1' || props.accommodation === true) {
    accommodationTrainee.value = true;
    componentKey.value += 1;
  } else {
    accommodationTrainee.value = false;
    componentKey.value += 1;
  }
});
</script>
