<template>
  <TrainingEvaluation v-if="displayTrainingEvaluation" @close="displayTrainingEvaluation = false"
    :training="training" />
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    style="z-index:3000" :detail="notificationMessage" @close="showNotification = false" />
  <BigLoader v-if="isLoading" :title="loaderTitle" :text="'Préparation en cours de votre document'" />
  <div class="xl:flex gap-4">
    <div class="w-full">
      <AlertMessage v-if="displayAlert" :type="alertType" :title="alertTitle" class="mt-12" :content="alertMessage" />
      <div class="overflow-hidden bg-white shadow sm:rounded-lg mt-10">
        <div class="px-4 py-6 sm:px-6">
          <h3 class="text-base font-semibold leading-7 text-gray-900">
            Informations générales
          </h3>
          <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            {{ training.complement }}
          </p>
        </div>
        <div class="border-t border-gray-100">
          <dl class="divide-y divide-gray-100">
            <!-- Dates -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-900">Dates</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ moment(training.start_date).format("Do MMM YY - HH:mm") }} / {{
    moment(training.end_date).format("Do MMM YY - HH:mm") }}
              </dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-if="training.deferrals && profile?.role?.slug !== 'stagiaire'">
              <dt class="text-sm font-medium text-gray-900">Reports</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <div v-for="deferral in training.deferrals" :key="deferral.id">
                  {{ moment(deferral.initial_start_date).format("Do MMM YY") }} -
                  {{ moment(deferral.initial_end_date).format("Do MMM YY") }}
                </div>
              </dd>
            </div>
            <!-- Responsable pédagogique -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" v-if="profile?.role?.slug !== 'stagiaire'">
              <dt class=" text-sm font-medium text-gray-900">Responsable pédagogique</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ training.pedagogical_supervisors?.map(supervisor => supervisor?.user.name).join(', ') }}
              </dd>
            </div>
            <!-- Catégorie -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-900">Catégorie</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ training.category?.name }}
              </dd>
            </div>
            <!-- Type -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              v-if="profile?.role?.slug === 'administrateur' || profile?.role?.slug === 'gestionnaire'">
              <dt class="text-sm font-medium text-gray-900">Type</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ training.type?.name }}
              </dd>
            </div>
            <!-- Thématique -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" v-if="profile?.role?.slug !== 'stagiaire'">
              <dt class="text-sm font-medium text-gray-900">Thématique</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ training.theme?.name }}
              </dd>
            </div>
            <!-- Syndicat -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-900">Syndicat</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ training.unions?.map(union => union?.name).join(', ') }}
              </dd>
            </div>
            <!-- Location -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-900">Lieu</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <b>{{ training.place?.name }}</b> <br> {{ training.place?.address }} <br> {{ training.place?.zip_code }}
                {{
    training.place?.city?.name }}
              </dd>
            </div>
            <!-- Public -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-900">Réservée</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><span class="status"
                  :class="training.reserved === 0 ? 'status7' : 'status4'">
                  {{ training.reserved === 0 ? 'Non réservée' : 'réservée' }}</span>
              </dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-900">Présentiel</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <b>{{ training.is_visio === 0 ? 'Présentiel' : 'Visio' }}</b>
              </dd>
            </div>
            <!-- Conditions tarifaires -->
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" v-if="(profile?.role?.slug !== 'stagiaire') &&
    (profile?.role?.slug !== 'responsable-pedagogique') && (profile?.role?.slug !== 'intervenant')
    ">
              <dt class="text-sm font-medium text-gray-900">Conditions tarifaires</dt>
              <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ training.paid_training === 0 ? 'Gratuit' : 'Payant' }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="max-w-72 mt-12">
      <div v-if="profile?.role?.slug === 'stagiaire' && registration"
        class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 w-96">
        <div class="flex-auto px-6 pt-6">
          <dt class="text-base font-semibold leading-6 text-gray-900 mb-4">Mon inscription</dt>
          <dd class="mt-2 text-base font-semibold leading-6 text-gray-900">
            <div><span class="status" v-if="registration"
                :class="'registrationStatus' + registration?.pivot?.registration_status_id">{{
    registration?.registration_status }}</span></div>
          </dd>
          <div><button class="btn-danger mt-10 w-full" @click.prevent="unregister"
              v-if="training.status.id === 3 || training.status.id === 4">Me
              désincrire</button>
          </div>
        </div>

        <div class="mt-6 border-t border-gray-900/5 px-6 py-6">
          <button class="text-left text-sm font-semibold leading-6 text-gray-900 mb-4 block"
            v-if="registration?.pivot?.registration_status_id === 2"
            @click.prevent="generateConvocation(registration.id);">Télécharger ma
            convocation
            <span aria-hidden="true">&rarr;</span></button>
          <button class="text-left text-sm font-semibold leading-6 text-gray-900 mb-4 block"
            v-if="training.attendance_sheet_done && training.status.id === 5"
            @click.prevent="generateAttendance(registration.id)">Télécharger mon
            attestation
            de présence
            <span aria-hidden="true">&rarr;</span></button>
          <button class="btn-primary" @click.prevent="displayTrainingEvaluation = true" v-if="needToEvaluate">Evaluer
            la
            formation</button>
        </div>
      </div>
      <div v-if="profile?.role?.slug === 'stagiaire' && !registration"
        class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 w-96">
        <div class="flex-auto px-6 py-6" v-if="training.max_attendees > training.registered_count">
          <dt class="text-base font-semibold leading-6 text-gray-900 mb-6">Inscriptions ouvertes</dt><router-link
            :to="'/inscription/' + training.id" v-if="profile?.role?.slug === 'stagiaire'"><button
              class="btn-primary relative ml-auto">m'inscrire à cette formation</button></router-link>
        </div>
        <div class="flex-auto px-6 py-6" v-else>
          <dt class="text-base font-semibold leading-6 text-gray-900 mb-6">Cette formation est complète,
            malheureusement, vous ne pouvez
            plus vous inscrire.</dt>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import moment from 'moment';
import 'moment/locale/fr';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import exportServices from '@/services/ExportServices';
import client from '@/api/client';
import BigLoader from '@/components/app/BigLoader.vue';
import { useRoute } from 'vue-router';
import TrainingEvaluation from '@/components/dashboards/trainee/TrainingEvaluation.vue';

moment.locale('fr');

const props = defineProps({
  training: Object,
  registration: [Object, Array],
});
let daysRemaining = 0;
const alertType = ref('');
const alertTitle = ref('');
const alertMessage = ref('');
const displayAlert = ref(false);
let daysAfter = 0;
const profile = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const isLoading = ref(false);
const loaderTitle = ref('');
const route = useRoute();
const displayTrainingEvaluation = ref(false);
const needToEvaluate = ref(false);

const emit = defineEmits(['statusUpdated']);
const unregister = async () => {
  try {
    const response = await client.delete(`api/trainings/${route.params.id}/unregister/${profile.value.id}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, vous êtes désinscrit';
    notificationMessage.value = response.data.message;
    emit('statusUpdated');
    fetchTrainee();
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de la désincription';
    if (error.response) {
      notificationMessage.value = error.response.data.error;
    }
  }
};

const generateAttendance = async (traineeId) => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  isLoading.value = true;
  loaderTitle.value = 'Génération de l\'attestation de présence en cours';
  try {
    const response = await client.get(`api/certificate/${props.training.id}/${traineeId}`, {
      responseType: 'blob',
    });

    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `f${traineeId}-attestation-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
}

const generateConvocation = async (traineeId) => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  isLoading.value = true;
  loaderTitle.value = 'Génération de la convocation en cours';
  try {
    const response = await client.get(`api/trainings/${props.training.id}/convocation/${traineeId}`, {
      responseType: 'blob',
    });

    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `f${traineeId}-convocation-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
}
const fetchTrainee = async () => {
  const today = new Date();
  try {
    const response = await client.get(`api/evaluations/evaluation/${props.training.id}/${profile.value.id}`);
    if (response.data.id) {
      needToEvaluate.value = false
    } else {
      if (new Date(props.training.end_date) < today) {
        needToEvaluate.value = true;
      }
    }
  } catch (error) {
    if (new Date(props.training.end_date) < today) { needToEvaluate.value = true; }
  }
}

onMounted(async () => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  daysRemaining = Math.ceil((new Date(props.training?.start_date) - new Date()) / (1000 * 60 * 60 * 24));
  daysAfter = Math.ceil((new Date() - new Date(props.training?.end_date)) / (1000 * 60 * 60 * 24));
  const today = new Date();
  if (new Date(props.training.start_date) === today) {
    displayAlert.value = true;
    alertType.value = 'info';
    alertTitle.value = `La formation commence aujourd'hui`;
  } else if (new Date(props.training.start_date) > today) {
    displayAlert.value = true;
    alertType.value = 'info';
    alertTitle.value = `Il reste ${daysRemaining} jours avant le début de la formation.`;
  } else if (new Date(props.training.end_date) < today) {
    displayAlert.value = true;
    alertType.value = 'info';
    alertTitle.value = `La formation est terminée depuis ${daysAfter} jours.`;
  } else if (new Date(props.training.start_date) < today && new Date(props.training.end_date) > today) {
    displayAlert.value = true;
    alertType.value = 'info';
    alertTitle.value = `La formation est en cours.`;
  }
  if (profile.value.role.slug === 'stagiaire') {
    fetchTrainee();
  };
});
</script>
