<template>
  <div class="min-h-screen flex items-center flex-col justify-center bg-gray-100">
    <div class="mb-10"><a href="/" alt="logo"><img class="h-12 sm:h-16"
      src="@/assets/img/logo/logo.svg" alt="SIRT - Institut Régional du Travail"></a> </div>
    <div class="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
      <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mb-3">
        <UserIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <h2 class="text-xl font-semibold leading-9 tracking-tight text-gray-900 text-center">Changer le mot de passe
      </h2>
      <form @submit.prevent="logUser">

        <div class="mt-3 text-left sm:mt-5">
          <form class="">
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-full">
                <div class="mt-2">
                  <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                  <input type="email" name="email" id="email" autocomplete="email" required="required"
                    v-model="formUser.email"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                </div>
              </div>

              <div class="col-span-full">
                <label for="account-number" class="block text-sm font-medium leading-6 text-gray-900">Mot de
                  passe</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                  <input :type="showPassword ? 'password' : 'text'" name="password" id="password"
                    autocomplete="password" required="required" v-model="formUser.password"
                    class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="mot de passe" />
                  <div class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                    @click="showPassword = !showPassword" @keydown.enter="showPassword = !showPassword" tabindex="0">
                    <span class="sr-only">Voir mot de passe</span>
                    <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div class="col-span-full">
                <label for="account-number" class="block text-sm font-medium leading-6 text-gray-900">Confirmation
                  de Mot de
                  passe</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                  <input :type="showPassword ? 'password' : 'text'" name="passwordConfirmation"
                    id="passwordConfirmation" v-model="formUser.passwordConfirmation"
                    :class="[checkPassword ? 'ring-green-300' : 'ring-red-300']"
                    class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="mot de passe" />
                  <div @click="showPassword = !showPassword" @keydown.enter="showPassword = !showPassword"
                    aria-hidden="true" class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                    tabindex="0">
                    <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
                <p class="mt-2 text-sm text-red-600" id="email-error" v-if="showPasswordError">Le mot de
                  passe est différent.</p>
              </div>
              <div class="col-span-full mb-10">
                <AlertMessage v-if="errorMessage" type="error" :title="errorMessage" />
                <AlertMessage v-if="successMessage" type="success" :title="successMessage" />
              </div>
            </div>
          </form>
        </div>

        <div class="flex items-center justify-between">
          <button @click.prevent="changePassword" type="submit" class="w-full mx-auto bg-indigo-600 hover:bg-indigo-900 text-white font-normal py-2 px-4 tracking-wide
            rounded-lg focus:outline-none focus:shadow-outline">
            Changer le mot de passe<i class="fa fa-sign-in" aria-hidden="true"></i>
          </button>
        </div>

      </form>
    </div>
  </div>

</template>

<script setup>
/* eslint-disable */
import { ref, computed, watch } from 'vue';
import router from '@/router';
import client from '@/api/client';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import { UserIcon, EyeIcon } from '@heroicons/vue/24/outline';

const urlParams = new URLSearchParams(window.location.search);

const errored = ref(false);
const errorMessage = ref('');
const successMessage = ref('');
const formUser = ref({
  token: null,
  password: null,
  email: urlParams.get('email'),
  passwordConfirmation: '',
});
let showPassword = ref(true);
const showPasswordError = ref(false);
const checkPassword = computed(() => formUser.value.password === formUser.value.passwordConfirmation);

watch(() => formUser.value.passwordConfirmation, (newVal) => {
  showPasswordError.value = newVal.length >= formUser.value.password.length && !checkPassword.value;
});

const changePassword = async () => {
  errorMessage.value = ''
  const urlParams = new URLSearchParams(window.location.search);
  const resetToken = urlParams.get('token');
    // Todo: confirmation
  try {
    const response = await client.post('api/password/reset', {
      token: resetToken,
      email: formUser.value.email,
      password: formUser.value.password,
      password_confirmation: formUser.value.password,
    })
    successMessage.value = 'Votre mot de passe a été changé avec succès. Vous pouvez maintenant vous connecter.';
    router.push({
      name: 'Authentification',
    });
  } catch (error) {
    errored.value = true;
    errorMessage.value = 'Il semblerait que votre code d\'autorisation a expiré. Vous pouvez réinitialiser votre mot de passe une nouvelle fois.';
    formUser.password = null;
    formUser.token = null;
    formUser.passwordconfirmation = null;
  }
};

const forgotLogin = () => {
  router.push({
    name: 'ResetPassword',
  });
};

</script>
