<template>
  <BigLoader v-if="isLoading" title="Chargement des formations" text="" />
  <AppLayout>
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="hidden sm:block" v-if="profile?.role?.slug === 'responsable-pedagogique'">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <button @click.prevent="currentTab = 'all'; filterTrainings('all')" :class="[
    currentTab === 'all'
      ? 'bg-indigo-100 text-indigo-700'
      : 'text-gray-500 hover:text-gray-700',
    'rounded-md px-3 py-2 text-sm font-medium',
  ]
    ">Toutes</button> <button @click.prevent="currentTab = 'myTrainings'; filterTrainings('all'); timeline = 'all'"
            :class="[
    currentTab === 'myTrainings'
      ? 'bg-indigo-100 text-indigo-700'
      : 'text-gray-500 hover:text-gray-700',
    'rounded-md px-3 py-2 text-sm font-medium',
  ]
    ">Mes formations</button>
        </nav>
      </div>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <div v-if="currentTab === 'all'">
            <h1 class="text-base font-semibold leading-6 text-gray-900">
              Formations
            </h1>
            <p class="mt-2 text-sm text-gray-700">
              Toutes les formations terminées, en cours et à venir.
            </p>
          </div>
          <div v-else>
            <h1 class="text-base font-semibold leading-6 text-gray-900">
              Mes formations
            </h1>
            <p class="mt-2 text-sm text-gray-700">
              Toutes mes formations terminées, en cours et à venir.
            </p>
          </div>

          <div class="mt-5">
            <button class="btn-secondary mr-2" @click.prevent="filterTrainings('all')"
              :class="timeline === 'all' ? '!bg-indigo-100' : ''">Tout</button>
            <button class="btn-secondary mr-2" @click.prevent="filterTrainings('completed')"
              :class="timeline === 'completed' || timeline === 'past' ? '!bg-indigo-100' : ''">Passées</button>
            <button class="btn-secondary mr-2" @click.prevent="filterTrainings('ongoing')"
              :class="timeline === 'ongoing' ? '!bg-indigo-100' : ''">En cours</button><button class=" btn-secondary"
              @click.prevent="filterTrainings('upcoming')" :class="timeline === 'upcoming' ? '!bg-indigo-100' : ''">A
              venir</button>
          </div>
        </div>

        <div class=" mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button type="button" @click="gotoCreateTraining" v-if="profile?.role?.slug !== 'intervenant'"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Créer une formation
          </button>
        </div>
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300" v-if="relevantTrainings.length > 0">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Intitulé
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date de début
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date de fin
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Catégorie
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Type
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Participants
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Statut
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Lieu
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span class="sr-only">Détails</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr v-for=" relevantTraining  in  relevantTrainings " :key="relevantTraining.id"
                  class="even:bg-gray-50">
                  <td class="py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-3">
                    <router-link :to="'/formations/' + relevantTraining.id"> {{ relevantTraining.title }}</router-link>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ moment(relevantTraining.start_date).format("Do MMM YY") }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ moment(relevantTraining.end_date).format("Do MMM YY") }}
                  </td>
                  <td class="whitespace nowrap px-3 py-4 text-sm text-gray-500">
                    <span>{{
    relevantTraining.category?.name }}</span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span class="status status1"> {{
    relevantTraining.type.name }}</span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div class="text-gray-900">{{ relevantTraining.registered_count }} inscrits / {{
    relevantTraining.max_attendees }}</div>
                    <div class="mt-1 text-gray-500">
                      {{ relevantTraining.pre_registered_count }} préinscrits
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span :class="'status' +
    relevantTraining.status?.id
    " class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium">
                      {{ relevantTraining.status?.name }}
                    </span>
                  </td>
                  <td class="px-3 py-4 text-xs text-gray-500">
                    {{ relevantTraining.place?.name }}
                  </td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <router-link :to="'/formations/' + relevantTraining.id"
                      class="text-indigo-600 hover:text-indigo-900">Détails<span class="sr-only">,
                        {{ relevantTraining.title }}</span></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <AlertMessage v-else-if="!isLoading && relevantTrainings.length === 0" class="mb-5"
              title="Aucune formation n'est associée" />
          </div>
        </div>
      </div>
    </div>
    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import Pagination from '@/components/lists/PaginationTables.vue';
import { useRouter } from 'vue-router';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import moment from 'moment';
import BigLoader from '@/components/app/BigLoader.vue';
import client from '@/api/client';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

const router = useRouter();

// Pagination data
const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = 20;
const totalItems = ref(0);
const isLoading = ref(false);
const profile = ref();
const timeline = ref('all');
const typeTrainings = ref('all');
const currentTab = ref('all');

// Trainings data
const relevantTrainings = ref([]);

// Dates formatting
const date = ref(new Date('2024-07-04 21:54:02'));
const formattedDate = computed(() => moment(date).format("Do MMM YY"));

const gotoCreateTraining = () => {
  router.push({ name: 'Créer une formation' });
};

const filterTrainings = (type) => {
  timeline.value = type;
  changePage(1);
};

const changePage = async (page) => {
  isLoading.value = true;
  if (currentTab.value === 'all') {
    fetchTrainingsServices.fetchTrainings(page, pageSize, timeline.value).then((data) => {
      relevantTrainings.value = data.data;
      currentPage.value = Number(data.current_page);
      isLoading.value = false;
      totalPages.value = data.last_page;
      totalItems.value = data.total;
    });
  } else {
    if (timeline.value === 'completed') {
      timeline.value = 'past';
    }
    console.log('timeline', timeline.value);
    try {
      const response = await client.get(`/api/pedagogical-supervisor/assigned-trainings?filter=${timeline.value}`, {
        params: { page: Number(page), per_page: pageSize },
      });
      relevantTrainings.value = response.data.data;
      currentPage.value = Number(response.data.current_page);
      totalPages.value = response.data.last_page;
      totalItems.value = response.data.total;
      isLoading.value = false;
    } catch (error) {
      console.error('Failed to fetch trainings:', error);
    }
  }

};

onMounted(() => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  isLoading.value = true;
  changePage(1);
});
</script>
