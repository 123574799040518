<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" style="z-index:2000;position:absolute" />
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="$emit('close')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class=" relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ title }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ message }}</p>
                  </div>

                  <div class="text-left mt-20 mb-10 min-h-[450px]">
                    <div class="sm:col-span-3">
                      <label for="title" class="block text-sm font-medium leading-6 text-gray-900">Intitulé de la
                        formation
                      </label>
                      <div class="mt-2">
                        <input type="text" name="titlen" id="title" v-model="titleTraining" autocomplete="given-name"
                          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                      </div>
                    </div>
                    <div class="sm:col-span-full mt-5" style="z-index:10000; position:relative">
                      <SelectComponent v-model="themeTraining" :arrayData="relevantTrainingThemes" :label="'Thème'"
                        :placeholder="'Choisissez un thème'" :key="componentKey" />
                    </div>
                    <div class="sm:col-span-full mt-5" style="z-index:9999; position:relative">
                      <SelectComponent v-model="placeTraining" :arrayData="relevantPlaces" :label="'Localisation'"
                        :placeholder="'Choisissez un lieu de stage'" :key="componentKey" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6 sm:mt-20 sm:flex sm:gap-3 mb-10">
                <button type="button" v-if="primaryButtonLabel"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="saveTitleTraining">{{ primaryButtonLabel }}</button>
                <button type="button" v-if="secondaryButtonLabel"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  @click="closeDialog" ref="cancelButtonRef">{{ secondaryButtonLabel }}</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/24/outline';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import { useRoute } from 'vue-router';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import SelectComponent from '@/components/form/SelectComponent.vue';

const route = useRoute();
const emit = defineEmits(['close', 'confirm']);

/* eslint-disable */
const props = defineProps({
  title: String,
  message: String,
  primaryButtonLabel: String,
  secondaryButtonLabel: String,
  training: [Array, Object],
});

const open = ref(true);
const titleTraining = ref(null);
const componentKey = ref(0);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const themeTraining = ref(null);
const relevantTrainingThemes = ref([]);
const relevantPlaces = ref([]);
const placeTraining = ref(null);

const closeDialog = () => {
  emit('close')
};

const saveTitleTraining = async () => {
  let arrayToPost = {};
  arrayToPost.title = titleTraining.value;
  arrayToPost.training_theme_id = themeTraining.value.id;
  console.log('arrayToPost', arrayToPost);
  if (placeTraining.value) {
    arrayToPost.training_place_id = placeTraining.value.id;
  }
  try {
    const response = await client.patch(`api/trainings/${route.params.id}`, arrayToPost);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value = '';
    emit('confirm');
    emit('close');
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};
onMounted(() => {
  titleTraining.value = props.training.title;

  fetchTrainingsServices.fetchTrainingThemes().then((data) => {
    relevantTrainingThemes.value = data;
    themeTraining.value = relevantTrainingThemes.value.find(theme => theme.id === props.training.training_theme_id);
    componentKey.value += 1;
  });

  fetchTrainingsServices.fetchTrainingPlaces().then((data) => {
    relevantPlaces.value = data;
    if (props.training.place) {
      placeTraining.value = relevantPlaces.value.find(place => place.id === props.training.place.id);
    }
    componentKey.value += 1;
  });
});
</script>
