<template>
  <BigLoader v-if="isLoading" :title="'Insccription en cours'" :text="'Préparation de votre inscription'" />
  <SuccessDialog title="Bravo, vous êtes bien préinscrit•e !" @close="gotoDashboard"
    message="Vous recevrez prochainement un email vous précisant si votre inscription est confirmée." v-if="showDialog"
    secondaryButtonLabel="fermer" />
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <AppLayout>
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">M'inscrire à
            la formation :</h2>
          <p class="mt-3 text-xl font-bold">{{ training.title }}</p>
        </div>
      </div>
      <div class="mx-auto mt-24">
        <nav aria-label="Progress">
          <ol role="list" class="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            <li v-for="(step, stepIdx) in steps" :key="step.name" class="relative md:flex md:flex-1">
              <a v-if="step.id === currentStep" :href="step.href" class="group flex w-full items-center"
                @click.prevent="currentStep = step.id">
                <span class="flex items-center px-6 py-4 text-sm font-medium">
                  <span
                    class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                    <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span class="ml-4 text-sm font-medium text-gray-900">{{ step.name }}</span>
                </span>
              </a>
              <a v-else-if="step.status === 'current'" :href="step.href" @click.prevent="currentStep = step.id"
                class="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span
                  class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span class="text-indigo-600">{{ step.id }}</span>
                </span>
                <span class="ml-4 text-sm font-medium text-indigo-600">{{ step.name }}</span>
              </a>
              <button v-else class="group flex items-center" @click.prevent="currentStep = step.id">
                <span class="flex items-center px-6 py-4 text-sm font-medium">
                  <span
                    class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span class="text-gray-500 group-hover:text-gray-900">{{ step.id }}</span>
                  </span>
                  <span class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{{ step.name }}</span>
                </span>
              </button>
              <template v-if="stepIdx !== steps.length - 1">
                <!-- Arrow separator for lg screens and up -->
                <div class="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor"
                      stroke-linejoin="round" />
                  </svg>
                </div>
              </template>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 mt-24" v-if="currentStep === 1">
      <div class="space-y-10 divide-y divide-gray-900/10">
        <TraineeRegistrationTrainingInfos :training="training" :key="componentKey" />
        <div class="text-right w-full px-4 sm:px-6 lg:px-8 mt-10 pt-5">
          <button class="ml-auto btn-primary" @click="currentStep++">Passer à l'étape suivante</button>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 mt-24 registrationContext" v-if="currentStep === 2">
      <div class="space-y-10 divide-y divide-gray-900/10">
        <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div class="px-4 sm:px-0">
            <h2 class="text-base font-semibold leading-7 text-gray-900">{{ accountTitle }}</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">{{ accountDescription }}</p>
          </div>
        </div>
      </div>
      <AccountForm :displayErrors="true" />
      <div class="text-right w-full px-4 sm:px-6 lg:px-8 pt-5 mb-10">
        <button class="ml-auto btn-primary" @click="currentStep++">Passer à l'étape suivante</button>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 mt-24" v-if="currentStep === 3">
      <AlertMessage v-if="isErrored" type="error" title="Veuillez remplir tous les champs"
        message="Pour valider la préinscription, veuillez saisir tous les champs" class="mb-10" />
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 mt-10 border-b border-gray-900/10  pb-12 mb-10"
        v-if="forPrudhommeAdvisor">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Informations sur votre mandat Prudhommes</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Vérifiez les informations de la formation sélectionnée</p>
        </div>
        <div class="w-full shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 mt-10 px-6 py-4">
          <li class="col-span-1 flex rounded-md shadow-sm mb-10 mt-2 ">
            <div
              class="bg-indigo-600 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
              CPH</div>
            <div
              class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div class="flex-1 truncate px-4 py-2 text-sm">
                <div class="font-medium text-gray-900 hover:text-gray-600">{{
    mandateSelected.name
  }}</div>
                <p class="text-gray-500">{{ getCouncil(mandateSelected.pivot?.council_id) }}</p>
                <p>{{
    getCouncilSection(mandateSelected.pivot?.council_section_id)
  }}</p>
              </div>
            </div>
          </li>
          <div class="col-span-full mb-5" style="z-index:10; position:relative;">
            <SelectComponent v-model="senioritySelected" :arrayData="relevantSeniorities"
              :label="'Quelle ancienneté pour ce mandat ?'" :placeholder="'Choisissez une ancienneté'"
              :key="componentKey" />
          </div>
          <div class="col-span-full" style="z-index:9; position:relative;">
            <SelectComponent v-model="durationSelected" :arrayData="relevantTrainingDurations"
              :label="'Combien de formations aveez-vous suivies depuis le début du mandat ?'"
              :placeholder="'Choisissez un nombre'" :key="componentKey" />
          </div>
        </div>
      </div>
      <TraineeRegistrationExpense :training="training" :key="componentKey" />
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 border-b border-gray-900/10  pb-12">
        <div class="px-4 sm:px-0">
        </div>
        <div class="w-full bg-indigo-50 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 mt-10 px-6 py-4">
          <SwitchGroup as="div" class="flex items-center justify-between mt-5 mb-5">
            <Switch v-model="reinbursement"
              :class="[reinbursement ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
              <span aria-hidden="true"
                :class="[reinbursement ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
            </Switch>
            <span class="flex flex-grow flex-col ml-4">
              <SwitchLabel as="span" class="text-base font-semidbold leading-6 text-indigo-600 mb-2" passive>Je demande
                à
                bénéficier
                des
                défraiements</SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">sous réserve de fournir les justificatifs dans
                un
                délai de 10 jours suivants la formation</SwitchDescription>
            </span>
          </SwitchGroup>
          <SwitchGroup as="div" class="flex items-center justify-between my-5" v-if="training.accommodation === 1">
            <Switch v-model="needs_accommodation"
              :class="[needs_accommodation ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
              <span aria-hidden="true"
                :class="[needs_accommodation ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
            </Switch>
            <span class="flex flex-grow flex-col ml-4">
              <SwitchLabel as="span" class="text-base font-semidbold leading-6 text-indigo-600 mb-2" passive>Je demande
                à
                bénéficier
                d'un hébergement</SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">Je m'engage à prévenir l'IRT 7 jours avant d'un
                éventuel désistement.</SwitchDescription>
            </span>
          </SwitchGroup>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 mt-10">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Comment avez-vous connu cette formation ?</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
          </p>
        </div>
        <div class="md:col-span-2" :class="isErrored ? 'missingField' : ''">
          <SelectComponent v-model="referralSourceSelected" :arrayData="relevantReferralSources" :label="''"
            :placeholder="'Choisissez une source de référence'" :key="componentKey" />
        </div>
      </div>

      <AlertMessage v-if="isErrored" type="error" title="Veuillez remplir tous les champs"
        message="Pour valider la préinscription, veuillez saisir tous les champs" class="my-10" />
      <div class="text-right w-full px-4 sm:px-6 lg:px-8 mb-10 pt-5">
        <button @click="checkForm" type="button"
          class="inline-flex items-center gap-x-2 rounded-md bg-emerald-400 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
          <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Je m'inscris
        </button>

      </div>
    </div>
    <div>
    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject, computed, watch } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import Pagination from '@/components/lists/PaginationTables.vue';
import { useRoute, useRouter } from 'vue-router';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import moment from 'moment';
import AccountForm from '@/components/users/AccountForm.vue';
import TraineeRegistrationTrainingInfos from '@/components/registration/TraineeRegistrationTrainingInfos.vue';
import TraineeRegistrationExpense from '@/components/registration/TraineeRegistrationExpense.vue';
import { CheckIcon } from '@heroicons/vue/24/solid'
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';
import SelectComponent from '@/components/form/SelectComponent.vue';
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import SuccessDialog from '@/components/dialogs/SuccessDialog.vue';
import BigLoader from '@/components/app/BigLoader.vue';

const route = useRoute();
const componentKey = ref(0);
const store = inject('store');
const steps = [
  { id: 1, name: 'Intitulé de la formation', href: '#', status: 'complete' },
  { id: 2, name: 'Vérifiez votre profil', href: '#', status: 'upcoming' },
  { id: 3, name: 'Finalisation', href: '#', status: 'upcoming' },
]
const router = useRouter();
let { user } = store;
const training = ref([]);
const currentStep = ref(1);
const relevantReferralSources = ref([]);
const showDialog = ref(false);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('')
const currentUser = ref([]);
const mandateSelected = ref(null);
const reinbursement = ref(false);
const senioritySelected = ref(null);
const durationSelected = ref(null);
const referralSourceSelected = ref(null);
const relevantSeniorities = ref([]);
const relevantTrainingDurations = ref([]);
const relevantCouncils = ref([]);
const relevantCouncilSections = ref([]);
const isErrored = ref(false);
const isLoading = ref(false);
const needs_accommodation = ref(false);

const accountTitle = 'Votre profil';
const accountDescription = 'Complétez et vérifiez les informations de votre profil';

const emit = defineEmits('statusUpdated')

const fetchTraining = async () => {
  fetchTrainingsServices.fetchTraining(route.params.id).then((data) => {
    training.value = data;
    componentKey.value += 1;
    if (training.value.type.name === 'CPH') {
      fetchPersonalInfoServices.fetchSeniorities().then((data) => {
        relevantSeniorities.value = data;
      });
      fetchPersonalInfoServices.fetchTrainingDurations().then((data) => {
        relevantTrainingDurations.value = data;
      });
      fetchPersonalInfoServices.fetchCouncils().then((data) => {
        relevantCouncils.value = data;
      });
      fetchPersonalInfoServices.fetchCouncilSections().then((data) => {
        relevantCouncilSections.value = data;
      });
      componentKey.value += 1;
    }
  });
};

const checkForm = () => {
  if (training.value.type.name === 'CPH' && forPrudhommeAdvisor.value) {
    if (senioritySelected.value !== null && durationSelected.value !== null && referralSourceSelected.value !== null) {
      register();
      isErrored.value = false;
    } else {
      isErrored.value = true;
    };
  } else if (referralSourceSelected.value !== null) {
    register();
    isErrored.value = false;
  } else {
    isErrored.value = true;
  };
};

const register = async () => {
  const arrayToPost = ref([]);
  arrayToPost.value.push({
    profile_id: currentUser.value.id,
    role_id: currentUser.value.role_id,
    referral_source_id: referralSourceSelected.value.id,
    reimbursed: reinbursement.value,
    needs_accommodation: needs_accommodation.value,
  });
  if (training.value.type.name === 'CPH' && forPrudhommeAdvisor) {
    arrayToPost.value.push({
      seniority_id: senioritySelected.value?.id,
      training_duration_id: durationSelected.value?.id,
    });
  }
  try {
    isLoading.value = true;
    const response = await client.post(`api/trainings/${route.params.id}/register`, arrayToPost.value[0]);
    showDialog.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value = response.data.message;
    isLoading.value = false;
    store.fetchUserProfile();
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    isLoading.value = false;
    if (error.response) {
      notificationMessage.value = error.response.data.error;
    }
  }
  open.value = false;
};

const gotoDashboard = () => {
  router.push({ name: 'Tableau de bord' });
};
const forPrudhommeAdvisor = computed(() => {

  for (const index in currentUser.value?.mandates) {
    if (currentUser.value.mandates[index].name === 'Conseiller Prud’hommes' && (currentUser.value.mandates[index].pivot.is_valid === 1) && training.value.type.name === 'CPH') {
      mandateSelected.value = currentUser.value.mandates[index];
      return true;
    } else return false;
  }
});

const getCouncil = ((id) => {
  const council = relevantCouncils.value.find(item => item.id === id);
  if (council) {
    return council.name;
  }
  return '';
});

const getCouncilSection = ((id) => {
  const councilSection = relevantCouncilSections.value.find(item => item.id === id);
  if (councilSection) {
    return councilSection.name;
  }
  return '';
});

watch(() => store.user, (newVal) => {
  currentUser.value = newVal;
});


onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    currentUser.value = user;
  }
  fetchTraining();
  fetchPersonalInfoServices.fetchReferralSources().then((data) => {
    relevantReferralSources.value = data;
  });

});
</script>
<style>
.registrationContext h1 {
  display: none;
}

.missingField button {
  --tw-ring-opacity: 1;
  --tw-ring-color: #ef4444;
}
</style>
