<template>
  <SwitchGroup as="div" class="flex items-center">
    <Switch v-model="enabled" @update:model-value="updateSelected" :class="[enabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
      <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
    </Switch>
    <SwitchLabel as="span" class="ml-3 text-sm">
      <span class="font-medium text-gray-900">{{ enabled ? trueValue :
                                                falseValue
                                                }}</span>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

defineProps({
  trueValue: String,
  falseValue: String,
});
const emit = defineEmits(['update:modelValue', 'close']);
const model = defineModel();

const enabled = ref(false);

const updateSelected = () => {
  emit('update:modelValue', enabled.value);
}

onMounted(() => {
  console.log(model.value === 1);
  if(model.value === 1) {
    enabled.value = true;
  } else if(model.value === 0) {
    enabled.value = false;
  }
});

</script>