<template>
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
    <header>
        <div class="mx-auto max-w-10xl px-4 sm:px-6 lg:px-8 mb-12">
            <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900"><span
                    v-if="!route.params.profileId">Créer un {{
                        selectedRole?.name }}</span><span v-else>Modifier le compte de {{ formProfile.user?.name }}</span>
            </h1>
            <div class="mt-3"><span class="status" :class="'status' + (Number(selectedRole?.id) + 1)">{{
                selectedRole?.name
                    }}</span></div>
        </div>
    </header>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 mt-24">
        <div class="space-y-10 divide-y divide-gray-900/10">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3" v-if="!route.params.profileId">
                <div class="px-4 sm:px-0">
                    <h2 class="text-base font-semibold leading-7 text-gray-900">{{ identifiantTitle }}</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">{{ identifiantDescription }}</p>
                </div>

                <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div class="px-4 py-6 sm:p-8">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div class="sm:col-span-3">
                                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Prénom
                                    Nom</label>
                                <div class="mt-2">
                                    <input id="name" name="name" type="text" v-model="formProfile.name"
                                        autocomplete="name"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Adresse
                                    courriel</label>
                                <div class="mt-2">
                                    <input id="email" name="email" type="email" v-model="formProfile.email"
                                        autocomplete="email"
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                        <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                            Annuler
                        </button>
                        <button type="submit" @click.prevent="checkRoleUrl('create')" class=" rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm
                            hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                            focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">
                            Créer
                        </button>
                    </div>
                </form>
            </div>

        </div>

        <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
            <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">{{ profileTitle }}</h2>
                <p class="mt-1 text-sm leading-6 text-gray-600">{{ profileDescription }}</p>
            </div>
            <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-6 sm:p-8">
                    <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="sm:col-span-6">
                            <fieldset class="mt-4">
                                <legend class="sr-only">Genre</legend>
                                <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                    <div v-for=" genre in relevantGenres " :key="genre.id" class="flex items-center">
                                        <input :id="genre.id" name="genre" type="radio" :value="genre.id"
                                            :class="isMissing('genre_id') ? 'ring-red-300 ring-1 ring-inset' : ''"
                                            v-model="formProfile.genre_id" :key="componentKey"
                                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        <label :for="genre.id"
                                            class="ml-3 block text-sm font-medium leading-6 text-gray-900">{{
                                                genre.name }}</label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div class="sm:col-span-3">
                            <label for="title" class="block text-sm font-medium leading-6 text-gray-900">Fonction
                            </label>
                            <div class="mt-2">
                                <input type="text" name="title" id="title" autocomplete="title"
                                    v-model="formProfile.title"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" />
                            </div>
                        </div>

                        <div class="sm:col-span-3">
                            <div class="mt-2">
                                <div class="sm:col-span-full">
                                    <SelectComponent v-model="formProfile.employment_status"
                                        v-if="relevantEmploymentStatus" :arrayData="relevantEmploymentStatus"
                                        :label="'Statut'" :placeholder="'Choisissez un statut'" :key="componentKey" />
                                </div>
                            </div>
                        </div>

                        <div class="sm:col-span-3">
                            <label for="phone"
                                class="block text-sm font-medium leading-6 text-gray-900">Téléphone</label>
                            <div class="mt-2">
                                <input type="text" name="phone" id="phone" autocomplete="tel"
                                    v-model="formProfile.phone"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    :class="isMissing('phone') ? 'missing-field' : ''" />
                            </div>
                        </div>
                        <div class="sm:col-span-full" v-if="formProfile?.id === profile?.id">
                            <label for="phone" class="block text-sm font-medium leading-6 text-gray-900 mb-2">Email
                                principal</label>
                            <span class="badge badge_selected">{{ formProfile.user?.email }}
                                <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"
                                    @click.prevent="displayAddMainEmail = true" />
                            </span>
                            <div class="mt-2 flex rounded-md shadow-sm" v-if="displayAddMainEmail">
                                <div class="relative flex flex-grow items-stretch focus-within:z-10">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input type="email" name="email" id="email" v-model="newMainEmail"
                                        class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="mail@example.com" />
                                </div>
                                <button type="button" @click.prevent="updateEmail"
                                    class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    modifier l'email
                                </button>
                            </div>
                        </div>
                        <div v-else class="sm:col-span-full"> <label for="phone"
                                class="block text-sm font-medium leading-6 text-gray-900 mb-2">Email
                                principal</label>
                            <span class="badge badge_unselected">{{ formProfile.user?.email }}
                            </span>
                        </div>

                        <div class="sm:col-span-full">
                            <div class="mt-5">
                                <label for="phone" class="block text-sm font-medium leading-6 text-gray-900 mb-2">Emails
                                    secondaires</label>
                                <div v-for="(email, index) in emailsSelected" :key="email">
                                    <span class="badge badge_selected">{{ email }}
                                        <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"
                                            @click.prevent="removeItem(index)" />
                                    </span>

                                </div>

                                <div class="mt-2 flex rounded-md shadow-sm">
                                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input type="email" name="email" id="email" v-model="newSecondaryEmail"
                                            class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="mail@example.com" />
                                    </div>
                                    <button type="button" @click.prevent="addEmail(newSecondaryEmail)"
                                        class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                        ajouter l'email
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-full">
                            <div class="mt-2 flex items-center gap-x-3">
                                <div v-if="formProfile.temporary_profile_picture_url">
                                    <img class="h-20 w-20 rounded-full object-cover"
                                        :src="formProfile.temporary_profile_picture_url" alt="" />
                                </div>
                                <UserCircleIcon class="h-12 w-12 text-gray-300" aria-hidden="true" v-else />

                                <input id="fileInput" type="file" @change="selectFile($event)"
                                    accept="image/png, image/jpeg, image/jpg" placeholder="image"
                                    class="rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                    <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                        Annuler
                    </button>
                    <button class="btn-primary" @click.prevent="checkRoleUrl('modify')"
                        v-if="route.params.profileId">Enregistrer</button>
                    <button type="submit" @click.prevent="checkRoleUrl('create')" v-else class="btn-primary"
                        :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>

                </div>
            </form>
        </div>

        <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
            v-if="Number(selectedRole?.id) === 3 || Number(selectedRole?.id) === 4">
            <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">{{ addressTitle }}</h2>
                <p class="mt-1 text-sm leading-6 text-gray-600">{{ addressDescription }}</p>
            </div>

            <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-6 sm:p-8">
                    <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="sm:col-span-2">
                            <label for="street_number"
                                class="block text-sm font-medium leading-6 text-gray-900">Numéro</label>
                            <div class="mt-2">
                                <input type="text" name="street_number" id="street_number" autocomplete="street_number"
                                    v-model="formProfile.street_number"
                                    :class="isMissing('street_number') ? 'missing-field' : ''"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div class="sm:col-span-4">
                            <label for="street_name" class="block text-sm font-medium leading-6 text-gray-900">Adresse
                                postale</label>
                            <div class="mt-2">
                                <input type="text" name="street_name" id="street_name" v-model="formProfile.street_name"
                                    autocomplete="street_name" :class="isMissing('street_name') ? 'missing-field' : ''"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                        </div>

                        <div class="sm:col-span-2">
                            <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">Code
                                postal</label>
                            <div class="mt-2">
                                <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code"
                                    @input="searchCityByPostcode(formProfile.profile_zip_code)"
                                    v-model="formProfile.profile_zip_code"
                                    :class="isMissing('profile_zip_code') ? 'missing-field' : ''"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div class="sm:col-span-4">
                            <div v-if="formProfile.city"
                                class="mt-8 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                {{ formProfile.city.name }}</div>
                            <div v-else>
                                <div class="sm:col-span-4" v-if="formProfile.profile_zip_code || formProfile.city_id">
                                    <SelectComponent v-model="formProfile.city" :arrayData="relevantCities"
                                        :label="'Ville'" :placeholder="'Choisissez une ville'" :key="componentKey" />
                                </div>
                            </div>
                            <div class="mt-9">
                                <SmallLoader v-if="isLoading" :text="'Recherche des villes associées'" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                    <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                        Annuler
                    </button>
                    <button class="btn-primary" @click.prevent="checkRoleUrl('modify')"
                        v-if="route.params.profileId">Enregistrer</button>
                    <button type="submit" @click.prevent="checkRoleUrl('create')" v-else class="btn-primary"
                        :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>

                </div>
            </form>
        </div>
        <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
            v-if="Number(selectedRole?.id) === 3 || Number(selectedRole?.id) === 4">
            <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Coordonnées bancaires</h2>
                <p class="mt-1 text-sm leading-6 text-gray-600">
                    Vos informations bancaires, utilisées pour vous verser vos défraiements.
                </p>
            </div>

            <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-6 sm:p-8">
                    <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div class="col-span-full">
                            <div class="isolate -space-y-px rounded-md shadow-sm">
                                <div
                                    class="relative rounded-md rounded-b-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                                    <label for="iban" class="block text-xs font-medium text-gray-900">IBAN</label>
                                    <input type="text" name="iban" id="iban" v-model="formProfile.iban"
                                        :class="isMissing('iban') ? 'missing-field' : ''"
                                        class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder="FR63 1450 8000 4042 7333 3449 3Z85" />
                                </div>
                                <div
                                    class="relative rounded-md rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                                    <label for="bank" class="block text-xs font-medium text-gray-900">Bic</label>
                                    <input type="text" name="bank" id="bank" v-model="formProfile.bic"
                                        :class="isMissing('bic') ? 'missing-field' : ''"
                                        class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder="Crédit Mutuel" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                    <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                        Annuler
                    </button>
                    <button class="btn-primary" @click.prevent="checkRoleUrl('modify')"
                        v-if="route.params.profileId">Enregistrer</button>
                    <button type="submit" @click.prevent="checkRoleUrl('create')" v-else class="btn-primary"
                        :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>

                </div>
            </form>
        </div>
        <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
            v-if="Number(selectedRole?.id) === 3 || Number(selectedRole?.id) === 4"
            :class="[!route.params.profileId ? 'opacity-30' : '']">
            <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">{{ vehicleTitle }}</h2>
                <p class="mt-1 text-sm leading-6 text-gray-600">
                    {{ vehicleDescription }}
                </p>
            </div>

            <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-6 sm:p-8">
                    <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div class="col-span-full">
                            <SwitchGroup as="div" class="flex items-center mb-5">
                                <Switch v-model="formProfile.has_vehicle" @update:model-value="saveVehicle"
                                    :class="[formProfile.has_vehicle ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                    <span aria-hidden="true"
                                        :class="[formProfile.has_vehicle ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                </Switch>
                                <SwitchLabel as="span" class="ml-3 text-sm">
                                    <span class="font-medium text-gray-900">{{ vehicleSwitchLabel }}</span> {{ ' '
                                    }}
                                </SwitchLabel>
                                <ExclamationCircleIcon class="ml-3 h-5 w-5 text-red-500" aria-hidden="true"
                                    v-if="isMissing('has_vehicle')" />
                            </SwitchGroup>
                            <Listbox as="div" v-model="formProfile.vehicle_category" :key="componentKey"
                                v-if="formProfile.has_vehicle">
                                <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Puissance
                                    fiscale
                                </ListboxLabel>
                                <div class="relative mt-2">
                                    <ListboxButton
                                        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <span class="block truncate" v-if="formProfile.vehicle_category">{{
                                            formProfile.vehicle_category.category }}</span>
                                        <span v-else class="text-gray-500">Choisissez une puissance</span>
                                        <span
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </ListboxButton>

                                    <transition leave-active-class="transition ease-in duration-100"
                                        leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions
                                            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            <ListboxOption as="template" v-for=" itemList in relevantVehicleCategories "
                                                :key="itemList.id" :value="itemList" v-slot="{ active, selected }">
                                                <li
                                                    :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                    <span
                                                        :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                                            itemList.category }}</span>
                                                    <span v-if="selected"
                                                        :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                </div>
                            </Listbox>
                        </div>

                        <div class="col-span-full" v-if="formProfile.has_vehicle">
                            <label for="carte-grise" class="block text-sm font-medium leading-6 text-gray-900">Carte
                                grise</label>
                            <div class="col-span-full" style="z-index:6">
                            </div>
                            <div class="mt-3 text-sm bg-gray-200 px-4 py-3 rounded" v-if="!modifyVehicleRegistration">
                                <a :href="formProfile.temporary_vehicle_registration_url" target="_blank">{{
                                    formProfile.vehicle_registration_pdf }}</a>
                            </div><button class="btn-secondary mt-3" v-if="!modifyVehicleRegistration"
                                @click.prevent="modifyVehicleRegistration = true">modifier</button>
                            <div style="position:relative">
                                <AddVehicleRegistrationPdf :profileId="route.params.profileId"
                                    @saveVehicleRegistration="saveVehicleRegistration"
                                    v-if="modifyVehicleRegistration" />
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8  line-btn">
                    <button type="button" class="text-sm font-semibold leading-6 text-gray-900">
                        Annuler
                    </button>
                    <button class="btn-primary" @click.prevent="checkRoleUrl('modify')"
                        v-if="route.params.profileId">Enregistrer</button>
                    <button type="submit" @click.prevent="checkRoleUrl('create')" v-else class="btn-primary"
                        :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>

                </div>
            </form>
        </div>

    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserCircleIcon, XCircleIcon, EnvelopeIcon } from '@heroicons/vue/24/solid';
import client from '@/api/client';
import SelectComponent from '@/components/form/SelectComponent.vue';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';
import SmallLoader from '@/components/app/SmallLoader.vue';
import { debounce } from 'lodash';
import AddVehicleRegistrationPdf from '@/components/users/AddVehicleRegistrationPdf.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import {
    Switch, SwitchGroup, SwitchLabel, SwitchDescription,
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const route = useRoute();
const router = useRouter();
const store = inject('store');
const relevantGenres = ref([]);

const props = defineProps({
    adminMakesRegistration: {
        type: Boolean,
        default: false
    },
    displayErrors: {
        type: Boolean,
        default: true
    },
    roleId: [Number, String],
});
const currentUser = ref();
const urlWs = ref('');
const profile = ref();
const mandatoryFields = ['name', 'email'];
const isFormValid = computed(() => {
    for (const field of mandatoryFields) {
        if (!formProfile.value[field]) {
            return false;
        }
    }
    return true;
})
const formProfile = ref([]);
const relevantEmploymentStatus = ref([]);
const componentKey = ref(0);
const isLoading = ref(false);
const roles = ref([]);
const selectedRole = ref(null);

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const relevantCities = ref([]);
const relevantVehicleCategories = ref([]);
const modification = ref(false);
let modifyVehicleRegistration = ref(false);
const displayErrors = ref(false);
const selectedPhoto = ref(null);
const emailsSelected = ref([]);
const newSecondaryEmail = ref([]);
const newMainEmail = ref([]);
const displayAddMainEmail = ref(false);

const roleForTitle = ref('administrateur');
const identifiantTitle = computed(() => (roleForTitle.value === 'stagiaire' ? 'Vos identifiants' : 'Identifiants'));
const identifiantDescription = computed(() => (roleForTitle.value === 'stagiaire' ? 'Vos identifiants' : 'Identifiants'));

const profileTitle = computed(() => (roleForTitle.value === 'stagiaire' ? 'Votre profil' : 'Profil'));
const profileDescription = computed(() => (roleForTitle.value === 'stagiaire' ? 'Vos informations personnelles' : 'Informations personnelles'));

const addressTitle = computed(() => (roleForTitle.value === 'stagiaire' ? 'Votre adresse personnelle' : 'Adresse personnelle'));
const addressDescription = computed(() => (roleForTitle.value === 'stagiaire' ? 'Votre adresse personnelle, utilisée pour vous envoyer des informations.' : 'Adresse personnelle, utilisée pour envoyer des informations.'));

const vehicleTitle = computed(() => (roleForTitle.value === 'stagiaire' ? 'Avez-vous un véhicule ?' : 'Informations véhicule'));
const vehicleDescription = computed(() => (roleForTitle.value === 'stagiaire' ? 'Renseignez les informations nécessaires à votre défraiement' : 'Renseignez les informations nécessaires au défraiement du transport'));

const vehicleSwitchLabel = computed(() => (roleForTitle.value === 'stagiaire' ? 'Avez-vous un véhicule ?' : 'Véhiculé ?'));

const profileId = ref(null);

const saveVehicleRegistration = async (type, message) => {
    notificationTitle.value = '';
    notificationMessage.value = '';
    modifyVehicleRegistration.value = false;

    if (type === 'success') {
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, la carte grise a bien été enregistrée';
        notificationMessage.value = '';
    } else {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        notificationMessage.value = message.message;
    }
    fetchProfile();
    store.fetchUserProfile();
};
const saveVehicle = () => {
    if (!route.params.profileId) {
        checkRoleUrl('create');
    } else {
        checkRoleUrl('modify');
    }
};
const searchCityByPostcode = async (keyword) => {

    if (keyword.length == 5) {
        isLoading.value = true;
        fetchPersonalInfoServices.fetchCityByPostcode(keyword).then((response) => {
            relevantCities.value = response;
            if (relevantCities.value.length === 1) {
                formProfile.value.city = relevantCities.value[0];
                componentKey.value++;
            }
            isLoading.value = false;
        });
    } else if (keyword.length == 0) {
        isLoading.value = false;
        formProfile.value.city = null;
    }
};

const isMissing = computed(() => (fieldName) => {
    let isTrue = 0;
    for (const field in formProfile.value.missing_fields) {
        if (formProfile.value.missing_fields[field] === fieldName) {
            isTrue++;
        }
    };
    if (isTrue > 0 && displayErrors.value) {
        return true;
    }
});
const checkRoleUrl = (type) => {
    if (Number(selectedRole.value.id) === 4) {
        urlWs.value = 'api/register/teacher';
    } else if (Number(selectedRole.value.id) === 3) {
        urlWs.value = 'api/register/pedagogical-supervisor';
    } else if (Number(selectedRole.value.id) === 1) {
        urlWs.value = 'api/register/admin';
    } else if (Number(selectedRole.value.id) === 2) {
        urlWs.value = 'api/register/manager';
    }
    let emailsSecondary = null;
    if (emailsSelected.value.length > 0) {
        emailsSecondary = emailsSelected.value;
    }
    // Initialize an empty object to store all the attributes
    let arrayToPost = {};

    // If the type is 'create', add the first set of attributes
    if (type == 'create') {
        const symbols = "!@#$%^&*()_-+=<>?";
        const numbers = "0123456789";
        const length = 10;
        let password = "";
        for (let i = 0; i < length; i++) {
            if (i % 2 === 0) {
                const randomIndex = Math.floor(Math.random() * symbols.length);
                password += symbols[randomIndex];
            } else {
                const randomIndex = Math.floor(Math.random() * numbers.length);
                password += numbers[randomIndex];
            }
        }

        arrayToPost.email = formProfile.value.email;
        arrayToPost.password = password;
        arrayToPost.password_confirmation = password;
        arrayToPost.name = formProfile.value.name;
    }

    // Add the next set of attributes
    arrayToPost.role_id = Number(selectedRole.value.id);
    arrayToPost.genre_id = formProfile.value.genre_id;
    arrayToPost.phone = formProfile.value.phone;
    arrayToPost.emails = emailsSecondary;

    // If roleId is 3 or 4, add the additional attributes
    if (Number(selectedRole.value.id) === 3 || Number(selectedRole.value.id) === 4) {
        arrayToPost.street_name = formProfile.value.street_name;
        arrayToPost.street_number = formProfile.value.street_number;
        arrayToPost.profile_zip_code = formProfile.value.profile_zip_code;
        arrayToPost.city_id = formProfile.value.city?.id;
        arrayToPost.has_vehicle = formProfile.value.has_vehicle;
        arrayToPost.iban = formProfile.value.iban;
        arrayToPost.bic = formProfile.value.bic;
        arrayToPost.vehicle_category_id = formProfile.value.vehicle_category?.id;
        arrayToPost.employment_status_id = formProfile.value.employment_status?.id;
        arrayToPost.title = formProfile.value.title;
    }
    if (type == 'create') {
        createAccount(arrayToPost);
    } else {
        saveProfile(arrayToPost);
    }
};

const createAccount = async (arrayToPost) => {
    try {
        const response = await client.post(`${urlWs.value}`, arrayToPost);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le profil a bien été créé';
        notificationMessage.value = '';
        if (selectedPhoto.value) {
            uploadFile(response.data.profile.id);
        }
        router.push({ name: 'Modifer un membre de l\'équipe', params: { profileId: response.data.profile.id } });
        profileId.value = response.data.profile.id;
        fetchProfile();
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response.data.errors) {
            const errors = Object.entries(error.response.data.errors);
            errors.forEach((errorKey) => {
                notificationMessage.value += errorKey[1] + '\n' + '\n';
            });
        } else {
            notificationMessage += error.response.data.error;
        }
    }
};
const saveProfile = async (arrayToPost) => {
    console.log(arrayToPost);
    try {
        const response = await client.put(`api/profiles/${route.params.profileId}`, arrayToPost);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le profil a bien été modifié';
        notificationMessage.value = '';
        fetchProfile();
        checkBooelanFields();
        if (profile.value.id === formProfile.value.id) {
            store.fetchUserProfile();
        }
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response.data.errors) {
            const errors = Object.entries(error.response.data.errors);
            errors.forEach((errorKey) => {
                notificationMessage.value += errorKey[1] + '\n' + '\n';
            });
        } else {
            notificationMessage += error.response.data.error;
        }
    }
};
const fetchProfile = async () => {
    try {
        const response = await client.get(`/api/profiles/${profileId.value}`);
        formProfile.value = response.data;
        checkBooelanFields();
        fetchRoles();
        fetchGenres();
        if (formProfile.value.emails) {
            emailsSelected.value = JSON.parse(formProfile.value.emails);
            if (formProfile.value.emails.length < 1) {
                displayAddEmail.value = true;
            }
        }
        componentKey.value++;
    } catch (error) {
        console.error('Failed to fetch trainee details:', error);
    }
}

const checkBooelanFields = () => {
    formProfile.value.vehicle_category = relevantVehicleCategories.value.find((vehicleCategory) => vehicleCategory.id === formProfile.value.vehicle_category_id);
    if (formProfile.value.has_vehicle === 1) {
        formProfile.value.has_vehicle = true;
    } else {
        formProfile.value.has_vehicle = false;
    }
    if (formProfile.value.vehicle_registration_pdf === '/path/to/pdf' || formProfile.value.vehicle_registration_pdf === null) {
        modifyVehicleRegistration.value = true;
    }
    componentKey.value++;
};
const fetchRoles = async () => {
    try {
        const response = await client.get('/api/roles');
        roles.value = response.data;
        if (route.params.roleId) {
            selectedRole.value = roles.value.find(role => role.id === Number(route.params.roleId));
            selectedRole.value.name = selectedRole.value.name.toLowerCase();
        } else {
            selectedRole.value = roles.value.find(role => role.id === Number(formProfile.value.role_id));
            selectedRole.value.name = selectedRole.value.name.toLowerCase();
        }
    } catch (error) {
        console.error('Failed to fetch roles:', error);
    }
}


const selectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
        selectedPhoto.value = file;
        if (route.params.profileId) {
            uploadFile(route.params.profileId);
        };
    }
}

const uploadFile = async (idProfile) => {
    const formDataProfile = new FormData();
    formDataProfile.append('profile_picture', selectedPhoto.value);
    formDataProfile.append('profile_id', idProfile);
    try {
        const response = await client.post(`/api/profile/picture`, formDataProfile, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        fetchProfile();
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
}

const addEmail = (email) => {
    emailsSelected.value.push(email);
    newSecondaryEmail.value = '';
}
const removeItem = (index) => {
    emailsSelected.value.splice(index, 1);
}
const updateEmail = async () => {
    console.log(currentUser.value);
    try {
        const response = await client.post('api/update-email', {
            email: newMainEmail.value,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo,';
        notificationMessage.value += response.data.message;
        modification.value = true;
        fetchProfile();
        displayAddMainEmail.value = false;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de la création du profil';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

const fetchGenres = async () => {
    try {
        const response = await client.get('/api/genres');
        relevantGenres.value = response.data;
    } catch (error) {
        console.error('Failed to fetch genres:', error);
    }
}

onMounted(() => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    fetchPersonalInfoServices.fetchEmploymentStatus().then((data) => {
        relevantEmploymentStatus.value = data;
    });
    fetchPersonalInfoServices.fetchVehicleCategories().then((data) => {
        relevantVehicleCategories.value = data;
    });
    componentKey.value += 1;
    if (route.params.profileId) {
        profileId.value = route.params.profileId;
        modification.value = true;
        fetchProfile();
    } else {
        fetchRoles();
    }
});
</script>
