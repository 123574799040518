<template>
  <div class="min-h-screen flex items-center flex-col justify-center bg-gray-100">
    <div class="mb-10">
      <a href="/" alt="logo">
        <img class="h-12 sm:h-16" src="@/assets/img/logo/logo.svg" alt="SIRT - Institut Régional du Travail">
      </a>
    </div>
    <div class="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
      <h2 class="mb-10 text-xl font-semibold leading-9 tracking-tight text-gray-900 text-center">Vérification de votre
        email</h2>
      <div v-if="loading">Vérification de votre email...</div>
      <div v-else>
        <AlertMessage v-if="success" type="success" :title="'Bravo, votre email a bien été vérifié !'" />
        <div v-else class="error-message">
          <AlertMessage v-if="!success" type="error" :title="'La vérification a échoué. Veuillez réessayer.'" />
        </div>
        <div class="text-center">
          <router-link to="/authentification">
            <button class="btn-primary mx-auto mt-5">Me connecter</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import client from '@/api/client';
import { useRoute } from 'vue-router';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

const loading = ref(true);
const success = ref(false);
const successMessage = ref('Bravo, votre email a bien été vérifié. Vous pouvez désormais vous connecter.');
const errorMessage = ref('La vérification a échoué. Veuillez réessayer.');
const route = useRoute();

onMounted(async () => {
  const queryParams = route.query;

  try {
    const response = await client.get('/api/email/verify', { params: queryParams });
    // Todo: check endpoint and response
    success.value = true;
    successMessage.value = response.data.message || successMessage.value;
  } catch (err) {
    success.value = false;
    errorMessage.value = err.response.data.message || errorMessage.value;
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped>
.success-message {
  color: green;
}

.error-message {
  color: red;
}
</style>
