<template>
  <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
    <div class="px-4 sm:px-0">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Modalités de défraiement</h2>
      <p class="mt-1 text-sm leading-6 text-gray-600">Type de frais remboursables. Pour bénéficier du remboursement de
        vos frais, vous devez fournir les documents justificatifs demandés. (Voir Modalités détaillées)</p>
    </div>
    <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div class="border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <!-- Dates -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Nombre d'aller/retour</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">

              <span :class="training.number_of_round_trips !== 0 ? 'badge_selected' : 'badge_unselected'">{{
                training.number_of_round_trips }}</span>
            </dd>
          </div>
          <!-- Transport -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Frais de transport</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">

              <span :class="training.transportation_fees ? 'badge_selected' : 'badge_unselected'"> {{
                training.transportation_fees ? 'oui' :
                  'non' }}</span>
            </dd>
          </div>

          <!-- Hébergement -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Hébergement <div>(uniquement si vous habitez à plus de
                50km)</div>
            </dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <span :class="training.accommodation ? 'badge_selected' : 'badge_unselected'"> {{ training.accommodation ?
                'oui' : 'non' }}</span>
            </dd>
          </div>
          <!-- Catégorie -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Nombre de repas du soir
              <div>(uniquement si hébergement)</div>

            </dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">

              <span :class="training.dinners !== 0 ? 'badge_selected' : 'badge_unselected'"> {{ training.dinners
                }}</span>
            </dd>
          </div>

          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">En savoir plus</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <a :href="training.category.irt_link" target="_blank" alt="savoir plus" class="btn-secondary">Modalités
                détaillées</a>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const props = defineProps({
  training: {
    type: [Array, Object],
    required: true,
  },
});
</script>
