<template>
  <div style="z-index:2001; position:absolute">
    <div class="px-4 sm:px-6 lg:px-8 mt-12">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Évaluations</h1>
          <p class="mt-2 text-sm text-gray-700">Liste des évaluations réalisées par les stagiaires</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div class="flex space-x-2">
          </div>
        </div>
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="x-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Utilisateur</th>
                  <th scope="col" class="w-32 text-center px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Documentation</th>
                  <th scope="col" class="w-32 text-center px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Méthodes de formation</th>
                  <th scope="col" class="w-32 text-center px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Envie de continuer</th>
                  <th scope="col" class="w-32 text-center px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Vie de groupe</th>
                  <th class="w-32 text-center px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Conditions
                    matérielles</th>
                  <th class="w-32 text-center px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Apport de
                    connaissances</th>
                  <th class="w-32 text-center px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Satisfaction
                    globale</th>

                </tr>
              </thead>

              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="evaluation in evaluations" :key="evaluation.id">
                  <td class="w-64 text-left py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div class="flex items-center">
                      <div class="h-11 w-11 flex-shrink-0">
                        <img class="h-11 w-11 rounded-full" v-if="evaluation.profile.temporary_profile_picture_url"
                          :src="evaluation.temporary_profile_picture_url" alt="" />
                        <UserCircleIcon class="h-11 w-11 text-gray-400" v-else />
                      </div>
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">{{ evaluation.profile?.user?.name }}</div>

                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <span :class="'eval ' + badgeColor(evaluation.documentation)"> {{ evaluation.documentation }} /
                      10</span>
                  </td>
                  <td class="text-center"><span :class="'eval ' + badgeColor(evaluation.training_methods)"> {{
                  evaluation.training_methods
                }} /
                      10</span></td>
                  <td class="text-center"><span :class="'eval ' + badgeColor(evaluation.willingness_to_continue)"> {{
                  evaluation.willingness_to_continue }} /
                      10</span></td>
                  <td class="text-center"><span :class="'eval ' + badgeColor(evaluation.group_life)"> {{
                  evaluation.group_life }} /
                      10</span></td>
                  <td class="text-center"><span :class="'eval ' + badgeColor(evaluation.material_conditions)"> {{
                  evaluation.material_conditions }} /
                      10</span></td>

                  <td class="text-center"><span :class="'eval ' + badgeColor(evaluation.knowledge_contribution)"> {{
                  evaluation.knowledge_contribution }} /
                      10</span></td>
                  <td class="text-center"><span :class="'eval ' + badgeColor(evaluation.overall_satisfaction)"> {{
                  evaluation.overall_satisfaction }} /
                      10</span></td>

                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
import { BarsArrowDownIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import { useRoute, useRouter } from 'vue-router';
import client from '@/api/client';

const props = defineProps({
  training: [Array, Object],
});
const evaluations = ref([]);
const isLoading = ref(false);

const statBadgeColors = {
  'level1': 'bg-red-100 text-red-700',
  'level2': 'bg-yellow-100 text-yellow-800',
  'level3': 'bg-purple-100 text-purple-700',
  'level4': 'bg-blue-100 text-blue-800',
  'level5': 'bg-green-100 text-green-800'
};
const badgeColor = (evaluation) => {
  let level = 'level1';
  if (evaluation >= 0 && evaluation <= 2) {
    level = 'level1';
  } else if (evaluation > 2 && evaluation <= 4) {
    level = 'level2';
  } else if (evaluation > 4 && evaluation <= 6) {
    level = 'level3';
  } else if (evaluation > 6 && evaluation <= 8) {
    level = 'level4';
  } else if (evaluation > 8 && evaluation <= 10) {
    level = 'level5';
  }
  return statBadgeColors[level];
}

onMounted(async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/api/evaluations/${props.training.id}`);
    console.log(response.data);
    evaluations.value = response.data;
    isLoading.value = false;
  } catch (error) {
    console.error('Failed to fetch trainings:', error);
  }
});
</script>
