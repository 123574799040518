import { reactive } from 'vue';
import client from '@/api/client';
import Cookies from 'js-cookie';

const store = reactive({
  user: null,
  userdetails: null,
  error: null,

  setUserDetails(user) {
    this.userdetails = user;
  },
  setUser(user) {
    this.user = user;
  },
  clearUser() {
    this.user = null;
  },
  setError(error) {
    this.error = error;
  },

  async logInUser(credentials) {
    try {
      const response = await client.post('/api/login', credentials);
      const { token } = response.data;
      Cookies.set('token', token);
      this.error = null;
    } catch (error) {
      this.setError('Failed to log in. Please check your credentials and try again.');
    }
  },

  async fetchUserDetails() {
    try {
      const response = await client.get('/api/user');
      const user = response.data;
      localStorage.setItem('userdetails', JSON.stringify(user));
      this.setUserDetails(user);
    } catch (error) {
      this.setError('Failed to fetch user profile.');
    }
  },

  async fetchUserProfile(router) {
    try {
      const response = await client.get('/api/profile');
      const user = response.data;

      if (response.data.message === 'Profile not found') {
        this.setUser(null);
        router.push({ name: 'Inscription' });
      } else {
        this.setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
      }
    } catch (error) {
      this.setError('Failed to fetch user profile.');
    }
  },

  async logOutUser(router) {
    try {
      await client.post('/api/logout');
      Cookies.remove('token');
      localStorage.removeItem('user');
      localStorage.removeItem('userdetails');
      this.clearUser();
      this.error = null;
      router.push({ name: 'Authentification' });
    } catch (error) {
      this.setError('Failed to log out.');
    }
  },
});

export default store;
