<template>
  <AppLayout>
    <template v-slot:default="{}">
      <div>
        <div class="md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex w-full">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{{
              currentProfile?.user?.name }}</h2>
          </div>
          <div class="max-w-7xl mt-10"><router-link :to="'/equipe/modifier-un-compte/' + currentProfile?.id"><button
                class="btn btn-secondary ml-auto mb-5">Modifier</button></router-link></div>
        </div>
      </div>
      <div class="mx-auto pt-10 lg:flex lg:gap-x-16 lg:px-8" v-if="currentProfile">
        <aside
          class="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav class="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" class="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              <li v-for="item in secondaryNavigation" :key="item.name">
                <a :href="item.href" :alt="item.name"
                  :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold']">
                  <component :is="item.icon"
                    :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                    aria-hidden="true" />
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </nav>
        </aside>

        <main class="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div id="general">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[0].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[0].description }}</p>
              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Numéro de profil</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900"> {{ currentProfile?.profile_number }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Genre</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ currentProfile?.genre?.name || 'Non déclaré' }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div>
                      <div class="text-gray-900">{{ currentProfile?.user?.email }}</div>
                      <div class="text-gray-900" v-for="email in JSON.parse(currentProfile?.emails)" :key="email">
                        {{ email }}
                      </div>
                    </div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Téléphone</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ currentProfile?.phone }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Rôle</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ currentProfile?.role?.name }}</div>

                  </dd>
                </div>

              </dl>
            </div>
            <div id="address">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[1].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[1].description }}</p>

              <ul role="list" class="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <li class="flex justify-between gap-x-6 py-6">
                  <div class="font-medium text-gray-900">{{ currentProfile?.full_address
                    }}</div>
                </li>
              </ul>
            </div>
            <div id="terms">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[2].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[2].description }}</p>
              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Conditions d'utilisation</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <span class="text-gray-900 status"
                      :class="currentProfile?.accepted_terms ? 'status7' : 'status6'">{{
                        currentProfile?.accepted_terms ? 'oui' : 'non' }}
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </main>
      </div>
    </template>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import { useRoute } from 'vue-router';
import client from '@/api/client';

import {
  CreditCardIcon,
  UserCircleIcon,
  TruckIcon,
  BriefcaseIcon,
  ShieldCheckIcon,
  HomeIcon,
  EnvelopeIcon,
} from '@heroicons/vue/24/outline';

const route = useRoute();
const store = inject('store');
const profile = ref();
const currentProfile = ref();
const currentUser = ref();
const menuProfileItems = ref([]);

const secondaryNavigation = [
  {
    name: 'Informations générales', description: 'Vos informations personnelles', href: '#general', icon: UserCircleIcon, current: true, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire', 'stagiaire']
  },
  {
    name: 'Adresse personnelle', href: '#address', description: 'Nécessaire pour les défraiements', icon: HomeIcon, current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire', 'stagiaire']
  },
  {
    name: 'Conditions d\'utilisation', description: 'Acceptation des conditons d\'utilisation', href: '#terms', icon: UserCircleIcon, current: false, auth: ['stagiaire', 'responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire']
  }
];

const fetchProfileDetails = async (id) => {
  try {
    const response = await client.get(`/api/profiles/${id}`);
    currentProfile.value = response.data;
  } catch (error) {
    console.error('Failed to fetch trainee details:', error);
  }
};
onMounted(async () => {
  fetchProfileDetails(route.params.profileId);
});

</script>
