<template>
  <DashboardLayout>
    <template v-slot:default="{ currentUserName }">
      <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left pl-8">
        <h1 class="text-lg font-semibold leading-6 text-gray-900 mt-12">Bonjour,</h1>
        <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">{{ currentUserName }}</h1>
        <p class="text-xl font-bold text-gray-900 sm:text-2xl"></p>
        <p class="text-sm font-medium text-gray-600">{{ currentUserRole }}</p>
      </div>
    </template>

    <template v-slot:primary>
      <DashboardStats :currentUserRole="currentUserRole" />
      <div>
        <CurrentTrainings :currentUserRole="currentUserRole" />
        <SpeakersTimeTracking :currentUserRole="currentUserRole" />

      </div>
    </template>

    <template v-slot:secondary>
      <ExpensesValidated :currentUserRole="currentUserRole" />
      <TrainingsToBoost :currentUserRole="currentUserRole" />
    </template>
  </DashboardLayout>
</template>

<script setup>
import {
  ref, onMounted, inject,
} from 'vue';
// Administrator components
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import DashboardStats from '@/components/dashboards/DashboardStats.vue';
import CurrentTrainings from '@/components/dashboards/administrator/CurrentTrainings.vue';
import SpeakersTimeTracking from '@/components/dashboards/administrator/SpeakersTimeTracking.vue';
import ExpensesValidated from '@/components/dashboards/administrator/ExpensesValidated.vue';
import TrainingsToBoost from '@/components/dashboards/administrator/TrainingsToBoost.vue';

const store = inject('store');

const currentUserName = ref('');
const currentUserRole = ref('');

onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    currentUserName.value = user.username;
    currentUserRole.value = user.role.name;
  }
});
</script>
