<template>

    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <div class="mx-auto max-w-12xl">
            <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900"> <span v-if="totalItems > 0"
                        class="badge badge_unselected !bg-purple-100">{{
                    totalItems }}</span> Préinscriptions à traiter
                </h3>
            </div>
            <div class="my-2">
                <SmallLoader v-if="isLoading" :text="'Chargement en cours'" />
            </div>
            <ul role="list" class="divide-y divide-gray-100" v-if="relevantTrainees.length > 0">
                <li v-for="person in relevantTrainees" :key="person.email" class="flex justify-between gap-x-6 py-5">
                    <div class="flex min-w-0 gap-x-4">
                        <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="person.profile.profile_picture"
                            alt="" v-if="person.profile.profile_picture" />
                        <UserCircleIcon class="h-11 w-11 text-gray-400" v-else />
                        <div class="min-w-0 flex-auto">
                            <button @click.prevent="gotoTrainingTab(person.training.id, 'Stagiaires')">
                                <p
                                    class="text-sm text-left font-semibold leading-6 text-gray-900 hover:text-indigo-700">
                                    {{ person.profile.name
                                    }}</p>
                            </button>
                            <p class="mt-1 flex text-xs leading-5 text-gray-500">
                                <a :href="`mailto:${person.profile?.email}`" class="truncate hover:underline">{{
                    person.profile?.email
                }}</a>
                            </p>
                        </div>
                    </div>
                    <div class="flex shrink-0 items-center gap-x-6 max-w-72">
                        <div class="hidden sm:flex sm:flex-col sm:items-end">
                            <p class="text-xs leading-4 text-gray-900 text-right  hover:text-indigo-700"><router-link
                                    :to="'/formations/' + person.training.id"> {{ person.training?.title
                                    }}</router-link>
                            </p>
                            <p class="mt-1 text-xs leading-5 text-gray-500">
                                <time :datetime="person.training?.start_date">{{
                    moment(person.training?.start_date).format("Do MMM YY") }}</time>
                            </p>
                        </div>
                        <Menu as="div" class="relative flex-none">
                            <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                <span class="sr-only">Actions</span>
                                <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                            </MenuButton>
                            <transition enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95">
                                <MenuItems
                                    class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    <MenuItem v-slot="{ active }">
                                    <router-link :to="'/stagiaires/' + person.profile.id"><a href="#"
                                            :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Profil
                                            <span class="sr-only">, {{ person.name }}</span></a></router-link>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                    <router-link :to="'/formations/' + person.training.id"><a href="#"
                                            :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Formation<span
                                                class="sr-only">, {{ person.name }}</span></a></router-link>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </li>
            </ul>
            <AlertMessage type="info" title="Aucune formation en cours"
                v-if="!isLoading && relevantTrainees.length === 0" />
            <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid'
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import moment from 'moment';
import 'moment/locale/fr';
import { UserCircleIcon } from '@heroicons/vue/24/outline';
import { useRouter } from 'vue-router';

moment.locale('fr');

const router = useRouter();
const relevantTrainees = ref([]);
const profile = ref(null);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 7;
const isLoading = ref(false);

const changePage = async (page) => {
    isLoading.value = true;
    try {
        const response = await client.get('/api/registrations?status=preinscription', {
            params: { page: page, per_page: pageSize },
        });
        relevantTrainees.value = response.data.data;
        currentPage.value = Number(response.data.current_page);
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
        isLoading.value = false;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};

const gotoTrainingTab = (idTraining, tab) => {
    router.push({ name: 'Détails de la formation', params: { id: idTraining }, query: { tab } });
};

onMounted(async () => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    changePage(1);
});

</script>
