<template>
    <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
        <Dialog as="div" class="relative !z-100" @close="$emit('close')" style="z-index:100">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 !z-100 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95">
                    <DialogPanel
                        class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                        <Combobox v-slot="{ activeOption }">
                            <div class="relative">
                                <MagnifyingGlassIcon
                                    class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true" />
                                <ComboboxInput
                                    class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                    placeholder="Rechercher..."
                                    @change="query = $event.target.value; searchPerson($event.target.value)" />
                            </div>

                            <ComboboxOptions v-if="query === '' || filteredPeople.length > 0"
                                class="flex transform-gpu divide-x divide-gray-100" as="div" static hold>
                                <div
                                    :class="['max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-96']">
                                    <div hold class="-mx-2 text-sm text-gray-700">

                                        <ComboboxOption v-for="person in displayPeople" :key="person.id" :value="person"
                                            onSelect(activeOption) as="template" v-slot="{ active }">
                                            <div
                                                :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900']">

                                                <img v-if="person?.temporary_profile_picture_url"
                                                    :src="person?.temporary_profile_picture_url" alt=""
                                                    class="h-6 w-6 flex-none rounded-full" />
                                                <UserCircleIcon class="h-6 w-6 text-gray-400" v-else />
                                                <span class="ml-3 flex-auto truncate">{{ person.user?.name }}</span>
                                                <ChevronRightIcon v-if="active"
                                                    class="ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                            </div>
                                        </ComboboxOption>

                                    </div>
                                </div>

                                <div v-if="activeOption"
                                    class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                                    <div class="flex-none p-6 text-center">
                                        <img v-if="activeOption?.temporary_profile_picture_url"
                                            :src="activeOption?.temporary_profile_picture_url" alt=""
                                            class="mx-auto h-16 w-16 rounded-full" />
                                        <UserCircleIcon class="mx-auto h-16 w-16 text-gray-400" v-else />
                                        <h2 class="mt-3 font-semibold text-gray-900">
                                            {{ activeOption.user?.name }}
                                        </h2>
                                        <p class="text-sm leading-6 text-gray-500">{{ activeOption.role?.name }}</p>
                                    </div>
                                    <div class="flex flex-auto flex-col justify-between p-6">
                                        <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                            <dt class="col-end-1 font-semibold text-gray-900">Téléphone</dt>
                                            <dd>{{ activeOption.phone }}</dd>
                                            <dt class="col-end-1 font-semibold text-gray-900">URL</dt>
                                            <dd class="truncate">
                                                <a :href="activeOption.url" class="text-indigo-600 underline">
                                                    {{ activeOption.url }}
                                                </a>
                                            </dd>
                                            <dt class="col-end-1 font-semibold text-gray-900">Email</dt>
                                            <dd class="truncate">
                                                <a :href="`mailto:${activeOption.email}`"
                                                    class="text-indigo-600 underline">
                                                    {{ activeOption.user?.email }}
                                                </a>
                                            </dd>
                                        </dl>
                                        <button type="button" @click.prevent="onSelect(activeOption)"
                                            class="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sélectionner</button>
                                    </div>
                                </div>

                            </ComboboxOptions>

                            <div v-if="query !== '' && filteredPeople.length === 0"
                                class="px-6 py-14 text-center text-sm sm:px-14">
                                <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                <p class="mt-4 font-semibold text-gray-900">Aucun formateur trouvé</p>
                                <p class="mt-2 text-gray-500">Aucune personne ne semble correspondre à cette recherche.
                                </p>
                            </div>
                        </Combobox>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        style="z-index:3000" :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { computed, ref } from 'vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { ChevronRightIcon, UsersIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import client from '@/api/client';
import { debounce } from 'lodash';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const props = defineProps({
    idTraining: [Number, String],
    type: {
        type: String,
        required: true,
    },
});

const recent = [];

const emit = defineEmits(['close', 'confirm']);

const open = ref(true);
const query = ref('');
const filteredPeople = ref([]);

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayTeacherModal = ref(false);
const selectedperson = ref(null);

function onSelect(person) {
    emit('confirm', person, props.type);
}

const onClose = () => {
    displayTeacherModal.value = false;
};
const displayPeople = computed(() => {
    if (query.value === '') {
        return recent.filter(person => person !== undefined);
    } else {
        return filteredPeople.value;
    }
});

const searchPerson = debounce(async (keyword) => {
    let roles = [3, 4];
    if (props.type === 'responsable-pedagogique') {
        roles = [3];
    }
    try {
        const response = await client.get(`api/profiles-search?name=${keyword}&role_ids=[${roles}]`);
        filteredPeople.value = response.data;
    } catch (error) {
        console.error('Failed to fetch persons', error);
    }
}, 600);

</script>
