<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />

  <BigLoader v-if="isLoading" :title="loaderTitle" :text="'Préparation en cours de votre document'" />
  <AppLayout>
    <!-- Exports -->
    <section class="mb-10">
      <h3 class="text-base font-semibold leading-6 text-gray-900">Exports</h3>
      <div class="mt-10"><button @click.prevent="exportCph('cph')" class="btn-primary mr-2">export CPH (format excel)
        </button>
      </div>
    </section>

    <DashboardStats />
    <section class="mt-20">
      <StatsByType />
    </section>

    <section class="mt-20">
      <PercentageTrainingStats />
    </section>

    <section class="mt-20">
      <NewTraineesStats />
    </section>
  </AppLayout>
</template>

<script setup>
import { ref } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import moment from 'moment';
import 'moment/locale/fr';
import BigLoader from '@/components/app/BigLoader.vue';
import DashboardStats from '@/components/dashboards/DashboardStats.vue';
// Todo: add real stats import GeneralStats from '@/components/stats/GeneralStats.vue';
import StatsByType from '@/components/stats/StatsByType.vue';
import PercentageTrainingStats from '@/components/stats/PercentageTrainingStats.vue';
import NewTraineesStats from '@/components/stats/NewTraineesStats.vue';

moment.locale('fr');
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const isLoading = ref(false);
const loaderTitle = ref('');

const exportCph = async (type) => {
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  let urlws = '';
  let fileName = '';
  if (type === 'cph') {
    urlws = '/api/export/export-cph-trainings';
    fileName = `export-cph-${startDate}.xls`;
  } else if (type === 'labor') {
    urlws = '/api/export/labor-tribunal-trainings';
    fileName = `export-cph-${startDate}.pdf`;
  }
  try {
    const response = await client.get(`${urlws}`, {
      responseType: 'blob',
    });

    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${fileName}`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
    }

    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur';
  }
};
</script>
