<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" style="z-index:2000; position:absolute" />
  <div class="mt-2 flex items-center gap-x-3">
    <button type="button"
      class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <label for="carte-grise" class="block text-sm font-medium leading-6 text-gray-900">
        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
          aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
        </svg>
        <span class="mt-2 block text-sm font-semibold text-gray-900">Déposer votre carte
          grise</span>
        <p>(pdf, max: 2048 KB)</p>
        <input id="carte-grise" name="carte-grise" type="file" @change="selectFile" class="sr-only" />
      </label>
    </button>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';

const props = defineProps({
  profileId: {
    type: [Number, String],
    required: true,
  },
});
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('')
const selectedFile = ref(null);
const emit = defineEmits(['saveVehicleRegistration', 'close']);

const selectFile = (event) => {
  selectedFile.value = event.target.files[0];
  postVehicleRegistration();
}

const postVehicleRegistration = async () => {
  const formDataReceipt = new FormData();
  formDataReceipt.append('profile_id', props.profileId);
  formDataReceipt.append('vehicle_registration_pdf', selectedFile.value);
  try {
    const response = await client.post('/api/vehicle/profile', formDataReceipt, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, la carte grise a bien été enregistrée';
    notificationMessage.value = '';
    emit('saveVehicleRegistration', 'success', response.data);
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de la carte grise';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
    emit('saveVehicleRegistration', 'error', error.response.data);
  }
}
</script>