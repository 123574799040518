/* eslint-disable */
import client from '@/api/client';

// Fetch all trainings
async function fetchTrainings(page, pageSize, timeline) {
    try {
        const response = await client.get('/api/trainings', {
            params: { current_page: page, per_page: pageSize, timeline: timeline },
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};

// Fetch all trainings by name
async function fetchTrainingsBySearchTerm(page, pageSize, searchTerm) {
    try {
        const response = await client.get(`/api/trainings/search?search_term=${searchTerm}`, {
            params: { page: page, per_page: pageSize },
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};

// Fetch training by id
async function fetchTraining(id) {
    try {
        const response = await client.get(`/api/trainings/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch training:', error);
    }
};

async function fetchTrainingCategories() {
    try {
        const response = await client.get('/api/training-categories');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch training categories:', error);
    }
};

async function fetchTrainingTypes() {
    try {
        const response = await client.get('/api/training-types');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch training types:', error);
    }
};

async function fetchTrainingThemes() {
    try {
        const response = await client.get('/api/training-themes');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch training themes:', error);
    }
};

async function fetchTrainingStatuses() {
    try {
        const response = await client.get('/api/training-statuses');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch training statuses:', error);
    }
};

async function fetchTrainingPlaces() {
    try {
        const response = await client.get('/api/training-places');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch training places:', error);
    }
};

async function fetchUnions() {
    try {
        const response = await client.get('/api/unions');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch unions:', error);
    }
};

async function fetchMandates() {
    try {
        const response = await client.get('/api/mandates');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch mandates:', error);
    }
};

async function fecthTrainingsUpcomingTeachers() {
    try {
        const response = await client.get('api/trainings/upcoming-without-teachers');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch upcoming teachers:', error);
    }
};

async function fetchTrainingsUpcomingHours() {
    try {
        const response = await client.get('api/trainings/upcoming-without-hours');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch upcoming hours:', error);
    }
}

async function fetchTrainngPastWithoutActualHours() {
    try {
        const response = await client.get('api/trainings/past-without-actual-hours');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch past without actual hours:', error);
    }
}

async function fetchTrainingsLast15Days() {
    try {
        const response = await client.get('api/trainings/last-15-days');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch past without actual hours:', error);
    }
}
async function fetchTrainingsNext15Days() {
    try {
        const response = await client.get('api/trainings/next-15-days');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch past without actual hours:', error);
    }
}

async function fetchAresStatuses() {
    try {
        const response = await client.get('/api/ares-statuses');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch Ares Statuses:', error);
    }
}

// Export all trainings services
const fetchTrainingsServices = {
    fetchTrainings,
    fetchTraining,
    fetchTrainingCategories,
    fetchTrainingTypes,
    fetchTrainingThemes,
    fetchTrainingStatuses,
    fetchUnions,
    fetchMandates,
    fetchTrainingPlaces,
    fetchTrainingsBySearchTerm,
    fecthTrainingsUpcomingTeachers,
    fetchTrainingsUpcomingHours,
    fetchTrainngPastWithoutActualHours,
    fetchTrainingsLast15Days,
    fetchTrainingsNext15Days,
    fetchAresStatuses,
};

export default fetchTrainingsServices;