<template>
  <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
    <div class="px-4 sm:px-0">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Intitulé de la formation</h2>
      <p class="mt-1 text-sm leading-6 text-gray-600">Vérifiez les informations de la formation sélectionnée</p>
    </div>
    <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div class="border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <!-- Dates -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Dates</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {{ moment(training.start_date).format("Do MMM YY - HH:mm") }} / {{
                moment(training.end_date).format("Do MMM YY - HH:mm") }}
            </dd>
          </div>
          <!-- Catégorie -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Catégorie de la formation</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {{ training.category?.name }}
            </dd>
          </div>
          <!-- Syndicat -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Syndicat</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {{ training.unions?.map(union => union?.name).join(', ') }}
            </dd>
          </div>
          <!-- Location -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Lieu</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <b>{{ training.place?.name }}</b> <br> {{ training.place?.address }} <br> {{ training.place?.zip_code }}
              {{ training.place?.city?.name }}
            </dd>
          </div>
          <!-- Conditions tarifaires -->
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-semibold text-indigo-600">Conditions tarifaires</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {{ training.paid_training === 0 ? 'Gratuit' : 'Payant' }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const props = defineProps({
  training: {
    type: [Array, Object],
    required: true,
  },
});
</script>
