<template>
  <div class="grid md:grid-cols-2 2xl:grid-cols-4 gap-5">
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900 mb-10">Nombre de formations selon les années</h3>
      <LineChart :chart-data="sessionsData" :options="chartOptions" v-if="dataInfos" />
    </div>
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900 mb-10">Nombre de jours de formation selon les années
      </h3>
      <LineChart :chart-data="daysData" :options="chartOptions" v-if="dataInfos" />
    </div>
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900 mb-10">Nombre de présents par type</h3>
      <BarChart :chart-data="attendanceData" :options="chartOptions" v-if="dataInfos" />
    </div>
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900 mb-10">Nombre de formations par type</h3>
      <BarChart :chart-data="traineeDaysData" :options="chartOptions" v-if="dataInfos" />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';
import { BarChart, PolarAreaChart, LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import 'moment/locale/fr';
import client from '@/api/client';

moment.locale('fr');

Chart.register(...registerables);
Chart.defaults.font.family = 'Sora, sans-serif';

const dataLabels = ref([]);
const dataLabelsYear = ref([]);
const dataSessions = ref([]);
const dataDays = ref([]);
const dataAttendance = ref([]);
const dataTraineeDays = ref([]);
const dataInfos = ref([]);
const categories = ref([]);
const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];
const years = ref([])

const sessionsData = computed(() => ({
  labels: years.value,
  datasets: [
  ],
}));
const daysData = computed(() => ({
  labels: years.value,
  datasets: [],
}));

const attendanceData = computed(() => ({
  labels: dataLabels.value,
  datasets: [
    {
      label: 'Nombre de présents par type',
      data: dataAttendance.value,
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      borderColor: 'rgb(79, 70, 229)',
    },
  ],
}));
const traineeDaysData = computed(() => ({
  labels: dataLabels.value,
  datasets: [
    {
      label: 'Nombre de formations par type',
      data: dataTraineeDays.value,
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      borderColor: 'rgb(79, 70, 229)',
    },
  ],
}));

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'center',
      labels: {
        boxWidth: 20,
        padding: 20,
        usePointStyle: true,
        font: {
          size: 14,
          weight: 'bold',
          family: 'Sora, sans-serif',
        },
      },
    },
  },
});

onMounted(async () => {
  try {
    const response = await client.get(`api/training-stats`);
    dataInfos.value = response.data;

    categories.value = Object.keys(response.data.trainingSessions['2024']);
    years.value = Object.keys(response.data.trainingSessions);
    /* trainingSessions */
    const sesionsValues = Object.keys(response.data.trainingSessions).map(year => {
      return {
        year: year,
        values: categories.value.map(category => ({
          category: category,
          value: response.data.trainingSessions[year][category]
        }))
      };
    });
    const sessionsByYear = [];
    categories.value.forEach((category, indx) => {
      sessionsByYear.push({
        label: category,
        data: sesionsValues.map(year => year.values.find(item => item.category === category).value),
        backgroundColor: colors[indx],
        borderColor: colors[indx],
        borderWidth: 1,
      });
    });
    sessionsData.value.datasets.push(...sessionsByYear);

    /* trainingDays */
    const daysValues = Object.keys(response.data.trainingDays).map(year => {
      return {
        year: year,
        values: categories.value.map(category => ({
          category: category,
          value: response.data.trainingDays[year][category]
        }))
      };
    });
    const daysByYear = [];
    categories.value.forEach((category, indx) => {
      daysByYear.push({
        label: category,
        data: daysValues.map(year => year.values.find(item => item.category === category).value),
        backgroundColor: colors[indx],
        borderColor: colors[indx],
        borderWidth: 1,
      });
    });
    daysData.value.datasets.push(...daysByYear);

    // Find last year
    const lastYear = Math.max(...years.value.map(year => parseInt(year)));

    const traineesPresentLastYear = response.data.traineesPresent[lastYear];
    dataAttendance.value = Object.values(traineesPresentLastYear);
    dataLabels.value = Object.keys(traineesPresentLastYear);

    const traineesDaysLastYear = response.data.traineeDays[lastYear];
    dataTraineeDays.value = Object.values(traineesDaysLastYear);
  } catch (error) {
    console.error(error);
  }
});
</script>
