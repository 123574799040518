<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="$emit('close')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ title }}</DialogTitle>
                  <div class="mt-2" :class="type === 'training-places' ? 'min-h-[600px]' : ''">
                    <div class="py-10">
                      <p class="text-sm text-gray-500">{{ message }}</p>
                      <label for="name"
                        class="block text-sm font-medium leading-6 text-gray-900 text-left mt-5 mb-2">Nom</label>
                      <input type="text" name="name" id="reference" v-model="formItem.name" class="inputSimple"
                        placeholder="Notez le nom de l'item" />
                      <div v-if="type === 'training-themes'"><label for="numerotation"
                          class="block text-sm font-medium leading-6 text-gray-900 text-left mb-2 mt-5">Numérotation</label>
                        <input type="text" name="numerotation" id="numerotation" v-model="formItem.numerotation"
                          class="inputSimple" placeholder="Notez le nom de l'item" />
                      </div>
                      <div v-if="type === 'training-categories'"><label for="link"
                          class="block text-sm font-medium leading-6 text-gray-900 text-left mb-2 mt-5">Lien en savoir
                          plus</label>
                        <input type="link" name="numerotation" id="numerotation" v-model="formItem.irt_link"
                          class="inputSimple" placeholder="Notez le nom de l'item" />
                      </div>

                      <div v-if="type === 'training-places'" class="text-left">

                        <div class="mt-5">
                          <label for="address" class="block text-sm font-medium leading-6 text-gray-900">Adresse
                            postale</label>
                          <div class="mt-2">
                            <input type="text" name="address" id="address" v-model="formItem.address"
                              autocomplete="address"
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                          </div>
                        </div>
                        <div class="mt-5">
                          <label for="type" class="block text-sm font-medium leading-6 text-gray-900">Type</label>
                          <select id="type" name="type" v-model="formItem.type"
                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <option selected="">Présentiel</option>
                            <option>Distanciel</option>
                          </select>
                        </div>

                        <div class="mt-5">
                          <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">Code
                            postal</label>
                          <div class="mt-2">
                            <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code"
                              @input="searchCityByPostcode(formItem.zip_code)" v-model="formItem.zip_code"
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                          </div>
                        </div>
                        <div class="mt-5">
                          <div v-if="formItem.city">
                            <label for="postal-code"
                              class="block text-sm font-medium leading-6 text-gray-900 mb-2">Ville</label>
                            <div
                              class="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              {{ formItem.city.name }}</div>
                          </div>
                          <div v-else>

                            <div class="mt-5 pb-10 mb-10" v-if="formItem.zip_code || formItem.city_id">
                              <SelectComponent v-model="formItem.city" :arrayData="relevantCities" :label="'Ville'"
                                :placeholder="'Choisissez une ville'" :key="componentKey" />
                            </div>
                          </div>
                          <div class="mt-9">
                            <SmallLoader v-if="isLoading" :text="'Recherche des villes associées'" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:gap-3">
                <button type="button" v-if="secondaryButtonLabel"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  @click="closeDialog" ref="cancelButtonRef">{{ secondaryButtonLabel }}</button>
                <button type="button" v-if="primaryButtonLabel && !objectToModify"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="confirm">{{ primaryButtonLabel }}</button>
                <button type="button" v-if="primaryButtonLabel && objectToModify"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="confirm">enregistrer</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/24/outline';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';
import SelectComponent from '@/components/form/SelectComponent.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';

const props = defineProps({
  title: String,
  message: String,
  primaryButtonLabel: String,
  secondaryButtonLabel: String,
  type: String,
  objectToModify: Object,
});

const open = ref(true);
// const name = ref('');
// const numerotation = ref('');
const formItem = ref([]);
const isLoading = ref(false);
const relevantCities = ref([]);
const componentKey = ref(0);

const emit = defineEmits(['close', 'confirm']);

const closeDialog = () => {
  emit('close')
};

const searchCityByPostcode = async (keyword) => {

  if (keyword.length == 5) {
    isLoading.value = true;
    fetchPersonalInfoServices.fetchCityByPostcode(keyword).then((response) => {
      relevantCities.value = response;
      if (relevantCities.value.length === 1) {
        formItem.value.city = relevantCities.value[0];
        componentKey.value++;
      }
      isLoading.value = false;
    });
  } else if (keyword.length == 0) {
    isLoading.value = false;
    formItem.value.city = null;
  }
};

const confirm = () => {
  emit('confirm', formItem.value, props.objectToModify?.id);
};

onMounted(() => {
  if (props.objectToModify) {
    formItem.value = props.objectToModify;
  }
});
</script>
