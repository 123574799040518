<template>
  <BigLoader v-if="isLoading" title="Chargement des formations" text="" />

  <div class="px-4 sm:px-6 lg:px-8 mt-20">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          Formations
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          Toutes les formations terminées, en cours et à venir.
        </p>
      </div>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                  Intitulé
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date de début
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date de fin
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Catégorie
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Type
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Participants
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Statut
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Lieu
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                  <span class="sr-only">Détails</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for=" relevantTraining  in  relevantTrainings " :key="relevantTraining.id" class="even:bg-gray-50">
                <td class="py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-3">
                  {{ relevantTraining.title }}

                </td>

                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ moment(relevantTraining.start_date).format("Do MMM YY") }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ moment(relevantTraining.end_date).format("Do MMM YY") }}
                </td>
                <td class="whitespace nowrap px-3 py-4 text-sm text-gray-500">
                  <span>{{
    relevantTraining.category?.name }}</span>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span class="status status1"> {{
    relevantTraining.type?.name }}</span>
                </td>
                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                  <div class="text-gray-900">{{ relevantTraining.registered_count }} inscrits / {{
    relevantTraining.max_attendees }}</div>
                  <div class="mt-1 text-gray-500">
                    {{ relevantTraining.pre_registered_count }} préinscrits
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span :class="'status' +
    relevantTraining.status?.id
    " class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium">
                    {{ relevantTraining.status?.name }}
                  </span>
                </td>
                <td class="px-3 py-4 text-xs text-gray-500">
                  {{ relevantTraining.place?.name }}
                </td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                  <router-link :to="'/formations/' + relevantTraining.id"
                    class="text-indigo-600 hover:text-indigo-900">Détails<span class="sr-only">,
                      {{ relevantTraining.title }}</span></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import moment from 'moment';
import BigLoader from '@/components/app/BigLoader.vue';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/20/solid';

const router = useRouter();;
const isLoading = ref(false);
const profile = ref();

// Trainings data
const relevantTrainings = ref([]);
const props = defineProps({
  teacher: {
    type: Object,
    required: true,
  },
});

const filterTrainings = (type) => {
  timeline.value = type;
  changePage(1);
};

const changePage = async (page) => {
  isLoading.value = true;
  try {
    const response = await client.get(`/api/profiles/${props.teacher.id}/trainings`);
    relevantTrainings.value = response.data.trainings;
    isLoading.value = false;
  } catch (error) {
    console.error('Failed to fetch trainings:', error);
  }
};
onMounted(async () => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  changePage(1);
});
</script>
