<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <ExpenseUpload v-if="displayExpenseUpload" @close="displayExpenseUpload = false" style="z-index: 2000;"
    :expense="expenseId" @savedReceipt="receiptResponse" />
  <div class="mt-10">
    <TraineeRegistrationExpense :training="training" />
    <div v-if="profile?.role?.slug === 'stagiaire' && registration?.registration_status === 'Validé'">
      <h2 class="text-base font-semibold leading-7 text-gray-900 mt-10">Mes justificatifs</h2>
      <div class="mt-10"><button type="button" @click.prevent="addExpense"
          class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <div class="mx-auto"> <svg class="mb-3 mx-auto h-12 w-12 text-gray-400 cursor-pointer" stroke="currentColor"
              fill="#6b7280" width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="m849.91 1089.1c7.3633 0 13.332-5.9648 13.332-13.332v-66.316h83.543c7.3633 0 13.332-5.9648 13.332-13.332v-871.97c0-7.3711-5.9688-13.332-13.332-13.332h-613.61c-7.3633 0-13.332 5.9648-13.332 13.332v76.184h-66.621c-7.3633 0-13.332 5.9648-13.332 13.332v862.1c0 7.3711 5.9688 13.332 13.332 13.332zm-503.41-951.61h586.95v845.3h-70.207v-769.12c0-7.3711-5.9688-13.332-13.332-13.332h-503.41zm-79.953 89.516h570.03v835.43h-570.03z" />
              <path
                d="m708.29 776.61h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3672-5.9727-13.332-13.336-13.332z" />
              <path
                d="m708.29 858.45h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332s-5.9727-13.332-13.336-13.332z" />
              <path
                d="m708.29 940.3h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9727-13.332-13.336-13.332z" />
              <path
                d="m422.41 563.63c0 7.3711 5.9688 13.332 13.332 13.332h22.293c20.18 52.465 70.969 89.855 130.45 89.855 20.078 0 39.504-4.207 57.746-12.5 6.6992-3.0469 9.6602-10.949 6.6133-17.656-3.0469-6.707-10.965-9.6875-17.656-6.6133-14.746 6.707-30.461 10.105-46.707 10.105-44.445 0-82.879-25.82-101.38-63.191h104.48c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9688-13.332-13.332-13.332h-113.83c-1.5859-7.5352-2.4453-15.332-2.4453-23.332 0-7.9961 0.85938-15.789 2.4414-23.32h113.83c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9688-13.332-13.332-13.332h-104.49c18.504-37.371 56.938-63.191 101.39-63.191 16.238 0 31.953 3.3984 46.719 10.09 6.6719 3.0742 14.602 0.078126 17.648-6.6289 3.0391-6.707 0.070312-14.609-6.6328-17.645-18.25-8.293-37.676-12.488-57.734-12.488-59.48 0-110.27 37.395-130.45 89.855h-22.293c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h15.004c-1.2852 7.6016-2.1055 15.359-2.1055 23.32 0 7.9648 0.82422 15.727 2.1094 23.332h-15.004c-7.3477 0.007812-13.32 5.9688-13.32 13.34z" />
            </svg>
          </div>
          <span class="mt-2 block text-sm font-semibold text-gray-900">Ajouter un justificatif</span>
        </button></div>
    </div>
    <ul>
      <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6" v-for="receipt in expense.receipts"
        :key="receipt.id">
        <div class="flex w-0 flex-1 items-center">
          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
          <div class="ml-4 flex min-w-0 flex-1 gap-2">
            <span class="truncate font-medium">{{ receipt.description }}</span>
          </div>
        </div>
        <div class="ml-4 flex-shrink-0">
          <a :href="receipt.temporary_receipt_url" target="_blank"
            class="font-medium text-indigo-600 hover:text-indigo-500">Voir</a>
          <button @click.prevent="displayConfirmDeleteDialog = true; idTypeToEdit = receipt.id" type="button"
            class="font-medium text-indigo-600 hover:text-indigo-500 ml-4">Supprimer</button>
        </div>
      </li>
    </ul>
    <ConfirmationDialog @confirm="deleteReceipt(idTypeToEdit, type)" v-if="displayConfirmDeleteDialog"
      :title="confirmTexts[0].title" :message="confirmTexts[0].message"
      :primaryActionLabel="confirmTexts[0].primaryActionLabel"
      :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
  </div>
</template>
<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import TraineeRegistrationExpense from '@/components/registration/TraineeRegistrationExpense.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import ExpenseUpload from '@/components/expenses/ExpenseUpload.vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { PaperClipIcon } from '@heroicons/vue/20/solid';
import client from '@/api/client';
import { useRoute } from 'vue-router';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';

moment.locale('fr');

const route = useRoute();
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayExpenseUpload = ref(false);
const expense = ref([]);
const profile = ref([]);
const noExpense = ref(false);
const expenseId = ref(null);
const componentKey = ref(0);
const idTypeToEdit = ref(null);
const displayConfirmDeleteDialog = ref(false);

const props = defineProps({
  training: {
    type: [Array, Object],
    required: true,
  },
  registration: [Object, Array],
});

const confirmTexts = [
  {
    title: 'Supprimer ce document ?',
    message: 'Voulez-vous supprimer ce document ?',
    primaryActionLabel: 'supprimer',
    secondaryActionLabel: 'annuler'
  },
]

const receiptResponse = (type, response) => {
  displayExpenseUpload.value = false;
  if (type === 'success') {
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, le justificatif a bien été ajouté';
    notificationMessage.value = '';
    fetchExpense();
  } else {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement';
    notificationMessage.value = response;
  }
}
const fetchExpense = async () => {
  try {
    const response = await client.get(`/api/training/${props.training.id}/profile/${profile.value.id}/expense`);
    noExpense.value = false;
    expenseId.value = response.data.id;
    expense.value = response.data;
  } catch (error) {
    noExpense.value = true;
  }
}

const addExpense = () => {
  if (noExpense.value) {
    postExpense();
  } else {
    displayExpenseUpload.value = true;
  }
};

const postExpense = async () => {
  try {
    const response = await client.post(`api/training-expenses`, {
      training_id: props.training.id,
      profile_id: profile.value.id,
      training_expense_status_id: 1,
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été créé';
    notificationMessage.value = '';
    expenseId.value = response.data.id;
    displayExpenseUpload.value = true;
    componentKey.value += 1;
  } catch (error) {
    notificationType.value = 'error';
    setTimeout(() => { showNotification.value = false; }, 3000);
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
}

const deleteReceipt = async (itemToDelete) => {
  displayConfirmDeleteDialog.value = false;
  try {
    const response = await client.delete(`api/receipts/${itemToDelete}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, le document a bien été supprimé ';
    notificationMessage.value = '';
    fetchExpense();
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};

onMounted(async () => {
  profile.value = JSON.parse(localStorage.getItem('user'));

  fetchExpense();
});
</script>