<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />

  <!--mandats-->
  <div class="col-span-full" v-if="type === 'mandates' && relevantMandates">
    <span v-for="item in mandates" :key="item.id">
      <button type="button" class="badge_selected" v-if="item.pivot.is_valid">
        {{ item.name }} <span v-if="item.name === 'Conseiller Prud’hommes'" class="status status1">{{
    getCouncil(item.pivot?.council_id) }}</span><span class="status status2"
          v-if="item.name === 'Conseiller Prud’hommes'">{{ getCouncilSection(item.pivot?.council_section_id) }}</span>
        <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" v-if="item.pivot.is_valid"
          @click.prevent="changeMandate(item, false);" />
      </button></span>
  </div>
  <div class="mt-5" style="z-index:9; position:relative;">
    <SelectComponent v-model="mandateSelected" :arrayData="relevantMandates" :label="'Mandats'"
      :placeholder="'Ajoutez un mandat'" :key="componentKey" @update:model-value="openMandate" />
  </div>
  <!--modale d'édition-->
  <div style="z-index:200; position:relative;" v-if="open"
    class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 mt-5 ">
    <div class="px-3 py-6 sm:p-8">
      <div class="col-span-full mb-5" v-if="mandatePrudhommes" style="position:relative;z-index:10;">

        <SelectComponent v-model="formProfile.council" :arrayData="relevantCouncils" :label="'Conseil prud\'hommes'"
          :placeholder="'Choisissez un conseil'" :key="componentKey" />
      </div>
      <div class="col-span-full mb-3" style="position:relative;z-index:ç;">
        <SelectComponent v-model="formProfile.council_section" :arrayData="relevantCouncilSections"
          :label="'Section prud\'hommes'" :placeholder="'Choisissez une section'" :key="componentKey" />
      </div>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button v-if="typeEdition === 'creation'" type="button"
          class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          @click="createMandate">Créer</button><button type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          @click="open = false" ref="cancelButtonRef">Annuler</button>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';
import SelectComponent from '@/components/form/SelectComponent.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { CheckIcon, ChevronUpDownIcon, XCircleIcon, PencilSquareIcon } from '@heroicons/vue/20/solid';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const props = defineProps({
  adminMakesRegistration: {
    type: Boolean,
    default: false
  },
  displayErrors: {
    type: Boolean,
    default: true
  },
  unions: [Array, Object],
  mandates: [Array, Object],
  type: String,
  profileId: [Number, String],
});
const emit = defineEmits(['update']);
const formProfile = ref([
  {
    mandate_id: null,
    council_id: null,
    council_section_id: null,
  },
]);
const relevantUnions = ref([]);
const relevantCouncils = ref([]);
const relevantCouncilSections = ref([]);
const relevantMandates = ref([]);
const componentKey = ref(0);

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const open = ref(false);
const unionSelected = ref(null);
const mandateSelected = ref(null);
const typeEdition = ref('creation');
let isValid = ref(false);
let mandatePrudhommes = ref(false);

const changeMandate = async (mandate, type) => {
  console.log('mandate', mandate.council_id);
  console.log('type', type);
  let council_id = mandate.council_id;
  let council_section_id = mandate.council_section_id;
  if (!mandate.council_id) {
    council_id = null;
  }
  if (!mandate.council_section_id) {
    council_section_id = null;
  }
  try {
    const response = await client.put(`/api/profiles/${props.profileId}/mandates/${mandate.id}`, {
      mandate: mandate.id,
      is_valid: type,
      council_id: council_id,
      council_section_id: council_section_id,
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value = '';
    emit('update');
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};

const getCouncil = ((id) => {
  const council = relevantCouncils.value.find(item => item.id === id);
  if (council) {
    return council.name;
  }
  return '';
});
const getCouncilSection = ((id) => {
  const councilSection = relevantCouncilSections.value.find(item => item.id === id);
  if (councilSection) {
    return councilSection.name;
  }
  return '';
});
const openMandate = async () => {
  if (mandateSelected.value.name === 'Conseiller Prud’hommes') {
    mandatePrudhommes.value = true;
    open.value = true;
    componentKey.value += 1;
  } else {
    mandatePrudhommes.value = false;
    createMandate();
  }
};

const createMandate = async () => {
  let council_id = null;
  if (formProfile.value.council) {
    council_id = formProfile.value.council.id;
  }
  let council_section_id = null;
  if (formProfile.value.council_section) {
    council_section_id = formProfile.value.council_section.id;
  }
  try {
    const response = await client.post(`/api/profiles/${props.profileId}/mandates`, {
      mandate_id: mandateSelected.value.id,
      is_valid: true,
      council_id: council_id,
      council_section_id: council_section_id,
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value = '';
    mandateSelected.value = null;
    emit('update');
    open.value = false;
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};


onMounted(async () => {
  fetchPersonalInfoServices.fetchUnions().then((data) => {
    relevantUnions.value = data;
  });

  fetchPersonalInfoServices.fetchMandates().then((data) => {
    relevantMandates.value = data;
  });
  fetchPersonalInfoServices.fetchCouncils().then((data) => {
    relevantCouncils.value = data;
  });
  fetchPersonalInfoServices.fetchCouncilSections().then((data) => {
    relevantCouncilSections.value = data;
  });

  const filteredMandates = computed(() => {
    return mandates.filter(mandate => {
      const council = relevantCouncils.value.find(council => council.id === mandate.council_id);
      return council && council.name === mandate.name;
    });
  });
  const mandateEntries = ref([]);

  props.mandates.forEach(mandate => {
    if (mandate.council_id) {
      getCouncil(mandate.council_id);

    }
  });

});
</script>
