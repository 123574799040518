<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    style="z-index:3000" :detail="notificationMessage" @close="showNotification = false" />
  <AppLayout>
    <div v-if="dialogProps.open">
      <ConfirmationDialog :key="componentKey" :open="dialogProps.open" v-bind="dialogProps" @confirm="disableAccount"
        @close="resetDialog" />
    </div>

    <div class="sm:flex sm:items-center mb-10">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">Équipe</h1>
        <p class="mt-2 text-sm text-gray-700">Liste des administrateurs•rices, gestionnaires, et responsables
          pédagogiques</p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex gap-x-1.5">
          <button type="button" @click.prevent="gotoUserCreation(1)" class="btn-secondary mr-2">Créer
            un admin</button>
          <button type="button" @click.prevent="gotoUserCreation(2)" class="btn-secondary">Créer
            un gestionnaire</button>
        </div>
      </div>
    </div>
    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <li v-for="person in teammates " :key="person.id"
        class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
        <div class="flex flex-1 flex-col p-8">
          <img v-if="person.temporary_profile_picture_url"
            class="mx-auto h-32 w-32 flex-shrink-0 rounded-full object-cover"
            :src="person.temporary_profile_picture_url" alt="{{ person.user.name }}" />
          <UserCircleIcon v-else class="mx-auto h-32 w-32 flex-shrink-0 rounded-full object-cover text-gray-300" />
          <button class="mt-6 text-sm font-medium text-gray-900" @click="gotoProfile(person.role.slug, person.id)">
            {{
              person.user?.name }}
          </button>
          <dl class="mt-1 flex flex-grow flex-col justify-between">
            <dt class="sr-only">Title</dt>
            <dd class="text-sm text-gray-500">{{ person.title }}</dd>
            <dt class="sr-only">Role</dt>
            <dd class="mt-3">
              <span :class="roleBadgeClass(person.role.slug)">
                {{ person.role.name }}
              </span>
              <span class="status ml-2" :class="person.is_active ? 'status5' : 'status6'">
                {{ person.is_active ? 'Actif' : 'Inactif' }}
              </span>
            </dd>
          </dl>
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="flex w-0 flex-1">
              <router-link :to="'/equipe/modifier-un-compte/' + person.id" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border
                border-transparent py-4 text-sm font-semibold text-gray-900">
                <PencilSquareIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                Modifier
              </router-link>

            </div>
            <div class="-ml-px flex w-0 flex-1">
              <a :href="`mailto:${person.email}`" @click.prevent="openConfirmationDialog(person)"
                class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm text-gray-900">
                <TrashIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                Désactiver
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </AppLayout>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import { TrashIcon, PencilSquareIcon } from '@heroicons/vue/20/solid';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/outline';
import { useRouter } from 'vue-router';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const router = useRouter();
const teammates = ref([]);

async function fetchAppUsers() {
  try {
    const response = await client.get('/api/training-top-staff');
    teammates.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

onMounted(() => {
  fetchAppUsers();
});

const dialogProps = ref({
  open: false,
  title: '',
  message: '',
  primaryActionLabel: '',
  secondaryActionLabel: '',
});

const selectedPerson = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const componentKey = ref(0);

const openConfirmationDialog = (person) => {
  selectedPerson.value = person;

  dialogProps.value = {
    open: true,
    title: 'Voulez-vous désactiver cet utilisateur?',
    message: `Êtes-vous sûre de vouloir désactiver le compte de ${person.user.name}?`,
    primaryActionLabel: 'Oui, je confirme',
    secondaryActionLabel: 'Non',
  };

  window.console.log(dialogProps.value);
};

const resetDialog = () => {
  dialogProps.value = {
    open: false,
    title: '',
    message: '',
    primaryActionLabel: '',
    secondaryActionLabel: '',
  };
  selectedPerson.value = null;

  componentKey.value += 1;

  window.console.log(dialogProps.value.open);
};

const disableAccount = async () => {
  try {
    const response = await client.post(`api/profiles/${selectedPerson.value.id}/deactivate`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, le profil a bien été modifié';
    notificationMessage.value = response.data.message;
    fetchAppUsers();
    dialogProps.value.open = false;
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};

const roleBadgeClass = (roleSlug) => {
  switch (roleSlug) {
    case 'administrateur':
      return 'inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/10';
    case 'gestionnaire':
      return 'inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10';
    default:
      return 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10';
  }
};

const gotoProfile = (roleSlug, idPerson) => {
  if (roleSlug === 'administrateur' || roleSlug === 'gestionnaire') {
    router.push({ name: 'Membre de l\'équipe', params: { profileId: idPerson } });
  } else {
    router.push({ name: 'Détails de l\'enseignant', params: { id: idPerson } });
  }
};
const gotoUserCreation = (idRole) => {
  router.push({ name: 'Créer un membre de l\'équipe', params: { roleId: idRole } });
};
</script>
