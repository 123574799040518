<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div>
                                <div
                                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                                    <CalendarIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
                                </div>
                                <div class="mt-3 sm:mt-5">
                                    <DialogTitle as="h3"
                                        class="text-base font-semibold leading-6 text-gray-900 text-center mb-2">{{
                                            event.title }} <div class="text-indigo-600">{{
                                            moment(event.start_date).format("Do MMM YY - HH:mm") }} / {{
                                                moment(event.end_date).format("Do MMM YY - HH:mm") }}</div>
                                    </DialogTitle>
                                    <div class="text-center"><span class="status status5">{{ event.category?.name
                                            }}</span></div>
                                    <div class="mt-2 text-center">
                                        <span class="status status3">{{ event.type?.name
                                            }}</span>
                                    </div>
                                    <div v-if="event.pedagogical_supervisors[0]"
                                        class="flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-2 pt-2 mt-10">
                                        <dt class="flex-none">
                                            <span class="sr-only">Responsable</span>
                                            <UserIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd class="text-sm font-medium leading-6 text-gray-900">{{
                                            event.pedagogical_supervisors[0]?.user.name }}</dd>
                                    </div>
                                    <div v-if="event.place?.name"
                                        class="mt-2 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-2 pt-2 mb-10">
                                        <dt class="flex-none">
                                            <span class="sr-only">Responsable</span>
                                            <MapPinIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd class="text-sm font-medium leading-6 text-gray-900 leading-4">{{
                                            event.place?.name }}</dd>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button type="button"
                                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                    @click="open = false"><router-link class="inline-flex"
                                        :to="'formations/' + event.id">Détails de la formation</router-link></button>
                                <button type="button"
                                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                    @click="open = false" ref="cancelButtonRef">Fermer</button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { CalendarIcon, MapPinIcon, UserIcon } from '@heroicons/vue/24/outline';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const open = ref(true);

defineProps({
    event: Object,
});
</script>
