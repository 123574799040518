<template>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">
        <div class="mx-auto max-w-12xl">
            <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900"><span v-if="totalItems > 0"
                        class="badge badge_unselected !bg-purple-100">{{
                    totalItems }}</span> Formations des 15 derniers jours</h3>
            </div>
            <div class="my-4">
                <SmallLoader v-if="isLoading" :text="'Chargement en cours'" />
            </div>
            <ul role="list" class="divide-y divide-gray-100">
                <li v-for="training in relevantTrainings" :key="training.id"
                    class="flex items-center justify-between gap-x-6 py-5">
                    <div class="min-w-0">
                        <div class="flex items-start gap-x-3">
                            <router-link :to="'/formations/' + training.id">
                                <p class="text-sm font-semibold leading-6 text-gray-900">{{ training.title }}</p>
                            </router-link>
                        </div>
                        <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p class="whitespace-nowrap">
                                Du <time :datetime="training.start_date">{{
                    moment(training.start_date).format("Do MMM YY") }}</time> au <time
                                    :datetime="training.end_date">{{
                    moment(training.end_date).format("Do MMM YY") }}</time>
                            </p>
                        </div>

                    </div>
                    <div class="flex flex-none items-center gap-x-4">
                        <span :class="getStatusClass(training.registeredTrainees, training.invitationsTobeSent)"
                            v-if="training.attendance_sheet_done"
                            class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium">
                            présence saisie
                        </span>
                        <router-link :to="'/formations/' + training.id"><button type="button"
                                class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                                Voir<span class="sr-only">, {{ training.training_title }}</span></button></router-link>
                    </div>
                </li>
            </ul>
            <AlertMessage type="info" title="Aucune formation pour le moment" class="mb-4"
                v-if="!isLoading && relevantTrainings.length === 0" />
            <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid'
import { EnvelopeIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const getStatusClass = (registeredTrainees, invitationsTobeSent) => {
    const difference = parseInt(registeredTrainees) - parseInt(invitationsTobeSent);
    if (difference < 0) {
        return 'bg-green-100 text-green-700';
    } else if (difference > 0) {
        return 'bg-red-100 text-red-800';
    } else {
        return 'bg-yellow-100 text-yellow-700';
    }
}
const profile = ref(null);
const relevantTrainings = ref([]);
const isLoading = ref(false);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 7;

const changePage = async (page) => {
    isLoading.value = true;
    try {
        const response = await client.get('api/trainings/last-15-days', {
            params: { page: page, per_page: pageSize },
        });
        relevantTrainings.value = response.data.data;
        currentPage.value = Number(response.data.current_page);
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
        isLoading.value = false;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};
onMounted(async () => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    changePage(1);
});
</script>
