<template>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">
        <div class="mx-auto max-w-12xl">
            <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900"><span v-if="totalItems > 0"
                        class="badge badge_unselected !bg-purple-100">{{
                    totalItems }}</span>Formations à booster</h3>
            </div>
            <div class="my-2">
                <SmallLoader v-if="isLoading" :text="'Chargement en cours'" />
            </div>
            <ul role="list" class="divide-y divide-gray-100" v-if="relevantTrainings.length > 0">
                <li v-for="training in relevantTrainings" :key="training.id"
                    class="flex items-center justify-between gap-x-6 py-5">
                    <div class="min-w-0">
                        <div class="flex items-start gap-x-3">
                            <router-link :to="'/formations/' + training.id">
                                <p class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">{{
                    training.title }}</p>
                            </router-link>
                        </div>
                        <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p class="whitespace-nowrap">
                                Débute le <time :datetime="training?.start_date">{{
                    moment(training?.start_date).format("Do MMM YY") }}</time>
                            </p>
                            <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                                <circle cx="1" cy="1" r="1" />
                            </svg>
                            <p class="truncate"><span class="status status3">{{ training.registered_count }} inscrits /
                                    {{ training.max_attendees }}
                                    places</span></p>
                        </div>
                    </div>
                    <div class="flex flex-none items-center gap-x-4">
                        <span class="status status4 !text-base">{{ Math.round(training.inscription_rate * 100) / 100 }}
                            %</span>
                        <router-link :to="'/formations/' + training.id"><a :href="training.href"
                                class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                                Voir<span class="sr-only">, {{ training.title }}</span></a></router-link>
                    </div>
                </li>
            </ul>
            <AlertMessage type="info" title="Aucune formation pour le moment" class="mb-4"
                v-if="!isLoading && relevantTrainings.length === 0" />
            <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const relevantTrainings = ref([]);
const profile = ref(null);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 7;
const isLoading = ref(false);

const changePage = async (page) => {
    isLoading.value = true;
    try {
        const response = await client.get('api/trainings/low-inscription-alert', {
            params: { page: page, per_page: pageSize },
        });
        relevantTrainings.value = response.data.data;
        currentPage.value = Number(response.data.current_page);
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
        isLoading.value = false;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};
onMounted(async () => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    changePage(1);
});

</script>
