<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="$emit('close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:w-full max-w-unset sm:max-w-sm sm:p-6">
                            <div>
                                <div
                                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                    <UserIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                                </div>
                                <div class="mt-3 text-left sm:mt-5">
                                    <form class="">
                                        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div class="col-span-full">
                                                <label for="name"
                                                    class="block text-md font-medium leading-6 text-gray-900">Prénom
                                                    Nom</label>
                                                <div class="mt-2">
                                                    <input type="text" name="name" id="name" required="required"
                                                        v-model="formUser.name" placeholder="Fatima Dupont"
                                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                </div>
                                            </div>
                                            <div class="col-span-full">
                                                <div class="mt-2">
                                                    <label for="email"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                                                    <input type="email" name="email" id="email" autocomplete="email"
                                                        required="required" v-model="formUser.email"
                                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                </div>
                                            </div>

                                            <div class="col-span-full">
                                                <label for="account-number"
                                                    class="block text-sm font-medium leading-6 text-gray-900">Mot de
                                                    passe</label>
                                                <div class="relative mt-2 rounded-md shadow-sm">
                                                    <input :type="showPassword ? 'password' : 'text'" name="password"
                                                        id="password" autocomplete="password" required="required"
                                                        v-model="formUser.password"
                                                        class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="mot de passe" />
                                                    <div class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                                                        @click="showPassword = !showPassword"
                                                        @keydown.enter="showPassword = !showPassword" tabindex="0">
                                                        <span class="sr-only">Voir mot de passe</span>
                                                        <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-full">
                                                <label for="account-number"
                                                    class="block text-sm font-medium leading-6 text-gray-900">Confirmation
                                                    de Mot de
                                                    passe</label>
                                                <div class="relative mt-2 rounded-md shadow-sm">
                                                    <input :type="showPassword ? 'password' : 'text'"
                                                        name="passwordConfirmation" id="passwordConfirmation"
                                                        v-model="formUser.passwordConfirmation"
                                                        :class="[checkPassword ? 'ring-green-300' : 'ring-red-300']"
                                                        class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="mot de passe" />
                                                    <div @click="showPassword = !showPassword"
                                                        @keydown.enter="showPassword = !showPassword" aria-hidden="true"
                                                        class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                                                        tabindex="0">
                                                        <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <p class="mt-2 text-sm text-red-600" id="email-error"
                                                    v-if="!checkPassword">Le mot de
                                                    passe est différent.</p>
                                            </div>
                                            <div class="col-span-full">
                                                <SmallLoader v-if="isLoading" :text="'Création de compte en cours'" />
                                                <AlertMessage v-if="errorMessage" type="error" :title="errorMessage" />
                                                <AlertMessage v-if="successMessage" type="success"
                                                    :title="successMessage" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6">
                                <button type="button" @click="createUser"
                                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                                    mon compte</button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, watch, defineEmits } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { UserIcon, EyeIcon } from '@heroicons/vue/24/outline';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import client from '@/api/client';
import SmallLoader from '@/components/app/SmallLoader.vue';

const formUser = ref({
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
});

defineProps({
    open: Boolean,
});
const emit = defineEmits(['close']);
const errorMessage = ref('');
const successMessage = ref('');
let showPassword = ref(true);
const checkPassword = computed(() => formUser.value.password === formUser.value.passwordConfirmation);

const showPasswordError = ref(false);
const isLoading = ref(false);

watch(() => formUser.value.passwordConfirmation, (newVal) => {
    showPasswordError.value = newVal.length >= formUser.value.password.length && !checkPassword.value;
});

const createUser = async () => {
    isLoading.value = true;
    errorMessage.value = ''
    try {
        const response = await client.post('/api/register', {
            name: formUser.value.name,
            email: formUser.value.email,
            password: formUser.value.password,
        });
        successMessage.value = 'L\'utilisateur a été créé avec succès. Vous allez recevoir un email de confirmation. Veuillez confirmer votre compte par email.';
        isLoading.value = false;
        setTimeout(() => { emit('close'); }, 4000);
    } catch (error) {
        isLoading.value = false;
        errorMessage.value = 'Erreur lors de la création de l\'utilisateur.' + '\n';
        if (error.response.data.errors) {
            const errors = Object.entries(error.response.data.errors);
            errors.forEach((errorKey) => {
                errorMessage.value += errorKey[1] + '.\n' + '\n';
            });
        } else {
            errorMessage.value += error.response.data.error;
        }
    }
}
</script>