<template>
  <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900 mb-10">Nombre de sessions par type</h3>
      <DoughnutChart :chart-data="sessionsData" :options="chartOptions" v-if="dataInfos" />
    </div>
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900 mb-10">Nombre de jours de formations par type</h3>
      <DoughnutChart :chart-data="daysData" :options="chartOptions" v-if="dataInfos" />
    </div>
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900 mb-10">Stagiaires présents par type de formation</h3>
      <DoughnutChart :chart-data="traineesData" :options="chartOptions" v-if="dataInfos" />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';
import { BarChart, PolarAreaChart, LineChart, DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

import moment from 'moment';
import 'moment/locale/fr';
import client from '@/api/client';

moment.locale('fr');

Chart.register(...registerables);
Chart.defaults.font.family = 'Sora, sans-serif';

const dataLabels = ref([]);
const dataLabelsYear = ref([]);
const dataSessions = ref([]);
const dataDays = ref([]);
const dataTrainees = ref([]);
const dataInfos = ref([]);
const sessionsData = computed(() => ({
  labels: dataLabels.value,
  datasets: [
    {
      label: 'Nombre de formations selon les années',
      data: dataSessions.value,
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      borderColor: 'rgb(255, 255, 255)',
      borderWidth: 1,
    },
  ],
}));
const daysData = computed(() => ({
  labels: dataLabels.value,
  datasets: [
    {
      label: 'Nombre de jours de formation selon les années',
      data: dataDays.value,
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      borderColor: 'rgb(255, 255, 255)',
      borderWidth: 1,
    },
  ],
}));


const traineesData = computed(() => ({
  labels: dataLabels.value,
  datasets: [
    {
      label: 'Nombre de formations par type',
      data: dataTrainees.value,
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      borderColor: 'rgb(255, 255, 255)',
    },
  ],
}));

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'center',
      labels: {
        boxWidth: 20,
        padding: 20,
        usePointStyle: true,
        font: {
          size: 14,
          weight: 'bold',
          family: 'Sora, sans-serif',
        },
      },
    },
  },
});

onMounted(async () => {
  try {
    const response = await client.get(`api/percentage-training-stats`);
    dataInfos.value = response.data;
    dataLabels.value = dataInfos.value.session_percentages.map((item) => item.type);
    dataSessions.value = dataInfos.value.session_percentages.map((item) => item.percentage);
    dataDays.value = dataInfos.value.days_percentages.map((item) => item.percentage);
    dataTrainees.value = dataInfos.value.trainee_percentages.map((item) => item.percentage);

  } catch (error) {
    console.error(error);
  }
});
</script>
