<template>
    <div style="z-index:2001; position:absolute">
        <ReimbursmentDialog :title="'Modifier la demande de remboursement'" v-if="displayReimbursementDialog"
            :trainingId="training.id" :traineeId="reimbursedTraineeId" :traineeName="reimbursedTraineeName"
            :reimbursed="reimbursedTrainee" :accommodation="accommodationTrainee"
            :message="'Voulez-vous modifier la demande de remboursement ?'" primaryButtonLabel='Modifier'
            secondaryButtonLabel='Fermer' @close="displayReimbursementDialog = false" :key="reimbursedKey"
            @confirm="fetchExpenses" />
        <ReimbursmentTeacherDialog :title="'Modifier les besoins du formateur'" v-if="displayReimbursementTeacherDialog"
            :trainingId="training.id" :teacherId="reimbursedTeacherId" :traineeName="reimbursedTeacherName"
            :role="roleTeacher" :isRemunerated="isRemunerated" :reimbursed="transportTeacher"
            :accommodation="accommodationTeacher" :message="'Voulez-vous modifier la demande de remboursement ?'"
            primaryButtonLabel='Modifier' secondaryButtonLabel='Fermer'
            @close="displayReimbursementTeacherDialog = false" :key="reimbursedTeacherKey" @confirm="fetchExpenses" />
    </div>
    <TrainingExpenseDetail v-if="openExpenseDetail" :expense="selectedExpense" :expensesStatus="expensesStatus"
        :role="selectedExpense.role" :idProfile="selectedExpense.profile_id" :idExpense="selectedExpense.expense_id"
        :training="training" :username="selectedExpense.username" :key="componentKey" @close="closeExpenseDetail"
        @confirm="fetchExpenses" />
    <div class="px-4 sm:px-6 lg:px-8 mt-12">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h1 class="text-base font-semibold leading-6 text-gray-900">Frais</h1>
                <p class="mt-2 text-sm text-gray-700">Liste des frais</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <div class="flex space-x-2">
                </div>
            </div>
        </div>
        <div>
            <!-- Stats -->
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                    <dt class="text-base font-normal text-gray-900 flex items-center"> <span
                            class="flex items-baseline text-2xl font-semibold text-indigo-600 mr-2">
                            {{ training.dinners }}
                        </span> Repas du soir</dt>
                </div>
                <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                    <dt class="text-base font-normal text-gray-900 flex items-center"> <span
                            class="flex items-baseline text-2xl font-semibold text-indigo-600 mr-2"> {{
                                training.number_of_round_trips }}
                        </span>Nombre d'allers-retours</dt>
                </div>
                <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                    <dt class="text-base font-normal text-gray-900 flex items-center"> <span
                            class="flex items-baseline text-2xl font-semibold text-indigo-600 mr-2"> {{
                                training.accommodation }}
                        </span>Hébergement</dt>
                </div>

            </dl>
        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Utilisateur</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Remboursement demandé</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Statut</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Numéro de régie</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Montant</th>
                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Détail</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="expense in expenses" :key="expense.id">
                                <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    <div class="flex items-center">
                                        <div class="h-11 w-11 flex-shrink-0">
                                            <img class="h-11 w-11 rounded-full" :src="expense.profile_picture_url"
                                                v-if="expense.profile_picture_url" alt="" />
                                            <UserCircleIcon class="h-11 w-11 text-gray-400" v-else />
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium text-gray-900">{{ expense.username }}</div>
                                            <div class="mt-1 text-gray-500"><span class="status"
                                                    :class="expense.role === 'Intervenant' ? 'status2' : 'status3'">{{
                                                        expense.role }}</span></div>
                                        </div>
                                    </div>
                                </td>
                                <td><button @click="openReimbursedDialog(expense)"
                                        v-if="expense.role === 'Stagiaire'"><span class="status status2"
                                            v-if="expense.reimbursed === 1">à
                                            rembourser</span><span class="status status1" v-else>non
                                            demandé</span><span class="status status1"
                                            :class="expense.needs_accommodation ? 'status3' : 'status1'">{{
                                                expense.needs_accommodation ?
                                                    'hébergement' : 'pas d\'hébergement' }}</span></button>
                                    <button @click="openReimbursedTeacherDialog(expense)"
                                        v-if="expense.role === 'Intervenant' || expense.role === 'Responsable p\u00e9dagogique'"><span
                                            class="status status1"
                                            :class="expense.reimbursed_transportation ? 'status3' : 'status1'">{{
                                                expense.reimbursed_transportation ?
                                                    'transport' : 'pas de transport' }}</span> <span class="status status1"
                                            :class="expense.needs_accommodation ? 'status3' : 'status1'">{{
                                                expense.needs_accommodation ?
                                                    'hébergement' : 'pas d\'hébergement' }}</span></button>

                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <span v-if="expense.expense_status_id" :class="'expenseStatus expenseStatus' +
                                        expense.expense_status_id">
                                        {{ statusName(expense.expense_status_id) }}
                                    </span>
                                    <span v-if="expense.role === 'Stagiaire'"> <span class=" status status3"
                                            v-if="!expense.expense_status_id && expense.reimbursed === 1">à faire</span>
                                        <span class="status status6"
                                            v-if="!expense.expense_status_id && expense.reimbursed === 0">non
                                            défrayé</span></span>
                                    <span v-else><span class=" status status3"
                                            v-if="!expense.expense_status_id && expense.reimbursed_transportation === 1">à
                                            faire</span> <span class="status status6"
                                            v-if="!expense.expense_status_id && expense.reimbursed_transportation === 0">non
                                            défrayé</span></span>
                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <div class="text-gray-900">{{ expense.payment_reference }}</div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <div class="mt-1 text-gray-500">{{ expense.amount }} € </div>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <a href="#" class="text-indigo-600 hover:text-indigo-900"
                                        v-if="canSeeDetailExpense(expense)"
                                        @click.prevent="seeExpenseDetail(expense)">Détail<span class="sr-only">, {{
                                            expense.userName }}</span></a>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import TrainingExpenseDetail from '@/components/trainings/TrainingExpenseDetail.vue';
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
import { BarsArrowDownIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import fetchExpensesServices from '@/services/ExpensesServices.js';
import { useRoute, useRouter } from 'vue-router';
import ReimbursmentDialog from '@/components/expenses/ReimbursmentDialog.vue';
import ReimbursmentTeacherDialog from '@/components/expenses/ReimbursmentTeacherDialog.vue';

const route = useRoute();
const router = useRouter();
const props = defineProps({
    training: [Array, Object],
});
const roleBadgeColors = {
    'Intervenant': 'bg-blue-100 text-blue-700',
    'Responsable pédagogique': 'bg-indigo-100 text-indigo-700',
    'Stagiaire': 'bg-pink-100 text-pink-700',
};
const expensesStatus = ref([]);
const reimbursedTrainee = ref(false);

function statusClass(status) {
    const statusId = expensesStatus.value.findIndex((item) => item.id === status);
    return `expenseStatus expenseStatus${statusId + 1}`;
}
function statusName(status) {
    const statusId = expensesStatus.value.findIndex((item) => item.id === status);
    if (expensesStatus.value[statusId]) {
        return expensesStatus.value[statusId].name;
    }
}
function canSeeDetailExpense(expense) {
    let seeDetail = false;
    if (expense.role === 'Intervenant' || expense.role === 'Responsable p\u00e9dagogique') {
        seeDetail = true;
    }
    if (expense.role === 'Stagiaire' && expense.reimbursed === 1) {
        seeDetail = true;
    }
    return seeDetail;
};
const expenses = ref([]);
const openExpenseDetail = ref(false);
const selectedExpense = ref(null);
let componentKey = ref(0);
const displayReimbursementDialog = ref(false);
const displayReimbursementTeacherDialog = ref(false);
const reimbursedKey = ref(0);
const reimbursedTraineeId = ref(null);
const reimbursedTraineeName = ref(null);
const accommodationTrainee = ref(null);
const transportTeacher = ref(null);
const accommodationTeacher = ref(null);
const reimbursedTeacherId = ref(null);
const reimbursedTeacherName = ref(null);
const reimbursedTeacherKey = ref(0);
const isRemunerated = ref(null);
const roleTeacher = ref(null);

function seeExpenseDetail(expense) {
    selectedExpense.value = expense;
    openExpenseDetail.value = true;
    componentKey.value += 1;
}

function closeExpenseDetail() {
    openExpenseDetail.value = false;
    componentKey.value += 1;
}

const openReimbursedDialog = (expense) => {
    displayReimbursementDialog.value = true;
    reimbursedTrainee.value = expense.reimbursed;
    reimbursedTraineeId.value = expense.profile_id;
    reimbursedTraineeName.value = expense.username;
    accommodationTrainee.value = expense.needs_accommodation;
    reimbursedKey.value += 1;
};
const openReimbursedTeacherDialog = (expense) => {
    displayReimbursementTeacherDialog.value = true;
    transportTeacher.value = expense.reimbursed_transportation;
    reimbursedTeacherId.value = expense.profile_id;
    reimbursedTeacherName.value = expense.username;
    accommodationTeacher.value = expense.needs_accommodation;
    isRemunerated.value = expense.is_remunerated;
    reimbursedTeacherKey.value += 1;
    roleTeacher.value = expense.role;
};

const fetchExpenses = () => {
    fetchExpensesServices.fetchTrainingExpensesByTraining(route.params.id).then((data) => {
        expenses.value = data;
    });
}
onMounted(() => {
    fetchExpensesServices.fetchTrainingExpenseStatuses().then((data) => {
        expensesStatus.value = data;
    });
    fetchExpenses();
});
</script>
