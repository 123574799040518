<template>
  <DashboardAdministrator v-if="currentUserRole === 'Administrateur'" />
  <DashboardManager v-if="currentUserRole === 'Gestionnaire'" />
  <DashboardLeadTeacher v-if="currentUserRole === 'Responsable pédagogique'" />
  <DashboardTrainee v-if="currentUserRole === 'Stagiaire'" />
  <DashboardTeacher v-if="currentUserRole === 'Intervenant'" />
</template>

<script setup>
import {
  ref, onMounted, inject,
} from 'vue';
// Administrator components
import DashboardAdministrator from '@/components/dashboards/DashboardAdministrator.vue';
import DashboardManager from '@/components/dashboards/DashboardManager.vue';
import DashboardLeadTeacher from '@/components/dashboards/DashboardLeadTeacher.vue';
import DashboardTrainee from '@/components/dashboards/DashboardTrainee.vue';
import DashboardTeacher from '@/components/dashboards/DashboardTeacher.vue';

const store = inject('store');

const currentUserName = ref('');
const currentUserRole = ref('');

onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    currentUserName.value = user.username;
    currentUserRole.value = user.role.name;
  }
});
</script>
