/* eslint-disable */
import client from '@/api/client';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const generateAttendanceSheet = async (id) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.get(`api/generate-attendance-sheet/${id}`, {
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `f${id}-emargement-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  } catch (error) {
    console.error('Failed to export attendance sheet:', error);
  }
}


const generateAttendances = async (id) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.get(`api/certificate/${id}`, {
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `f${id}-attestations-presences-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  } catch (error) {
    console.error('Failed to export attestations:', error);
  }
}
const generateAttendanceByTrainee = async (id, trainee) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.get(`api/certificate/${id}/${trainee}`, {
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `s${trainee}-attestations-presences-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  } catch (error) {
    console.error('Failed to export attestations:', error);
  }
}

const generateConvocations = async (id) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.get(`api/trainings/${id}/convocations`, {
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `f${id}-convocation-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  } catch (error) {
    console.error('Failed to export convocations:', error);
  }
}
const generateConvocationByTrainee = async (id, trainee) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.get(`api/trainings/${id}/convocation/${trainee}`, {
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `s${trainee}-convocation-${startDate}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  } catch (error) {
    console.error('Failed to export convocations:', error);
  }
}
const sendAttendanceMails = async (id) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.get(`api/send-certificates-email/${id}`);
  } catch (error) {
    console.error('Failed to export convocations:', error);
  }
}
const sendAttendanceMailByTrainee = async (id, trainee) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.get(`api/send-certificate-email/${id}/${trainee}`);
  } catch (error) {
    console.error('Failed to export convocations:', error);
  }
}
const sendConvocationMails = async (id) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.post(`api/send-convocations/${id}`);
  } catch (error) {
    console.error('Failed to export convocations:', error);
  }
}
const sendConvocationMailByTrainee = async (id, trainee) => {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  try {
    const response = await client.post(`api/send-convocation/${id}/${trainee}`);
  } catch (error) {
    console.error('Failed to export convocations:', error);
  }
}
// Export all trainings services
const exportServices = {
  generateAttendances,
  generateAttendanceSheet,
  generateConvocations,
  generateConvocationByTrainee,
  generateAttendanceByTrainee,
  sendAttendanceMails,
  sendAttendanceMailByTrainee,
  sendConvocationMails,
  sendConvocationMailByTrainee,
};

export default exportServices;