<template>
  <div class="flex sm:h-screen min-h-full flex-1">
    <div class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div class="">
          <img class="h-12 w-auto px-4" src="@/assets/img/logo/logo.svg" alt="IRT" />
          <h2 class="mt-8 text-lg sm:text-2xl font-bold leading-6 sm:leading-9 tracking-tight text-gray-900">
            Application de gestion des formations de l'Institut Régional du Travail
          </h2>
          <p class="mt-2 text-sm leading-6 text-gray-500">
            Un problème d'accès ou une question ?
            <a href="https://irt.univ-amu.fr/fr/contact-irt"
              class="font-semibold text-indigo-600 hover:text-indigo-500">Nous contacter</a>
          </p>
        </div>

        <AlertMessage v-if="store.error" type="error" title="Mince..."
          :content="'Un problème est survenu. Vérifiez votre identifiant et votre mot de passe.' + errorMessage"
          class="mt-2" />

        <div class="mt-10">
          <div>
            <form @submit.prevent="logInUser" class="space-y-6">
              <div>
                <label for="mail" class="block text-sm font-medium leading-6 text-gray-900">Identifiant</label>
                <div class="mt-2">
                  <input v-model="mail" id="mail" name="mail" type="text" autocomplete="username"
                    placeholder="exemple@acme.org" required
                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div>
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Mot de passe</label>
                <div class="mt-2 flex rounded-md shadow-sm">
                  <input :type="showPassword ? 'text' : 'password'" v-model="password" id="password" name="password"
                    autocomplete="current-password" required
                    class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Votre mot de passe" />
                  <button type="button" @click="togglePasswordVisibility"
                    class="relative -ml-px inline-flex items-center px-3 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <EyeIcon v-if="!showPassword" class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    <EyeSlashIcon v-else class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center">
                </div>

                <div class="text-sm leading-6">
                  <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500"
                    @click.prevent="forgotLogin">Mot de passe oublié ?</a>
                </div>
              </div>

              <div>
                <button type="submit"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Vous identifier
                </button>
              </div>
            </form>
          </div>

          <div class="mt-10">
            <div class="relative">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-200" />
              </div>
              <div class="relative flex justify-center text-sm font-medium leading-6">
                <span class="bg-white px-6 text-gray-900">Ou créez un compte</span>
              </div>
            </div>

            <div class="mt-6 grid">
              <button type="button" @click="displayUserCreation = true"
                class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Créer un compte
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <img class="absolute inset-0 h-full w-full object-cover"
        src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
        alt="" />
    </div>

  </div>
  <UserCreation :open="displayUserCreation" @close="displayUserCreation = false" />
</template>

<script setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/20/solid';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import UserCreation from '@/components/users/UserCreation.vue';

const mail = ref('');
const password = ref('');
const showPassword = ref(false);
const displayUserCreation = ref(false);
const errorMessage = ref('');

const store = inject('store');
const router = useRouter();

const logInUser = async () => {
  const credentials = {
    email: mail.value,
    password: password.value,
  };
  try {
    await store.logInUser(credentials);
    await store.fetchUserDetails();
    await store.fetchUserProfile();
    if (store.user) {
      router.push({ name: 'Tableau de bord' });
    } else {
      router.push({ name: 'Inscription' });
    }
  } catch (error) {
    errorMessage.value = error.message;
  }
};

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const forgotLogin = () => {
  router.push({
    name: 'ResetPassword',
  });
};
</script>
