<template>
    <CalendarDialog v-if="displayEventDetail" :event="displayedEvent" :key="componentKey" />
    <div class="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div
            class="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
            <div class="bg-white py-2">L<span class="sr-only sm:not-sr-only">undi</span></div>
            <div class="bg-white py-2">M<span class="sr-only sm:not-sr-only">ardi</span></div>
            <div class="bg-white py-2">M<span class="sr-only sm:not-sr-only">ercredi</span></div>
            <div class="bg-white py-2">J<span class="sr-only sm:not-sr-only">eudi</span></div>
            <div class="bg-white py-2">V<span class="sr-only sm:not-sr-only">endredi</span></div>
            <div class="bg-white py-2">S<span class="sr-only sm:not-sr-only">amedi</span></div>
            <div class="bg-white py-2">D<span class="sr-only sm:not-sr-only">imanche</span></div>
        </div>
        <div class="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div class="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                <div v-for="day in days" :key="day.date" :class="[
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                    'relative px-3 py-2',
                ]" @click="selectDay(day)" @keydown.enter="selectDay(day)" @keydown.space.prevent="selectDay(day)"
                    role="button" tabindex="0">
                    <time :datetime="day.date" :class="day.isToday
                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                        : undefined
                        ">{{ day.date.split("-").pop().replace(/^0/, "") }}</time>
                    <ol v-if="day.events.length > 0" class="mt-2">
                        <li v-for="event in day.events" :key="event.id" class="mb-2">
                            <a :href="event.href" @click.prevent="displayEvent(event)" class="group">
                                <p><span class="status status3 !py-0 !px-1 mb-1">{{ event.type?.name }}</span></p>
                                <p class="flex font-medium text-gray-900 group-hover:text-indigo-600 leading-4">
                                    <span> <b>{{ event.title }}</b></span>
                                </p>
                                <p>{{ event.pedagogical_supervisors[0]?.user.name }}</p>
                            </a>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                <button v-for="day in days" :key="day.date" type="button" :class="[
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                    (day.isSelected || day.isToday) && 'font-semibold',
                    day.isSelected && 'text-white',
                    !day.isSelected && day.isToday && 'text-indigo-600',
                    !day.isSelected &&
                    day.isCurrentMonth &&
                    !day.isToday &&
                    'text-gray-900',
                    !day.isSelected &&
                    !day.isCurrentMonth &&
                    !day.isToday &&
                    'text-gray-500',
                    'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10',
                ]" @click="selectDay(day)">
                    <time :datetime="day.date" :class="[
                        day.isSelected &&
                        'flex h-6 w-6 items-center justify-center rounded-full',
                        day.isSelected && day.isToday && 'bg-indigo-600',
                        day.isSelected && !day.isToday && 'bg-gray-900',
                        'ml-auto',
                    ]">{{ day.date.split("-").pop().replace(/^0/, "") }}</time>
                    <span class="sr-only">{{ day.events.length }} events</span>
                    <span v-if="day.events.length > 0" class="-mx-0.5 mt-auto flex flex-wrap-reverse">
                        <span v-for="event in day.events" :key="event.id"
                            class="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div v-if="selectedDay?.events.length > 0" class="px-4 py-10 sm:px-6 lg:hidden">
        <ol
            class="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            <li v-for="event in selectedDay.events" :key="event.id"
                class="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                <div class="flex-auto">
                    <p class="font-semibold text-gray-900">{{ event.name }}</p>
                    <time :datetime="event.datetime" class="mt-2 flex items-center text-gray-700">
                        <ClockIcon class="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {{
                            moment(event.start_date).format("Do MMM YY - HH:mm") }}
                    </time>
                    <div class="mt-3">{{ event.title }}</div>
                </div>
                <a :href="event.href" @click.prevent="displayEvent(event)"
                    class="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100">Voir<span
                        class="sr-only">, {{ event.name }}</span></a>
            </li>
        </ol>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { ClockIcon } from '@heroicons/vue/24/outline';
import CalendarDialog from '@/components/calendar/CalendarDialog.vue';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');
const displayEventDetail = ref(false);
const displayedEvent = ref(null);
const selectedDay = ref(null);

const componentKey = ref(0);

defineProps({
    days: Array,
    selectedDay: Object,
});

const selectDay = (day) => {
    selectedDay.value = day;
};

const displayEvent = (event) => {
    displayedEvent.value = event;
    displayEventDetail.value = true;
    componentKey.value += 1;
    console.log(event)
};
</script>
