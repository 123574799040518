<template>
    <div class=" sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
            <h3 class="text-base font-semibold leading-6 text-gray-900"> <span
                    v-if="profile?.profile_completion !== 100">Attention, votre profil est incomplet !</span><span
                    v-else>Bravo, votre profil est complet</span></h3>

            <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                <div class="max-w-xl w-full text-sm text-gray-500">
                    <p v-if="profile?.profile_completion !== 100">Veuillez compléter votre profil pour continuer. Il sera
                        nécessaire d'avoir un profil complet afin
                        de pouvoir vous inscrire à une formation.</p>
                    <p></p>
                    <div class="mt-6 w-92" aria-hidden="true">
                        <div class="overflow-hidden rounded-full bg-gray-200">
                            <div class="h-2 rounded-full" :class="{
                        'bg-red-600': progress < 20,
                        'bg-indigo-600': progress >= 20 && progress < 100,
                        'bg-green-600': progress === 100
                    }" :style="{ width: progress + '%' }" />
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                    <router-link to="/modifier-un-compte/" v-if="profile?.profile_completion !== 100"><button
                            type="button"
                            class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            :class="{
                        'bg-indigo-600': progress >= 37.5,
                        'bg-red-600': progress < 37.5
                    }">
                            Compléter votre profil
                        </button></router-link> <router-link to="/profil/" v-else><button type="button"
                            class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            :class="{
                        'bg-indigo-600': progress >= 37.5,
                        'bg-red-600': progress < 37.5
                    }">
                            Voir votre profil
                        </button></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';

defineProps({
    currentUserRole: [String],
});

const profile = ref();
const progress = ref(80);

onMounted(() => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    progress.value = profile.value.profile_completion;
});
</script>
