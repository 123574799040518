<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <TransitionRoot as="template" :show="open" style="z-index:100; position:relative;">
    <Dialog class="relative z-10" @close="$emit('close')">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-white">Envoyer un email à {{
    trainee.user?.name }}
                        </DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button"
                            class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            @click="$emit('close')">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div class="mt-1">
                        <p class="text-sm text-indigo-300"></p>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pb-5 pt-6">
                          <fieldset>
                            <legend class="text-sm font-medium leading-6 text-gray-900">Convocation</legend>
                            <div class="mt-2 space-y-4">
                              <div class="relative flex items-start">
                                <div class="absolute flex h-6 items-center">
                                  <input id="convocation" name="convocation" v-model="typeEmail" value="convocation"
                                    aria-describedby="privacy-public-description" type="radio"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" checked="" />
                                </div>
                                <div class="pl-7 text-sm leading-6">
                                  <label for="convocation" class="font-medium text-gray-900">Convocation</label>
                                  <p class="text-gray-500">Renvoyer l'email indiquant que la convocation est prête</p>
                                </div>
                              </div>
                              <legend class="text-sm font-medium leading-6 text-gray-900">Attestation</legend>
                              <div>
                                <div class="relative flex items-start">
                                  <div class="absolute flex h-6 items-center">
                                    <input id="certificate" name="certificate" aria-describedby="certificate"
                                      type="radio" value="certificate" v-model="typeEmail"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                  </div>
                                  <div class="pl-7 text-sm leading-6">
                                    <label for="certificate" class="font-medium text-gray-900">Attestation de
                                      présence</label>
                                    <p class="text-gray-500">Renvoyer l'email</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button"
                      class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      @click="$emit('close')">Fermer</button>
                    <button type="submit" class="ml-4 inline-flex btn-primary" @click="sendEmail">Envoyer</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';

const props = defineProps({
  trainee: [Object, Array],
  training: Object,
});


const open = ref(true);
const typeEmail = ref('convocation');
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const emit = defineEmits(['close']);

const sendConvocationByTrainee = async () => {
  try {
    const response = await client.post(`api/send-convocation/${props.training.id}/${props.trainee.id}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'email a bien été envoyé';
    notificationMessage.value += response.data.message;
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Une erreur s\'est produite';
    notificationMessage.value += error.response.data.message;
  }
}

const sendAttendanceByTrainee = async () => {
  try {
    const response = await client.get(`api/send-certificate-email/${props.training.id}/${props.trainee.id}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'email a bien été envoyé';
    notificationMessage.value += response.data.message;
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Une erreur s\'est produite';
    notificationMessage.value += error.response.data.message;
  }
}

const sendEmail = () => {
  if (typeEmail.value === 'convocation') {
    sendConvocationByTrainee();
  } else if (typeEmail.value === 'certificate') {
    sendAttendanceByTrainee();
  } else if (typeEmail.value === 'account_create') {
  } else if (typeEmail.value === 'account_resend') {
  }
}
</script>