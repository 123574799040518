<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog class="relative z-10" @close="$emit('close')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto  py-20 px-4 sm:py-20 sm:px-6 md:p-20">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95">
          <DialogPanel
            class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox @update:modelValue="onSelect">
              <div class="relative">
                <MagnifyingGlassIcon class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                  aria-hidden="true" />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Rechercher une formation..."
                  @change="query = $event.target.value; searchTrainings($event.target.value)" @blur="query = ''" />
              </div>

              <ComboboxOptions v-if="relevantResults.length > 0" static
                class=" transform-gpu scroll-py-3 overflow-y-auto p-3">
                <ComboboxOption v-for="item in relevantResults" :key="item.id" :value="item" as="template"
                  v-slot="{ active }">
                  <li :class="['flex cursor-default select-none rounded-xl p-3', active && 'bg-gray-100']">
                    <div :class="['flex h-10 w-10 flex-none items-center justify-center rounded-lg', item.color]">
                      <img :src="require(`@/assets/img/icons/iconTraining${item.category?.id}.svg`)" :alt="item.title"
                        class="h-6 w-6 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
                    </div>
                    <div class="ml-4 flex-auto">
                      <p :class="['text-sm font-medium', active ? 'text-gray-900' : 'text-gray-700']">
                        {{ item.title }}
                      </p>
                      <p :class="['text-sm', active ? 'text-gray-700' : 'text-gray-500']">
                        {{
    moment(item.start_date).format("Do MMM YY") }} / {{
    moment(item.end_date).format("Do MMM YY") }}
                      </p>
                      <p><span class="status status1">{{ item.category?.name }}</span></p>
                    </div>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>

              <div v-if="query !== '' && relevantResults.length === 0" class="px-6 py-14 text-center text-sm sm:px-14">
                <ExclamationCircleIcon type="outline" name="exclamation-circle" class="mx-auto h-6 w-6 text-gray-400" />
                <p class="mt-4 font-semibold text-gray-900">Pas de résultats</p>
                <p class="mt-2 text-gray-500">Veuillez réesayer.</p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { computed, ref, onMounted } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import {
  CalendarIcon,
  CodeBracketIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  LinkIcon,
  PencilSquareIcon,
  PhotoIcon,
  TableCellsIcon,
  VideoCameraIcon,
  ViewColumnsIcon,
  Bars4Icon,
} from '@heroicons/vue/24/outline'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import client from '@/api/client';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const router = useRouter();
const emit = defineEmits(['close']);
const items = [
  {
    id: 1,
    name: 'Text',
    description: 'Add freeform text with basic formatting options.',
    url: '#',
    color: 'bg-indigo-500',
    icon: PencilSquareIcon,
  },
  // More items...
]

const props = defineProps({
  context: String,
});
const open = ref(true);
const query = ref('');
const relevantResults = ref([]);
const profile = ref(null);

function onSelect(item) {
  router.push(`/formations/${item.id}`);
}

const searchTrainings = debounce(async (keyword) => {
  let url = '';
  if (profile?.value.role?.slug === 'stagiaire' || props.context === 'catalog') {
    url = `/api/catalog/trainings/search?search=${keyword}`;
  } else {
    url = `/api/trainings/search?search_term=${keyword}`;
  }
  try {
    const response = await client.get(`${url}`);
    relevantResults.value = response.data.data;
  } catch (error) {
    console.error('Failed to fetch structures details:', error);
  }
}, 600);

onMounted(() => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  open.value = true;
});
</script>