import { createRouter, createWebHistory } from 'vue-router';
import Cookies from 'js-cookie';
import store from '@/store/store';
import client from '@/api/client';
// import TeacherDetail from '@/views/teachers/TeacherDetail.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import ResetPasswordConfirmation from '@/views/auth/ResetPasswordConfirmation.vue';
import DashBoard from '../views/dashboards/DashBoard.vue';
import LoginScreen from '../views/auth/LoginScreen.vue';
import TrainingsList from '../views/trainings/TrainingsList.vue';
import TrainingDetail from '../views/trainings/TrainingDetail.vue';
import TraineesList from '../views/trainees/TraineesList.vue';
import TraineeOrTeacherDetail from '../views/trainees/TraineeOrTeacherDetail.vue';
import UserProfile from '../views/users/UserProfile.vue';
import MainCalendar from '../views/agenda/MainCalendar.vue';
import TeachersListHours from '../views/teachers/TeachersListHours.vue';
import TeachersList from '../views/teachers/TeachersList.vue';
import TeamCreationProfile from '../views/users/TeamCreationProfile.vue';
import ExportsDashboard from '../views/exports/ExportsDashboard.vue';
import AppUsers from '../views/admin/users/AppUsers.vue';
import AccountCreation from '../views/users/AccountCreation.vue';
import PageNotFound from '../views/errors/PageNotFound.vue';
import TrainingCatalog from '../views/trainings/TrainingCatalog.vue';
import TrainingCreation from '../views/trainings/TrainingCreation.vue';
import LegalInfos from '../views/legal/LegalInfos.vue';
import EmailVerification from '../views/auth/EmailVerification.vue';
import PaymentOrdersDetail from '../views/payments/PaymentOrdersDetail.vue';
import TrainingRegistration from '../views/trainings/TrainingRegistration.vue';
import TeamProfile from '../views/users/TeamProfile.vue';
import ReferentialEdition from '../views/admin/ReferentialEdition.vue';

const routes = [
  {
    path: '/',
    name: 'Tableau de bord',
    component: DashBoard,
    meta: {
      title: 'Tableau de bord - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant', 'stagiaire'],
      searchContext: 'dashboard',
    },
  },
  {
    path: '/authentification',
    name: 'Authentification',
    component: LoginScreen,
    meta: {
      title: 'Authentification - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: false,
    },
  },
  {
    path: '/formations',
    name: 'Liste des formations',
    component: TrainingsList,
    meta: {
      title: 'Formations - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/formations/:id',
    name: 'Détails de la formation',
    component: TrainingDetail,
    meta: {
      title: 'Détails de la formation - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant', 'stagiaire'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/formations/creer-une-formation',
    name: 'Créer une formation',
    component: TrainingCreation,
    meta: {
      title: 'Créer une formation - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/formations/editer-une-formation/:idTraining',
    name: 'Modifier une formation',
    component: TrainingCreation,
    meta: {
      title: 'Modifier une formation - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/stagiaires',
    name: 'Stagiaires',
    component: TraineesList,
    meta: {
      title: 'Stagiaires - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant'],
      searchContext: 'trainees',
    },
  },
  {
    path: '/stagiaires/:id',
    name: 'Détails du stagiaire',
    component: TraineeOrTeacherDetail,
    meta: {
      title: 'Détails du stagiaire - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant'],
      searchContext: 'trainees',
    },
  },
  {
    path: '/formateurs',
    name: 'Formateurs',
    component: TeachersList,
    meta: {
      title: 'Formateurs - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique'],
      searchContext: 'teachers',
    },
  },
  {
    path: '/formateurs/heures',
    name: 'Heures formateurs',
    component: TeachersListHours,
    meta: {
      title: 'Formateurs - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'responsable-pedagogique'],
      searchContext: 'teachers',
    },
  },
  {
    path: '/formateurs/:id',
    name: 'Détails de l\'enseignant',
    component: TraineeOrTeacherDetail,
    meta: {
      title: 'Détails de l\'enseignant - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique'],
      searchContext: 'teachers',
    },
  },
  {
    path: '/equipe/creer-un-compte/:roleId',
    name: 'Créer un membre de l\'équipe',
    component: TeamCreationProfile,
    meta: {
      title: 'Créer un membre de l\'équipe - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique'],
      searchContext: 'teachers',
    },
  },
  {
    path: '/equipe/modifier-un-compte/:profileId',
    name: 'Modifer un membre de l\'équipe',
    component: TeamCreationProfile,
    meta: {
      title: 'Modifier un membre de l\'équipe - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'intervenant', 'responsable-pedagogique'],
      searchContext: 'teachers',
    },
  },
  {
    path: '/equipe/:profileId',
    name: 'Membre de l\'équipe',
    component: TeamProfile,
    meta: {
      title: 'Détail d\'un membre de l\'équipe - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'intervenant', 'responsable-pedagogique'],
      searchContext: 'teachers',
    },
  },
  {
    path: '/profil',
    name: 'Profil',
    component: UserProfile,
    searchContext: 'trainings',
    meta: {
      title: 'Profil - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'stagiaire', 'responsable-pedagogique', 'intervenant'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: MainCalendar,
    meta: {
      title: 'Agenda - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/exports',
    name: 'Exports',
    component: ExportsDashboard,
    meta: {
      title: 'Exports - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/informations-legales',
    name: 'Informations légales',
    component: LegalInfos,
    meta: {
      title: 'Informations légales - Formations IRT Marseille',
      layout: 'PublicLayout',
      requiresAuth: false,
      searchContext: 'trainings',
    },
  },
  {
    path: '/equipe',
    name: 'Équipe',
    component: AppUsers,
    meta: {
      title: 'Équipe - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur', 'gestionnaire', 'responsable-pedagogique'],
      searchContext: 'team',
    },
  },
  {
    path: '/catalogue-de-formations',
    name: 'Catalogue de formations',
    component: TrainingCatalog,
    meta: {
      title: 'Catalogue de formations - Formations IRT Marseille',
      layout: 'PublicLayout',
      requiresAuth: true,
      allowedRoles: ['stagiaire', 'administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant'],
      searchContext: 'catalog',
    },
  },
  {
    path: '/creer-un-compte',
    name: 'Inscription',
    component: AccountCreation,
    meta: {
      title: 'Inscription - Formations IRT Marseille',
      layout: 'PublicLayout',
      requiresAuth: true,
      allowedRoles: [],
      searchContext: 'trainings',
    },
  },
  {
    path: '/modifier-un-compte',
    name: 'Modification du compte',
    component: AccountCreation,
    meta: {
      title: 'Compte - Formations IRT Marseille',
      layout: 'PublicLayout',
      requiresAuth: true,
      allowedRoles: ['stagiaire', 'administrateur', 'gestionnaire', 'responsable-pedagogique', 'intervenant'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/modifier-un-compte/:id',
    searchContext: 'trainings',
    name: 'Modification du profil d\'un utilisateur',
    component: AccountCreation,
    meta: {
      title: 'Compte - Formations IRT Marseille',
      layout: 'PublicLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/inscription/:id',
    name: 'Inscription à une formation',
    searchContext: 'trainings',
    component: TrainingRegistration,
    meta: {
      title: 'Inscription - Formations IRT Marseille',
      layout: 'PublicLayout',
      requiresAuth: true,
      allowedRoles: ['stagiaire'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/regie',
    name: 'Gestion des régies',
    component: PaymentOrdersDetail,
    searchContext: 'trainings',
    meta: {
      title: 'Regie - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/referentiel',
    name: 'Gestion du référentiel',
    component: ReferentialEdition,
    searchContext: 'trainings',
    meta: {
      title: 'Référentiel - Formations IRT Marseille',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['administrateur'],
      searchContext: 'trainings',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
    meta: {
      title: 'Oups... - Formations IRT Marseille',
      requiresAuth: false,
    },
  },
  {
    path: '/verification-email',
    name: 'EmailVerification',
    component: EmailVerification,
    meta: {
      title: 'Vérification de l\'adresse email - Formations IRT Marseille',
      requiresAuth: false,
    },
  },
  {
    path: '/reinitialisation',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'Réinitialisation - Formations IRT Marseille',
    },
  },
  {
    path: '/reinitialisation-mot-de-passe',
    name: 'ResetPasswordConfirmation',
    component: ResetPasswordConfirmation,
    meta: {
      title: 'Confirmation - Formations IRT Marseille',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || 'SIRT'; // Set the document title

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = Cookies.get('token');
    if (!token) {
      if (to.name !== 'Authentification') {
        next({ name: 'Authentification' });
      } else {
        next();
      }
    } else {
      try {
        const response = await client.get('/api/validate-token', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.valid) {
          if (!localStorage.getItem('userdetails')) {
            await store.fetchUserDetails();
          }
          if (!localStorage.getItem('user')) {
            await store.fetchUserProfile();
          }
          const user = JSON.parse(localStorage.getItem('user'));
          const userRole = user ? user.role.slug : null;

          const allowedRoles = to.meta.allowedRoles ? to.meta.allowedRoles.map((role) => role.toLowerCase()) : [];
          if (allowedRoles.length && !allowedRoles.includes(userRole)) {
            next({ name: 'Tableau de bord' });
          } else {
            next();
          }
        } else {
          throw new Error('Token invalid');
        }
      } catch (error) {
        Cookies.remove('token');
        localStorage.removeItem('user');
        localStorage.removeItem('userdetails');
        next({ name: 'Authentification' });
      }
    }
  } else {
    next(); // Allow access if no authentication is required
  }
});

router.afterEach((to) => {
  document.title = to.meta.title || 'SIRT'; // Fallback title if none is specified
});

export default router;
