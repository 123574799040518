<template>
  <DashboardLayout>
    <template v-slot:default="{ currentUserName }">
      <div class="md:flex items-center w-full">
        <div class="mt-4 text-center md:mt-0 md:pt-1 md:text-left pl-8">
          <h1 class="text-lg font-semibold leading-6 text-gray-900 mt-12">Bonjour,</h1>
          <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">{{ currentUserName }}</h1>
          <p class="text-xl font-bold text-gray-900 sm:text-2xl"></p>
          <p class="text-sm font-medium text-gray-600">{{ currentUserRole }}</p>
        </div>
        <div class="mx-auto text-center md:ml-auto md:mr-0 md:pr-10">
          <div class="mt-3 sm:ml-4 sm:mt-3 inline-flex md:flex md:flex-col lg:flex-row gap-2">
            <button type="button" @click.prevent="gotoTrainingCreation"
              class="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              <BuildingLibraryIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <router-link to="/formations/creer-une-formation"> <span>Créer une formation</span></router-link>
            </button>
            <button type="button" @click="gotoTeacherCreation(4)"
              class="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              <UserIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Créer un formateur</span>
            </button>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:primary>
      <PreRegistrations :currentUserRole="currentUserRole" v-if="currentUserRole === 'Gestionnaire'" />
      <div>
        <CurrentTrainingsRegistrations :currentUserRole="currentUserRole" v-if="currentUserRole === 'Gestionnaire'" />
      </div>
    </template>

    <template v-slot:secondary>
      <ClosedTrainingsFollowup :currentUserRole="currentUserRole" v-if="currentUserRole === 'Gestionnaire'" />
      <ClosedTrainingsFollowExpense :currentUserRole="currentUserRole" v-if="currentUserRole === 'Gestionnaire'" />
    </template>
  </DashboardLayout>
</template>

<script setup>
import {
  ref, onMounted, inject,
} from 'vue';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

import PreRegistrations from '@/components/dashboards/administrative/PreRegistrations.vue';
import CurrentTrainingsRegistrations from '@/components/dashboards/administrative/CurrentTrainingsRegistrations.vue';
import ClosedTrainingsFollowup from '@/components/dashboards/administrative/ClosedTrainingsFollowup.vue';
import ClosedTrainingsFollowExpense from '@/components/dashboards/administrative/ClosedTrainingsFollowExpense.vue';
import { UserIcon, BuildingLibraryIcon } from '@heroicons/vue/24/outline';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = inject('store');
const currentUserName = ref('');
const currentUserRole = ref('');

const gotoTeacherCreation = (idRole) => {
  router.push({ name: 'Créer un membre de l\'équipe', params: { roleId: idRole } });
};

onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    currentUserName.value = user.username;
    currentUserRole.value = user.role.name;
  }
});
</script>
