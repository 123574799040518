<template>
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
    <ConfirmationDialog :title="'Voulez-vous vraiment désactiver ce compte de ' + profile.user?.name + ' ?'"
        v-if="displayConfirmationDialog"
        message="L'utilisateur ne pourra plus accéder à ses données mais le compte ne sera toutefois pas supprimé."
        primary-action-label="Désactiver" secondary-action-label="Annuler" @close="displayConfirmationDialog = false"
        @confirm="disableAccount" />
    <div class="bg-gray-50 sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Modifier le compte</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
                <p>Ici, vous pouvez clôturer ou fermer ce compte.</p>
            </div>
            <div class="mt-5 flex gap-x-3">
                <button type="button" @click="displayConfirmationDialog = true"
                    class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <PauseCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Clôturer
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed } from 'vue';
import { PauseCircleIcon, TrashIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';

const props = defineProps({
    profile: Object,
});
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const displayConfirmationDialog = ref(false);
const emit = defineEmits(['confirm']);

const disableAccount = async () => {
    try {
        const response = await client.post(`api/profiles/${props.profile.id}/deactivate`)
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emit('confirm');
        displayConfirmationDialog.value = false;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};
</script>
