<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 2xl:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
          enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full" enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Fermer</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <NavBar />
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <SideBar />

    <div class="2xl:pl-72">
      <TopBar @open="sidebarOpen = true" />
      <slot :currentUserName :currentUserRole></slot>

      <main class="mt-12 pb-8">
        <div>
          <h1 class="sr-only">Page title</h1>
          <div class="grid grid-cols-1 items-start gap-4 xl:grid-cols-2 xl:gap-8 grid-dahsboard">
            <div class="grid grid-cols-1 gap-4">
              <section aria-labelledby="section-1-title">
                <h2 class="sr-only" id="section-1-title">Section title</h2>
                <div class="overflow-hidden rounded-lg bg-white shadow ml-8">
                  <div class="px-0 py-6">
                    <slot :current-user-name="currentUserName" :current-user-role="currentUserRole" name="primary" />
                  </div>
                </div>
              </section>
            </div>

            <div class="grid grid-cols-1 gap-4 lg:col-span-1">
              <section aria-labelledby="section-2-title">
                <h2 class="sr-only" id="section-2-title">Section title</h2>
                <div class="overflow-hidden rounded-lg bg-white shadow mr-8">
                  <div class="px-0 py-6">
                    <slot :current-user-name="currentUserName" :current-user-role="currentUserRole" name="secondary" />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>

    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import SideBar from '@/components/app/SideBar.vue';
import NavBar from '@/components/app/SidebarMobile.vue';
import TopBar from '@/components/app/TopBar.vue';
import { ExclamationTriangleIcon, CheckCircleIcon, UserIcon, BuildingLibraryIcon } from '@heroicons/vue/24/outline';

import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  XMarkIcon,
} from '@heroicons/vue/24/outline';

const store = inject('store');
const currentUserName = ref('');
const currentUserRole = ref('');

const sidebarOpen = ref(false);

onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    currentUserName.value = user.username;
  }
});
</script>
