<template>
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" style="z-index:2000;" />
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative !z-100" @close="$emit('close')" style="z-index:1000;">
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Évaluer la formation
                                                </DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="$emit('close')">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Fermer</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Nous sommes soucieux de la qualité et
                                                    de l'efficacité des formations proposées à l'Institut Régional du
                                                    Travail. Aussi, nous vous invitons à remplir ce questionnaire de
                                                    satisfaction.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <div>
                                                        <label for="documentation"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Documentation</label>
                                                        <select id="documentation" name="documentation"
                                                            v-model="formEvaluation.documentation"
                                                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="" disabled selected>Sélectionnez une note
                                                            </option>
                                                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="teachingMethods"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Méthodes
                                                            de formation</label>
                                                        <select id="teachingMethods" name="teachingMethods"
                                                            v-model="formEvaluation.training_methods"
                                                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="" disabled selected>Sélectionnez une note
                                                            </option>
                                                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="continuationDesire"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Envie
                                                            de continuer</label>
                                                        <select id="continuationDesire" name="continuationDesire"
                                                            v-model="formEvaluation.willingness_to_continue"
                                                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="" disabled selected>Sélectionnez une note
                                                            </option>
                                                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="groupLife"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Vie
                                                            de groupe</label>
                                                        <select id="groupLife" name="groupLife"
                                                            v-model="formEvaluation.group_life"
                                                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="" disabled selected>Sélectionnez une note
                                                            </option>
                                                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="materialConditions"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Conditions
                                                            matérielles</label>
                                                        <select id="materialConditions" name="materialConditions"
                                                            v-model="formEvaluation.material_conditions"
                                                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="" disabled selected>Sélectionnez une note
                                                            </option>
                                                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="knowledgeContribution"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Apport
                                                            de connaissances</label>
                                                        <select id="knowledgeContribution" name="knowledgeContribution"
                                                            v-model="formEvaluation.knowledge_contribution"
                                                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="" disabled selected>Sélectionnez une note
                                                            </option>
                                                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="overallSatisfaction"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Satisfaction
                                                            globale</label>
                                                        <select id="overallSatisfaction" name="overallSatisfaction"
                                                            v-model="formEvaluation.overall_satisfaction"
                                                            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <option value="" disabled selected>Sélectionnez une note
                                                            </option>
                                                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                        <button type="button"
                                            class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            @click="$emit('close')">Fermer</button>
                                        <button type="button" @click.prevent="evaluateTraining"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enregistrer</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import client from '@/api/client';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const open = ref(true)
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const emit = defineEmits(['close'])
const formEvaluation = ref({
    documentation: '',
    training_methods: '',
    willingness_to_continue: '',
    group_life: '',
    material_conditions: '',
    knowledge_contribution: '',
    overall_satisfaction: '',
});
const profile = ref([]);

const props = defineProps({
    training: {
        type: [Array, Object],
        required: true,
    },
});

const evaluateTraining = async () => {
    console.log('Evaluate training')
    try {
        const response = await client.post(`/api/evaluations/${props.training.id}/${profile.value.id}`, {
            documentation: formEvaluation.value.documentation,
            training_methods: formEvaluation.value.training_methods,
            willingness_to_continue: formEvaluation.value.willingness_to_continue,
            group_life: formEvaluation.value.group_life,
            material_conditions: formEvaluation.value.material_conditions,
            knowledge_contribution: formEvaluation.value.knowledge_contribution,
            overall_satisfaction: formEvaluation.value.overall_satisfaction,
        });
        formEvaluation.value = response.data.evaluation;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'évaluation a été créée avec succès';
        notificationMessage.value = '';
        emit('close');
    } catch (error) {
        notificationType.value = 'error';
        setTimeout(() => { showNotification.value = false; }, 3000);
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
}
onMounted(async () => {
    profile.value = JSON.parse(localStorage.getItem('user'));
});
</script>
