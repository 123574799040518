<template>
  <div class="grid md:grid-cols-2 gap-5">
    <div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="text-sm font-medium text-gray-500">Nouveaux stagiaires en {{ dataInfos.year }}</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ dataInfos.new_trainees_count }}</dd>
      </div>
    </div>

  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';
import { BarChart, PolarAreaChart, LineChart, DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

import moment from 'moment';
import 'moment/locale/fr';
import client from '@/api/client';

moment.locale('fr');

Chart.register(...registerables);
Chart.defaults.font.family = 'Sora, sans-serif';

const dataLabels = ref([]);
const dataSessions = ref([]);
const dataInfos = ref([]);
const sessionsData = computed(() => ({
  labels: dataLabels.value,
  datasets: [
    {
      label: 'Nombre de formations selon les années',
      data: dataSessions.value,
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      borderColor: 'rgb(255, 255, 255)',
      borderWidth: 1,
    },
  ],
}));


const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'center',
      labels: {
        boxWidth: 20,
        padding: 20,
        usePointStyle: true,
        font: {
          size: 14,
          weight: 'bold',
          family: 'Sora, sans-serif',
        },
      },
    },
  },
});

onMounted(async () => {
  try {
    const response = await client.get(`api/trainees-per-year`);
    dataInfos.value = response.data;
  } catch (error) {
    console.error(error);
  }
});
</script>
