<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="$emit('close')">
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Nouveau justificatif
                                                </DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="$emit('close')">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Fermer</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Vous pouvez ajouter un justificatif
                                                </p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <button type="button" class="relative block w-full cursor-pointer">
                                                        <label for="file-upload"
                                                            class="cursor-pointer relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                            <svg class="mb-3 mx-auto h-12 w-12 text-gray-400 cursor-pointer"
                                                                stroke="currentColor" fill="#6b7280" width="1200pt"
                                                                height="1200pt" version="1.1" viewBox="0 0 1200 1200"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="m849.91 1089.1c7.3633 0 13.332-5.9648 13.332-13.332v-66.316h83.543c7.3633 0 13.332-5.9648 13.332-13.332v-871.97c0-7.3711-5.9688-13.332-13.332-13.332h-613.61c-7.3633 0-13.332 5.9648-13.332 13.332v76.184h-66.621c-7.3633 0-13.332 5.9648-13.332 13.332v862.1c0 7.3711 5.9688 13.332 13.332 13.332zm-503.41-951.61h586.95v845.3h-70.207v-769.12c0-7.3711-5.9688-13.332-13.332-13.332h-503.41zm-79.953 89.516h570.03v835.43h-570.03z" />
                                                                <path
                                                                    d="m708.29 776.61h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3672-5.9727-13.332-13.336-13.332z" />
                                                                <path
                                                                    d="m708.29 858.45h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332s-5.9727-13.332-13.336-13.332z" />
                                                                <path
                                                                    d="m708.29 940.3h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9727-13.332-13.336-13.332z" />
                                                                <path
                                                                    d="m422.41 563.63c0 7.3711 5.9688 13.332 13.332 13.332h22.293c20.18 52.465 70.969 89.855 130.45 89.855 20.078 0 39.504-4.207 57.746-12.5 6.6992-3.0469 9.6602-10.949 6.6133-17.656-3.0469-6.707-10.965-9.6875-17.656-6.6133-14.746 6.707-30.461 10.105-46.707 10.105-44.445 0-82.879-25.82-101.38-63.191h104.48c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9688-13.332-13.332-13.332h-113.83c-1.5859-7.5352-2.4453-15.332-2.4453-23.332 0-7.9961 0.85938-15.789 2.4414-23.32h113.83c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9688-13.332-13.332-13.332h-104.49c18.504-37.371 56.938-63.191 101.39-63.191 16.238 0 31.953 3.3984 46.719 10.09 6.6719 3.0742 14.602 0.078126 17.648-6.6289 3.0391-6.707 0.070312-14.609-6.6328-17.645-18.25-8.293-37.676-12.488-57.734-12.488-59.48 0-110.27 37.395-130.45 89.855h-22.293c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h15.004c-1.2852 7.6016-2.1055 15.359-2.1055 23.32 0 7.9648 0.82422 15.727 2.1094 23.332h-15.004c-7.3477 0.007812-13.32 5.9688-13.32 13.34z" />
                                                            </svg>
                                                            <span>Ajouter
                                                                un fichier</span>
                                                            <input id="file-upload" name="file-upload" type="file"
                                                                @change="selectFile" class="sr-only" />
                                                        </label>
                                                    </button>
                                                    <div v-if="selectedFile?.name">
                                                        <label for="project-name"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Fichier
                                                            déposé
                                                        </label>
                                                        <div class="bg-indigo-100 px-4 py-2 rounded mt-2">
                                                            {{ selectedFile?.name }}</div>
                                                        <div class="mt-2">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label for="description"
                                                            class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                                                        <div class="mt-2">
                                                            <textarea id="description" name="description" rows="4"
                                                                v-model="formReceipt.description"
                                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                        <button type="button"
                                            class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            @click="$emit('close')">Annuler</button>
                                        <button type="submit" @click.prevent="postReceipt"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enregistrer</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';

const emit = defineEmits(['close', 'savedReceipt']);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const props = defineProps({
    expense: [Number, String],
});

const open = ref(true);
const formReceipt = ref({
    name: '',
    description: '',
    type: '',
});
const selectedFile = ref(null);

const selectFile = (event) => {
    selectedFile.value = event.target.files[0];
}

const postReceipt = async () => {
    const formDataReceipt = new FormData();
    if (formReceipt.value.description === '' || selectedFile.value === null) {
        formReceipt.value.description = selectedFile.value.name;
    }
    formDataReceipt.append('training_expense_id', props.expense);
    formDataReceipt.append('receipts[0][file]', selectedFile.value);
    formDataReceipt.append('receipts[0][description]', formReceipt.value.description);
    try {
        const response = await client.post('/api/receipts', formDataReceipt, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response);
        emit('savedReceipt', 'success', response.data.message);
    } catch (error) {
        emit('savedReceipt', 'error', response.data.message);
    }
}

onMounted(() => {
});
</script>
