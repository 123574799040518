<template>
    <BigLoader v-if="isLoading" :title="loaderTitle" :text="'Préparation en cours de votre document'" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
    <div>
        <TrainingEvaluation v-if="displayTrainingEvaluation" @close="closeTrainingEvaluation" :training="training"
            :key="componentKey" />
    </div>
    <ExpenseUpload v-if="displayExpenseUpload" @close="displayExpenseUpload = false" style="z-index: 2000;"
        :expense="expenseId" @savedReceipt="receiptResponse" key="componentKey" />
    <div class="-mt-6 lg:col-start-3 lg:row-end-1">
        <h2 class="sr-only">Résumé</h2>
        <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
            <dl class="flex flex-wrap">
                <div class="flex-auto pl-6 pt-6">
                    <dt class="text-sm font-semibold leading-6 text-gray-400">EN COURS</dt>
                    <dd class="mt-1 text-base font-semibold leading-6 text-gray-900"><router-link
                            :to="'formations/' + training?.id">{{ trainingTitle }}</router-link></dd>
                </div>
                <div class="flex-none self-end px-6 pt-4">
                    <dt class="sr-only">Statut</dt>
                    <dd v-if="!training.pivot.reimbursed"
                        class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                        <svg class="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx="3" cy="3" r="3" />
                        </svg>
                        Pas de défraiement
                    </dd>
                    <dd v-if="training.pivot.reimbursed && noExpense"
                        class="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                        <svg class="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx="3" cy="3" r="3" />
                        </svg>
                        Aucun justificatif fourni
                    </dd>
                    <dd v-if="training.pivot.reimbursed && expenseId"
                        class="inline-flex items-center gap-x-1.5 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                        <svg class="h-1.5 w-1.5 fill-purple-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx="3" cy="3" r="3" />
                        </svg>
                        Défraiement demandé
                    </dd>
                </div>
                <div class="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dd class="text-sm leading-6 text-gray-500">
                        <time :datetime="startDate">{{
        moment(training.start_date).format("Do MMM YY - HH:mm") }}</time>
                    </dd>
                    <span class="text-sm leading-6 text-gray-500"> - </span>
                    <dd class="text-sm leading-6 text-gray-500">
                        <time :datetime="endDate">{{
        moment(training.end_date).format("Do MMM YY - HH:mm") }}</time>
                    </dd>
                </div>
                <div class="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt class="flex-none">
                        <span class="sr-only">Repas inclus</span>
                        <CakeIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd class="text-sm font-medium leading-6 text-gray-900"> Repas {{ training.dinners }} {{
        training.dinners > 0 ?
            'pris en charge' : ' non pris en charge' }}</dd>
                </div>
                <div class="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt class="flex-none">
                        <span class="sr-only">Hébergement
                        </span>
                        <MoonIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd class="text-sm font-medium leading-6 text-gray-900">Hébergement {{ training.accommodation ?
        'pris en charge' : 'non pris en charge' }}</dd>
                </div>
                <div class="mt-10 flex w-full flex-none gap-x-4 px-6">
                    <p class="font-semibold text-sm">Mes justificatifs</p>
                </div>
                <div class="mt-4 flex w-full flex-none gap-x-4 px-6" v-if="expense">
                    <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200 w-full"
                        v-if="expense.receipts">
                        <li class="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6"
                            v-for="receipt in expense.receipts" :key="receipt.id">
                            <div class="flex w-0 flex-1 items-center">
                                <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <div class="ml-4 flex min-w-0 flex-1 gap-2">
                                    <span class="truncate font-medium">{{ receipt.description }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </dl>
            <div class="mt-6 border-t border-gray-900/5 px-6 py-6">
                <a href="#" @click.prevent="generateConvocation(profile?.id);"
                    class="text-sm font-semibold leading-6 text-gray-900 mb-4 block">Télécharger la
                    convocation
                    <span aria-hidden="true">&rarr;</span></a>
                <a href="#" @click.prevent="generateAttendance(profile?.id)"
                    v-if="training.attendance_sheet_done && training.status?.id === 5"
                    class="text-sm font-semibold leading-6 text-gray-900 mb-4 block">Télécharger l'attestation
                    de présence
                    <span aria-hidden="true">&rarr;</span></a>
                <a href="#" @click.prevent="addExpense" class="text-sm font-semibold leading-6 text-gray-900"
                    v-if="expense?.status !== 'Mise en paiement'">Ajouter un
                    justificatif
                    <span aria-hidden="true">&rarr;</span></a>
            </div>
        </div>
    </div>
    <div class="bg-gray-50 shadow sm:rounded-lg mt-4">
        <div class="px-4 py-5 sm:p-6">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Évaluez la formation
            </h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500 mb-4">
                <p>Partagez votre avis sur la formation et contribuez à l'amélioration continue.</p>
            </div>
            <div class="mt-5" v-if="needToEvaluate">
                <button type="button" @click.prevent="evaluateTraining"
                    class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    Compléter l'évaluation
                </button>
            </div>
            <div v-else-if="!needToEvaluate && !evaluateLater" class="mt-">
                <span
                    class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                    <svg class="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx="3" cy="3" r="3" />
                    </svg>
                    évaluation effectuée
                </span>
            </div>
            <div v-else-if="evaluateLater">
                <span
                    class="inline-flex items-center gap-x-1.5 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                    <svg class="h-1.5 w-1.5 fill-purple-500" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx="3" cy="3" r="3" />
                    </svg>
                    évaluation à effectuer à la fin
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { defineProps } from 'vue';
import { CalendarDaysIcon, UserCircleIcon, MoonIcon, } from '@heroicons/vue/20/solid';
import { CakeIcon } from '@heroicons/vue/24/outline';
import TrainingEvaluation from '@/components/dashboards/trainee/TrainingEvaluation.vue';
import ExpenseUpload from '@/components/expenses/ExpenseUpload.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import BigLoader from '@/components/app/BigLoader.vue';
import client from '@/api/client';
import { PaperClipIcon } from '@heroicons/vue/20/solid';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const props = defineProps({
    trainingTitle: {
        type: String,
        default: 'Formation Syndicale Avancée'
    },
    status: {
        type: String,
        default: 'justificatifs à fournir' // Possible values: 'ok', 'justificatifs à fournir', 'justificatifs manquants'
    },
    referent: {
        type: String,
        default: 'Jean Dupont'
    },
    startDate: {
        type: String,
        default: '2024-05-13'
    },
    formattedStartDate: {
        type: String,
        default: '13 mai 2024'
    },
    endDate: {
        type: String,
        default: '2024-05-17'
    },
    formattedEndDate: {
        type: String,
        default: '17 mai 2024'
    },
    training: [Array, Object],
});

const componentKey = ref(0);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayExpenseUpload = ref(false);
const profile = ref();
const isLoading = ref(false);
const loaderTitle = ref('');
const noExpense = ref(false);
const expenseId = ref(0);
const expense = ref();
const needToEvaluate = ref(false);
const today = new Date();
const evaluateLater = ref(false);
const evaluateTraining = () => {
    displayTrainingEvaluation.value = true;
};

const displayTrainingEvaluation = ref(false);

const closeTrainingEvaluation = () => {
    displayTrainingEvaluation.value = false;
    componentKey.value += 1;
};

const trainingEvaluated = () => {
    // Todo: call API to save evaluation
    console.log('Training evaluated');
    componentKey.value += 1;
};

const addExpense = () => {

    if (noExpense.value) {
        postExpense();
    } else {
        displayExpenseUpload.value = true;
    }
};
const postExpense = async () => {
    try {
        const response = await client.post(`api/training-expenses`, {
            training_id: props.training.id,
            profile_id: profile.value.id,
            training_expense_status_id: 1,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été créé';
        notificationMessage.value = '';
        expenseId.value = response.data.id;
        displayExpenseUpload.value = true;
        componentKey.value += 1;
    } catch (error) {
        notificationType.value = 'error';
        setTimeout(() => { showNotification.value = false; }, 3000);
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
}
const receiptResponse = (type, response) => {
    displayExpenseUpload.value = false;
    if (type === 'success') {
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le justificatif a bien été ajouté';
        notificationMessage.value = '';
    } else {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        notificationMessage.value = response;
    }
}

const generateAttendance = async (traineeId) => {
    const startDate = moment(new Date()).format('YYYY-MM-DD');
    isLoading.value = true;
    loaderTitle.value = 'Génération de l\'attestation de présence en cours';
    try {
        const response = await client.get(`api/certificate/${props.training.id}/${traineeId}`, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `f${traineeId}-attestation-${startDate}.pdf`);
            document.body.appendChild(fileLink);
            fileLink.click();
            isLoading.value = false;
        }
    } catch (error) {
        isLoading.value = false;

        if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
                const errorData = JSON.parse(reader.result);
                notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
            };
            reader.readAsText(error.response.data);
        } else {
            notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
        }

        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Erreur';
    }
}

const generateConvocation = async (traineeId) => {
    isLoading.value = true;
    loaderTitle.value = 'Génération de la convocation en cours';
    const startDate = moment(new Date()).format('YYYY-MM-DD');
    try {
        const response = await client.get(`api/trainings/${props.training.id}/convocation/${traineeId}`, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `f${traineeId}-convocation-${startDate}.pdf`);
            document.body.appendChild(fileLink);
            fileLink.click();
            isLoading.value = false;
        }
    } catch (error) {
        isLoading.value = false;

        if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
                const errorData = JSON.parse(reader.result);
                notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
            };
            reader.readAsText(error.response.data);
        } else {
            notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
        }

        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Erreur';
    }
}

const fetchExpense = async () => {
    try {
        const response = await client.get(`/api/training/${props.training.id}/profile/${profile.value.id}/expense`);
        noExpense.value = false;
        expenseId.value = response.data.id;
        expense.value = response.data;
    } catch (error) {
        noExpense.value = true;
    }
}

onMounted(async () => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    fetchExpense();
    try {
        const response = await client.get(`api/evaluations/evaluation/${props.training.id}/${profile.value.id}`);
        console.log(response.data);
        if (response.data.id) {
            needToEvaluate.value = false
        } else {
            if (new Date(props.training.end_date) < today) {
                needToEvaluate.value = true;
            }
        }
    } catch (error) {
        console.log(error);
        if (new Date(props.training.end_date) < today) { needToEvaluate.value = true; } else {
            evaluateLater.value = true;
        }
    }
});
</script>
