<template>
    <div class="px-8">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Cette année</h3>
        <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div v-for="item in stats" :key="item.name"
                class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt class="text-sm font-medium text-gray-500">{{ item.name }}</dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ item.stat }}</dd>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, reactive } from 'vue';
import client from '@/api/client';

const stats = reactive([
    { name: 'Formations dans l\'année', stat: '0' },
    { name: 'Formations réalisées', stat: '0' },
    { name: 'Montant dépensé en régie', stat: '0' },
]);

onMounted(async () => {
    try {
        const response = await client.get('/api/admin-dashboard-stats');
        stats[0].stat = response.data.total_trainings;
        stats[0].name = stats[0].name + ' ' + response.data.year;
        stats[1].stat = response.data.completed_trainings;
        stats[2].stat = Math.floor(response.data.total_spent_regie);
        stats[2].stat += ' €';
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
});
</script>
