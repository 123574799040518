<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <BigLoader v-if="isLoading" :title="'Génération de  la régie en cours'"
    :text="'Préparation en cours de votre document'" />
  <TrainingExpenseAdminDetail v-if="openExpenseDetail" :expenseId="selectedExpense" :training="relevantTraining"
    :key="componentKey" @close="closeExpenseDetail" @confirm="fetchExpenses" context="regie" />
  <AppLayout>
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">
            Régie en cours : {{ order_number }}
          </h1>
          <p class="mt-2 text-sm text-gray-700">
            Tous les paiements de la régie de ce mois
          </p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex">
          <button class="btn-primary mr-2" @click="exportPaymentOrder">Exporter la régie</button>
          <button type="button" @click="createPaymentOrder"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Créer la nouvelle régie
          </button>
        </div>
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full">
              <thead class="bg-white">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Nom
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Montant
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-20">Puissance
                    fiscale
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Km
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Frais km
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Peage
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-20">Transport
                    en commun
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nbr repas
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-20">Montant
                    repas</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Hébergement
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Autre
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-20">Mode
                    paiement
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ref paiement
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white" v-if="relevantPayments">
                <template v-for="training in relevantPayments" :key="training.title">
                  <tr class="border-t border-gray-200">
                    <th colspan="11" scope="colgroup"
                      class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm  text-gray-900 sm:pl-3">
                      <div class="font-semibold">{{
                        training.training_title }}</div>
                      <div class="flex flex-wrap font-normal text-indigo-700">{{ training.training_dates }}
                      </div>
                    </th>
                  </tr>
                  <tr v-for="(participant, participantIdx) in training.participants" :key="participantIdx"
                    :class="[participantIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t']">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      <div class="font-medium text-gray-900">{{ participant?.participant_name }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span class="status status3">{{ participant?.amount }} €</span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.vehicle_category }} €
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.calculated_distance }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.transport_costs }} €
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.toll_parking_costs }} €
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.public_transport_costs }} €
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ training.dinners }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.evening_meals }} €
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.accommodation_costs }} €
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.other_costs }} €
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {{ participant?.payment_reference }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span class="status status3" v-if="participant.payment_method === 'virement'">{{
                        participant.payment_method }}</span><span class="status status4" v-else>{{
                          participant.payment_method }}</span>
                    </td>
                  </tr>
                  <tr class="border-t border-gray-200">
                    <td class="px-3">Total</td>
                    <td class="whitespace-nowrap px-3 py-4 font-semibold text-sm text-gray-500"><span
                        class="status status2 !text-normal">{{ training.total_expenses }}</span></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import moment from 'moment';
import 'moment/locale/fr';
import { useRoute } from 'vue-router';
import TrainingExpenseAdminDetail from '@/components/trainings/TrainingExpenseAdminDetail.vue';
import fetchExpensesServices from '@/services/ExpensesServices.js';
import Pagination from '@/components/lists/PaginationTables.vue';
import BigLoader from '@/components/app/BigLoader.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

moment.locale('fr');

const route = useRoute();
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const relevantPayments = ref([]);
const openExpenseDetail = ref(false);
const selectedExpense = ref(null);
let componentKey = ref(0);
const expense = ref(null);
const relevantTraining = ref(null);
// Pagination data
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 10;
const order_number = ref(null);
const last_payment_order = ref(null);
const isLoading = ref(false);

function closeExpenseDetail() {
  openExpenseDetail.value = false;
  componentKey.value += 1;
}

const fetchPaymentOrder = async (page, id) => {
  try {
    const response = await client.get(`api/financial-report/${id}`, {
      params: { page: page, per_page: pageSize },
    });
    relevantPayments.value = response.data.data;
    currentPage.value = response.data.current_page;
    totalPages.value = response.data.last_page;
  } catch (error) {
    console.error('Failed to fetch training details:', error);
  }
};
const changePage = (page) => {
  fetchPaymentOrder(page, id);
};
const seeExpenseDetail = (expense) => {
  selectedExpense.value = expense;
  openExpenseDetail.value = true;
  componentKey.value += 1;
}
const fetchExpenses = (id) => {
  fetchExpensesServices.fetchTrainingExpense(id).then((data) => {
    expense.value = data;
  });
  fetchTrainingsServices.fetchTraining(route.params.id).then((data) => {
    relevantTraining.value = data;
    componentKey.value += 1;
  });
};

const exportPaymentOrder = async () => {
  isLoading.value = true;
  const today = moment().format('YYYY-MM-DD');
  try {
    client.get(`api/financial-report-export/excel/${last_payment_order.value}`, {
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `regie-${order_number.value}-${today}.xls`);
      document.body.appendChild(fileLink);
      fileLink.click();
      isLoading.value = false;
    });
  } catch (error) {
    console.error('Failed to export régie:', error);
  }
}

const createPaymentOrder = async () => {
  try {
    const response = await client.post(`/api/payment-orders`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, a régie a bien été créée';
  } catch (error) {
    notificationType.value = 'error';
    setTimeout(() => { showNotification.value = false; }, 3000);
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement';
    if (error.response) {
      notificationMessage.value = error.response.data.error;
    }
  }
}
onMounted(async () => {
  try {
    const response = await client.get('api/last-payment-order', {
    });
    order_number.value = response.data.order_number;
    last_payment_order.value = response.data.id;

    fetchPaymentOrder(1, response.data.id);
  } catch (error) {
    console.error('Failed to fetch training details:', error);
  }
});
</script>