<template>
  <AppLayout>
    <template v-slot:default="{}">
      <div>
        <div class="md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex w-full">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Mes
              informations</h2>
          </div>
          <div class="max-w-7xl mt-10"><router-link to="/modifier-un-compte/"
              v-if="currentProfile?.role?.slug === 'stagiaire'"><button class="btn btn-secondary ml-auto mb-5">Modifier
                mon profil</button></router-link><router-link :to="'/equipe/modifier-un-compte/' + currentProfile?.id"
              v-else><button class="btn btn-secondary ml-auto mb-5">Modifier</button></router-link></div>
        </div>
      </div>
      <div v-if="currentProfile?.role?.name === 'stagiaire'">
        <AlertMessage type="error" :title="'Attention, votre profil n\'est pas complet'"
          v-if="currentProfile.profile_completion !== 100" />
      </div>
      <div class="mx-auto pt-10 lg:flex lg:gap-x-16 lg:px-8" v-if="currentProfile">
        <aside
          class="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav class="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" class="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              <li v-for="item in menuProfileItems" :key="item.name">
                <a :href="item.href" :alt="item.name"
                  :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold']">
                  <component :is="item.icon"
                    :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                    aria-hidden="true" />
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </nav>
        </aside>

        <main class="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div id="general">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[0].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[0].description }}</p>
              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Numéro de profil</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900"> {{ currentProfile?.profile_number }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Genre</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ currentProfile?.genre?.name || 'Non déclaré' }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Nom</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ currentProfile?.username }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div>
                      <div class="text-gray-900">{{ currentUser?.email }}</div>
                      <div class="text-gray-900" v-for="email in JSON.parse(currentProfile?.emails)" :key="email">
                        {{ email }}
                      </div>
                    </div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Téléphone</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ currentProfile?.phone }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Rôle</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.role?.name }}</div>

                  </dd>
                </div>
                <div class="pt-6 sm:flex"
                  v-if="currentProfile.role?.slug === 'responsable-pedagogique' || currentProfile.role?.slug === 'intervenant'">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Fonction</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ currentProfile?.title }}</div>

                  </dd>
                </div>
                <div class="pt-6 sm:flex"
                  v-if="currentProfile.role?.slug === 'responsable-pedagogique' || currentProfile.role?.slug === 'intervenant'">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Statut</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ currentProfile?.employment_status_name }}</div>

                  </dd>
                </div>
              </dl>
            </div>
            <div id="address">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[1].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[1].description }}</p>

              <ul role="list" class="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <li class="flex justify-between gap-x-6 py-6">
                  <div class="font-medium text-gray-900">{{ currentProfile.full_address
                    }}</div>
                </li>
              </ul>
            </div>
            <div id="pro" v-if="currentProfile.role?.slug === 'stagiaire'">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[2].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[2].description }}</p>

              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Situation professionnelle</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.professional_situation?.name }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Catégorie professionnelle</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.professional_category?.name }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Secteur professionnel</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.activity_sector?.name }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Diplôme</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.degree?.name }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Nom de
                    l'entreprise</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.company_name }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Taille de l'entreprise</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.company_size_id }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Code de postal de l'entreprise</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.company_zip_code }}</div>
                  </dd>
                </div>
              </dl>
            </div>

            <div id="unions" v-if="currentProfile.role?.slug === 'stagiaire'">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[3].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[3].description }}</p>

              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Est syndiqué</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile?.is_union_member ? 'oui' : 'non' }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Syndicat</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile.current_union?.name }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">A un ou des mandats</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile?.has_mandate ? 'oui' : 'non' }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <div v-for="(mandate) in profile.mandates" :key="mandate.id">
                    <div v-if="mandate.pivot?.is_valid === 1" class="sm:flex">
                      <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Mandat</dt>
                      <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div class="text-gray-900">{{ mandate.name }} <span v-if="mandate.pivot?.is_valid === 1"
                            class="ml-2 status status5">actif</span>
                        </div>
                      </dd>
                    </div>
                  </div>
                </div>
              </dl>
            </div>

            <div id="transport"
              v-if="currentProfile.role?.slug === 'stagiaire' || currentProfile.role?.slug === 'intervenant' || currentProfile.role?.slug === 'responsable-pedagogique'">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[4].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[4].description }}</p>
              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">A un véhicule</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile?.has_vehicle ? 'oui' : 'non' }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Puissance fiscale</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile?.vehicle_category_details?.category }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Carte grise</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900"><a :href="profile?.temporary_vehicle_registration_url"
                        target="_blank">Voir la carte grise</a></div>
                  </dd>
                </div>
              </dl>
            </div>
            <div id="bank"
              v-if="currentProfile.role?.slug === 'stagiaire' || currentProfile.role?.slug === 'intervenant' || currentProfile.role?.slug === 'responsable-pedagogique'">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[5].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[5].description }}</p>
              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Iban</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile?.iban }}</div>
                  </dd>
                </div>
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">BIC</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div class="text-gray-900">{{ profile?.bic }}</div>
                  </dd>
                </div>
              </dl>
            </div>
            <div id="newsletter" v-if="currentProfile.role?.slug === 'stagiaire'">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[6].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[6].description }}</p>
              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Inscription newsletter</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <span class="text-gray-900 status"
                      :class="profile?.subscribed_to_newsletter ? 'status7' : 'status6'">{{
                        profile?.subscribed_to_newsletter ? 'oui' : 'non' }}
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
            <div id="terms">
              <h2 class="text-base font-semibold leading-7 text-gray-900">{{ secondaryNavigation[7].name }}</h2>
              <p class="mt-1 text-sm leading-6 text-gray-500">{{ secondaryNavigation[7].description }}</p>
              <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div class="pt-6 sm:flex">
                  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Conditions d'utilisation</dt>
                  <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <span class="text-gray-900 status" :class="profile?.accepted_terms ? 'status7' : 'status6'">{{
                      profile?.accepted_terms ? 'oui' : 'non' }}
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </main>
      </div>
    </template>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

import {
  CreditCardIcon,
  UserCircleIcon,
  TruckIcon,
  BriefcaseIcon,
  ShieldCheckIcon,
  HomeIcon,
  EnvelopeIcon,
} from '@heroicons/vue/24/outline';

const store = inject('store');
const profile = ref();
const currentProfile = ref();
const currentUser = ref();
const menuProfileItems = ref([]);

const secondaryNavigation = [
  {
    name: 'Informations générales', description: 'Vos informations personnelles', href: '#general', icon: UserCircleIcon, current: true, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire', 'stagiaire']
  },
  {
    name: 'Adresse personnelle', href: '#address', description: 'Nécessaire pour les défraiements', icon: HomeIcon, current: false, auth: ['responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire', 'stagiaire']
  },
  {
    name: 'Infos professionnelles', description: 'Informations exigées par le ministère du travail', href: '#pro', icon: BriefcaseIcon, current: false, auth: ['stagiaire']
  },
  {
    name: 'Syndicat et mandats', description: 'Affiliation à un syndicat et mandat de représentation du personnel', href: '#unions', icon: ShieldCheckIcon, current: false, auth: ['stagiaire']
  },
  {
    name: 'Véhicule personnel', href: '#transport', description: 'Informations nécessaires au défraiement du transport', icon: TruckIcon, current: false, auth: ['responsable-pedagogique', 'intervenant', 'stagiaire']
  },
  {
    name: 'Coordonnées bancaires', description: 'Informations nécessaires en cas de paiement par virement. Le remboursement des frais par virement est prévu courant 2025.', href: '#bank', icon: CreditCardIcon, current: false, auth: ['responsable-pedagogique', 'intervenant', 'stagiaire']
  },
  {
    name: 'Abonnement newsletter', description: 'Je souhaite recevoir la newsletter mensuelle de l\'IRT', href: '#newsletter', icon: EnvelopeIcon, current: false, auth: ['stagiaire']
  },
  {
    name: 'Conditions d\'utilisation', description: 'Acceptation des conditons d\'utilisation', href: '#terms', icon: UserCircleIcon, current: false, auth: ['stagiaire', 'responsable-pedagogique', 'intervenant', 'administrateur', 'gestionnaire']
  }
];

const checkMenuItems = () => {
  let slug = null;
  if (profile.value) {
    slug = profile.value.role.slug;
    console.log('slug', slug);
  }
  menuProfileItems.value = secondaryNavigation.filter(item => {
    return item.auth.includes(slug)
  });
};

onMounted(async () => {
  let { user } = store;
  let { userdetails } = store;
  if (!userdetails) {
    userdetails = JSON.parse(localStorage.getItem('userdetails'));
  }
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      store.setUserDetails(userdetails);
      store.setUser(user);
    }
  }

  if (user && userdetails) {
    profile.value = user;
    console.log('profile', profile.value);
    currentProfile.value = profile.value;
    currentUser.value = userdetails;
  }
  checkMenuItems();
});

</script>
