<template>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">
        <div class="mx-auto max-w-12xl">
            <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900"><span v-if="totalItems > 0"
                        class="badge badge_unselected !bg-purple-100">{{
                    totalItems }}</span> Défraiements à gérer</h3>
            </div>
            <div class="my-2">
                <SmallLoader v-if="isLoading" :text="'Chargement en cours'" />
            </div>
            <ul role="list" class="divide-y divide-gray-100" v-if="relevantTrainings.length > 0">
                <li v-for="training in  relevantTrainings " :key="training.id"
                    class="flex items-center justify-between gap-x-6 py-5">
                    <div class="min-w-0">
                        <div class="flex items-start gap-x-3">
                            <button type="button" @click="gotoTrainingTab(training.training_id, 'Défraiements')"
                                class="text-left">
                                <p class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">{{
                    training.training_title
                }}</p>
                            </button>
                            <p>{{ training.profile_name }}</p>
                        </div>
                        <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p class="whitespace-nowrap">
                                S'est terminée le <time :datetime="training.training_end_date"> {{
                    moment(training.training_end_date).format("Do MMM YY") }}</time>
                            </p>
                        </div>

                    </div>
                    <div class="flex flex-none items-center gap-x-2">
                        <span v-for="(status, index) in  training.statuses " :key="status">
                            <span class="status status1" v-if="index === 'En cours'">
                                <EyeIcon class="h-4 w-4 mr-2" />{{
                    status.length }}
                            </span>
                            <span class="status status3" v-if="index === 'Validé'">
                                <CheckIcon class="h-4 w-4 mr-2" />{{
                    status.length }}
                            </span>
                            <span class="status status5" v-if="index === 'Mise en paiement'">
                                <CurrencyEuroIcon class="h-4 w-4 mr-2" />{{
                    status.length }}
                            </span>

                        </span>
                        <button type="button" @click="gotoTrainingTab(training.training_id, 'Défraiements')"
                            class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                            Voir<span class="sr-only">, {{ training.training_title }}</span></button>
                    </div>
                </li>
            </ul>
            <AlertMessage type="info" title="Aucune formation en cours"
                v-else-if="!isLoading && relevantTrainings.length === 0" />
            <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { EllipsisVerticalIcon, EyeIcon, CurrencyEuroIcon, CheckIcon } from '@heroicons/vue/20/solid'
import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const router = useRouter();
const profile = ref(null);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 7;
const relevantTrainings = ref([]);
const isLoading = ref(false);

const changePage = async (page) => {
    isLoading.value = true;
    try {
        const response = await client.get('/api/trainings/pending-expenses', {
            params: { page: Number(page), per_page: pageSize },
        });
        relevantTrainings.value = response.data.data;
        currentPage.value = Number(response.data.current_page);
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
        isLoading.value = false;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};

const gotoTrainingTab = (idTraining, tab) => {
    router.push({ name: 'Détails de la formation', params: { id: idTraining }, query: { tab } });
};

onMounted(async () => {
    changePage(1);
});
</script>
