/* eslint-disable */
import client from '@/api/client';

async function fetchCityByPostcode(searchTerm) {
  try {
    const response = await client.get(`api/cities/search-by-postcode?postcode=${searchTerm}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch city:', error);
  }
};

async function fetchCityByName(searchTerm) {
  try {
    const response = await client.get(`api/cities/search-by-postcode?name=${searchTerm}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch city:', error);
  }
};

async function fetchUnions() {
  try {
    const response = await client.get('/api/unions');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch unions:', error);
  }
};

async function fetchMandates() {
  try {
    const response = await client.get('/api/mandates');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch mandates:', error);
  }
};

async function fetchProfessionalSituations() {
  try {
    const response = await client.get('/api/professional-situations');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch profesional situations:', error);
  }
}

async function fetchProfessionalCategories() {
  try {
    const response = await client.get('/api/professional-categories');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch profesional categories:', error);
  }
}

async function fetchReferralSources() {
  try {
    const response = await client.get('api/referral-sources');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch referral source:', error);
  }
}

async function fetchCouncilSections() {
  try {
    const response = await client.get('/api/council-sections');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch council sections:', error);
  }
}

async function fetchCouncils() {
  try {
    const response = await client.get('/api/councils');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch councils:', error);
  }
}

async function fetchSeniorities() {
  try {
    const response = await client.get('/api/seniorities');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch seniorities:', error);
  }
}

async function fetchCompanySizes() {
  try {
    const response = await client.get('/api/company-sizes');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch company-sizes:', error);
  }
}

async function fetchActivitySectors() {
  try {
    const response = await client.get('/api/activity-sectors');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch activity sectors:', error);
  }
}

async function fetchDegrees() {
  try {
    const response = await client.get('/api/degrees');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch degrees:', error);
  }
}

async function fetchVehicleCategories() {
  try {
    const response = await client.get('/api/vehicle-categories');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch vehicle categories:', error);
  }
}
async function fetchTrainingDurations() {
  try {
    const response = await client.get('/api/training-durations');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch training durations:', error);
  }
}

async function fetchEmploymentStatus() {
  try {
    const response = await client.get('/api/employment-statuses');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch employment status:', error);
  }
}

// Export all trainings services
const fetchPersonalInfoServices = {
  fetchUnions,
  fetchMandates,
  fetchProfessionalSituations,
  fetchProfessionalCategories,
  fetchCityByPostcode,
  fetchCityByName,
  fetchReferralSources,
  fetchCouncils,
  fetchSeniorities,
  fetchCouncilSections,
  fetchCompanySizes,
  fetchActivitySectors,
  fetchDegrees,
  fetchVehicleCategories,
  fetchTrainingDurations,
  fetchEmploymentStatus
};

export default fetchPersonalInfoServices;