<template>
  <main>
    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
      <div id="pro" class="mb-20">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Informations professionnelles de {{
          trainee.user.name }}
        </h2>
        <p class="mt-1 text-sm leading-6 text-gray-500"></p>

        <dl class="mt-6 space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Situation professionnelle</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{
                trainee.professional_situation?.name }}
              </div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Catégorie professionnelle</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{
                trainee.professional_category?.name }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Secteur professionnel</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.activity_sector?.name }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Diplôme</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.degree?.name }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Nom de
              l'entreprise</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.company_name }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Taille de l'entreprise</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.company_size_id }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Code de postal de l'entreprise</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.company_zip_code }}</div>
            </dd>
          </div>
        </dl>
      </div>

      <div id="unions" class="mb-20">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Syndicats</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500"></p>

        <dl class="mt-6 space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Syndicat</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900"><span class="status status2">{{ trainee.current_union?.name }}</span></div>
            </dd>
          </div>
        </dl>
      </div>
      <div id="mandates">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Mandats</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500"></p>

        <dl class="mt-6 space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        </dl>
      </div>
      <div>
        <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 pt-10">
          <li v-for="(mandate) in trainee.mandates" :key="mandate.name" class="col-span-1 flex rounded-md shadow-sm">
            <div class="flex flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
              <div class="flex-1 truncate px-4 py-4 text-sm">
                <div class="font-semibold text-gray-900 hover:text-gray-600 mb-3">{{ mandate.name
                  }}</div>
                <p class="text-gray-500">{{ mandate.label }}</p>
                <p><span class="status status2" v-if="mandate.pivot?.council_id">{{
                  getCouncil(mandate.pivot?.council_id) }}</span></p>
                <p><span class="status status3" v-if="mandate.pivot.council_section_id">{{
                  getCouncilSection(mandate.pivot.council_section_id) }}</span></p>
              </div>
              <div class="flex-shrink-0 pr-4">
                <span v-if="mandate.pivot?.is_valid === 1" class="ml-2 status status5">actif</span> <span
                  class="ml-2 status status1" v-else>inactif</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, onMounted } from 'vue';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';

defineProps({
  trainee: Object,
});
const relevantCouncils = ref([]);
const relevantCouncilSections = ref([]);

const getCouncil = ((id) => {
  const council = relevantCouncils.value.find(item => item.id === id);
  if (council) {
    return council.name;
  }
  return '';
});
const getCouncilSection = ((id) => {
  const councilSection = relevantCouncilSections.value.find(item => item.id === id);
  if (councilSection) {
    return councilSection.name;
  }
  return '';
});

onMounted(() => {
  fetchPersonalInfoServices.fetchCouncils().then((data) => {
    relevantCouncils.value = data;
  });
  fetchPersonalInfoServices.fetchCouncilSections().then((data) => {
    relevantCouncilSections.value = data;
  });
});
</script>
