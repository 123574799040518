<template>
  <BigLoader v-if="isLoading" :title="'Dépense en cours de chargement'" :text="'Préparation en cours'" />
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <ExpenseUpload v-if="displayExpenseUpload" @close="displayExpenseUpload = false" style="z-index: 2000;"
    :expense="Number(selectedExpense)" @savedReceipt="receiptResponse" />
  <div style="z-index: 20000;">
    <TrainingExpenseDetail v-if="openExpenseDetail" :expense="selectedExpense" :username="trainee.user.name"
      :role="trainee.role.name" :idExpense="selectedExpense.expenses[0].id" :idProfile="trainee.id"
      :training="selectedTraining" :key="expenseKey" @close="closeExpenseDetail" />
    <ConfirmationDialog @confirm="deleteExpense(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
      :title="confirmTexts[0].title" :message="confirmTexts[0].message"
      :primaryActionLabel="confirmTexts[0].primaryActionLabel"
      :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
  </div>
  <main>
    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
      <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Remboursements de {{ trainee.user.name }}</h3>
        <div class="mt-3 sm:ml-4 sm:mt-0">
        </div>
      </div>
      <ul role="list" class="divide-y divide-gray-100">
        <li v-for=" expense in expenses" :key="expense.id" class="flex items-center justify-between gap-x-6 py-5">
          <div class="min-w-0">
            <div class="flex items-start gap-x-3">
              <router-link :to="'/formations/' + expense.training_id">
                <p class="text-sm font-semibold leading-6 text-gray-900">
                  {{ expense.training_title }}
                </p>
              </router-link>
              <p :class="getClassByStatus(expense.expenses[0].status)">
                {{ expense.expenses[0].status }}
              </p>

            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate">Référence : {{ expense.expenses[0].payment_reference }}</p>
            </div>
            <div><span class="status status5 mt-3">{{ expense.expenses[0].receipts.length }} justificatifs</span></div>

          </div>
          <div class="flex flex-none items-center gap-x-4">
            <button @click.prevent="openExpense(expense)"><span class="status status4 !text-base"
                v-if="expense.expenses[0].total_forced_amount !== '0.00'">{{
                  expense.expenses[0].total_forced_amount }} €</span><span class="status status4 !text-base" v-else>{{
                  expense.expenses[0].amount }} €</span></button>
            <a href="" @click.prevent="openExpenseDetailModal" @keydown="openExpenseDetailModal"
              v-if="expense.expenses[0].payment_order_number"
              class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">{{
                expense.expenses[0].payment_order_number }}<span class="sr-only">, {{ expense.training_title }}</span></a>
            <button type="button" @click="displayExpenseUpload = true; selectedExpense = expense.expenses[0].id"
              class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Ajouter
              des pièces</button>
            <Menu as="div" class="relative flex-none" v-if="expense.expenses[0].status === 'En cours'">
              <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span class="sr-only">Open options</span>
                <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>
              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                  <button @click.prevent="openExpense(expense)" :class="[
                    active ? 'bg-gray-50' : '',
                    'block px-3 py-1 text-sm leading-6 text-gray-900',
                  ]
                    ">Editer<span class="sr-only">, {{ expense.training_title }}</span></button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <a href="#" @click.prevent="displayConfirmDeleteDialog = true; idTypeToEdit = expense.expenses[0].id"
                    :class="[
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900',
                    ]
                      ">Supprimer<span class="sr-only">, {{ expense.training_title }}</span></a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <Menu as="div" class="relative flex-none" v-else>
              <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span class="sr-only">Open options</span>
                <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>
              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                  <button @click.prevent="openExpense(expense)" :class="[
                    active ? 'bg-gray-50' : '',
                    'block px-3 py-1 text-sm leading-6 text-gray-900',
                  ]
                    ">Editer<span class="sr-only">, {{ expense.training_title }}</span></button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </li>
      </ul>
      <AlertMessage title="aucun défraiement encours ou passé" type="info" v-if="expenses.length === 0" class="mt-10" />
    </div>
  </main>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
import ExpenseUpload from '@/components/expenses/ExpenseUpload.vue';
import TrainingExpenseDetail from '@/components/trainings/TrainingExpenseDetail.vue';
import client from '@/api/client';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import BigLoader from '@/components/app/BigLoader.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

const getClassByStatus = (status) => {
  switch (status) {
    case 'Remboursée':
      return 'text-green-700 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset';
    case 'En retard':
      return 'text-yellow-800 bg-yellow-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset';
    case 'En attente de pièces':
      return 'text-yellow-600 bg-yellow-100 ring-yellow-500/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset';
    case 'À payer':
      return 'text-blue-700 bg-blue-50 ring-blue-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset';
    default:
      return 'text-gray-700 bg-gray-50 ring-gray-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset';
  }
};

const props = defineProps({
  trainee: Object,
});

const expenses = ref([]);
const isLoading = ref(false);
const selectedExpense = ref([]);
const selectedTraining = ref([]);

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayExpenseUpload = ref(false);
const componentKey = ref(0);
const idTypeToEdit = ref(null);
const displayConfirmDeleteDialog = ref(false);
const expenseKey = ref(0);

const receiptResponse = (type, response) => {
  displayExpenseUpload.value = false;
  if (type === 'success') {
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, le justificatif a bien été ajouté';
    notificationMessage.value = '';
    fetchExpenses();
  } else {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement';
    notificationMessage.value = response;
  }
}

const confirmTexts = [
  {
    title: 'Supprimer ce défraiement ?',
    message: 'Voulez-vous supprimer ce défraiement ?',
    primaryActionLabel: 'supprimer',
    secondaryActionLabel: 'annuler'
  },
]
const openExpenseDetail = ref(false);

const openExpenseDetailModal = () => {
  // Todo: open expense detail and pass the selected expense
  openExpenseDetail.value = true;
};

const closeExpenseDetail = () => {
  // Todo: close expense detail
  openExpenseDetail.value = false;
  componentKey.value += 1;
};

const openExpense = (expense) => {
  isLoading.value = true;
  selectedExpense.value = expense;
  fetchTrainingsServices.fetchTraining(expense.expenses[0].training_id).then((data) => {
    selectedTraining.value = data;
    expenseKey.value += 1;
    openExpenseDetail.value = true;
    isLoading.value = false;
  });

};

const deleteExpense = async (itemToDelete) => {
  displayConfirmDeleteDialog.value = false;
  try {
    const response = await client.delete(`api/training-expenses/${itemToDelete}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, le document a bien été supprimé ';
    notificationMessage.value = '';
    fetchExpenses();
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};

const fetchExpenses = async () => {
  try {
    isLoading.value = true;
    const response = await client.get(`api/profiles/${props.trainee.id}/expenses`);
    expenses.value = response.data.expenses;
    isLoading.value = false;
  } catch (error) {

  }
};
onMounted(async () => {
  fetchExpenses();
});
</script>
