<template>
    <SearchTraining v-if="displaySearchTraining" @close="searchOpen = false" :context="searchContext" />
    <SearchUser v-if="displaySearchUser" @close="searchOpen = false" :context="searchContext" />
    <TermsAcceptance :isOpen="acceptanceRequired" v-if="acceptanceRequired" @close="closeAcceptanceModal"
        style="z-index:2000; position:absolute" />
    <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 2xl:hidden" @click="$emit('open')">
            <span class=" sr-only">Ouvrir</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-200 2xl:hidden" aria-hidden="true" />
        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <button class="relative flex flex-1" @click.prevent="triggerSearch">
                <label for="search-field" class="sr-only">Rechercher</label>
                <MagnifyingGlassIcon class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                    aria-hidden="true" />
            </button>
            <div class="flex items-center gap-x-4 lg:gap-x-6">
                <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                    v-if="notifications.length">
                    <span class="sr-only">Notifications</span>
                    <BellIcon class="h-6 w-6" aria-hidden="true" />
                </button>
                <!-- Separator -->
                <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true"
                    v-if="notifications.length" />

                <!-- Profile dropdown -->
                <Menu as="div" class="relative" v-if="currentUserProfile">
                    <MenuButton class="-m-1.5 flex items-center p-1.5">
                        <span class="sr-only">Menu</span>
                        <img class="h-8 w-8 rounded-full bg-gray-50  object-cover" :src="currentUserProfilePic" alt=""
                            v-if="currentUserProfilePic" />
                        <UserCircleIcon class="h-6 w-6 text-gray-400" v-else />
                        <span class="hidden lg:flex lg:items-center">
                            <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{{
                                currentUserName }}</span>
                            <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </MenuButton>
                    <transition enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
                        <MenuItems
                            class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                            <router-link v-if="item.href" :to="item.href"
                                :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']">
                                {{ item.name }}
                            </router-link>
                            <a href="#" v-else @click.prevent="item.action ? item.action(router) : {}"
                                :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']">
                                {{ item.name }}
                            </a>
                            </MenuItem>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import {
    ref, computed, onMounted, inject, watch
} from 'vue';
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/vue';
import {
    Bars3Icon,
    BellIcon, UserCircleIcon
} from '@heroicons/vue/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { useRouter, useRoute } from 'vue-router';
import SearchTraining from '@/components/search/SearchTraining.vue';
import SearchUser from '@/components/search/SearchUser.vue';
import TermsAcceptance from '../terms/TermsAcceptance.vue';

const route = useRoute();
const searchContext = computed(() => route.meta.searchContext);
const displaySearchTraining = computed(() => (searchContext.value === 'trainings' || searchContext.value === 'catalog' || searchContext.value === 'dashboard') && searchOpen.value);
const displaySearchUser = computed(() => (searchContext.value === 'trainees' || searchContext.value === 'teachers' || searchContext.value === 'team') && searchOpen.value);
const searchOpen = ref(false);

const router = useRouter();
const searchQuery = ref('');
const store = inject('store');

const GoodByeUser = () => {
    store.logOutUser(router);
};
const userNavigation = [
    { name: 'Mon profil', href: '/profil' },
    { name: 'Déconnexion', action: GoodByeUser },
];

const currentUserProfilePic = ref('');
const currentUserName = ref('');
const currentUserRole = ref('');
const currentUserProfile = ref('');
const notifications = ref([]);

const sidebarOpen = ref(false);
const emit = defineEmits(['open']);

const triggerSearch = () => {
    console.log('context', searchContext.value);
    searchOpen.value = true;
};

const acceptanceRequired = ref(false);

const closeAcceptanceModal = () => {
    acceptanceRequired.value = false;
};

watch(() => store.user, (newProfile) => {
    if (newProfile) {
        currentUserProfilePic.value = newProfile.temporary_profile_picture_url;
    }
});

onMounted(() => {
    let { user } = store;
    if (!user) {
        user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            store.setUser(user);
        }
    }

    if (user) {
        currentUserName.value = user.username;
        currentUserRole.value = user.role.name;
        currentUserProfile.value = user;
        currentUserProfilePic.value = user.temporary_profile_picture_url;
        if (user.accepted_terms === 0) {
            acceptanceRequired.value = true;
        }
        store.setUser(user);
    }
});
</script>
