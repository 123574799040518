<template>
  <main v-if="trainee">
    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
      <div
        class="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div class="lg:col-start-3 lg:row-end-1">
          <h2 class="sr-only">Résumé</h2>

          <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
            <dl class="flex flex-wrap">
              <div class="flex-auto pl-6 pt-6">
                <dd class="mt-1 text-base font-semibold leading-6 text-gray-900">{{ trainee.role?.name }}</dd>
                <dd>{{ trainee.profile_number }}</dd>
              </div>
              <div class="flex-none self-end px-6 pt-4">
                <dt class="sr-only">Statut</dt>
                <dd>
                  <span
                    class="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20"
                    v-if="trainee.is_active">Actif</span>
                  <span
                    class="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-600/20"
                    v-else>Inactif</span>
                </dd>
              </div>
              <div class="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6"
                v-if="trainee.title && trainee.role?.slug !== 'stagiaire'">
                <dt class="flex-none">
                  <span class="sr-only">Titre</span>
                  <UserCircleIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm font-medium leading-6 text-gray-900">{{ trainee.title }}</dd>
              </div>
              <div class="mt-4 flex w-full flex-none gap-x-4 px-6"
                v-if="trainee.title && trainee.role?.slug !== 'stagiaire'">
                <dt class=" flex-none">
                  <span class="sr-only">Statut</span>
                  <UserCircleIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm font-medium leading-6 text-gray-900">{{ trainee.employment_status_name }}</dd>
              </div>
              <div class="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt class="flex-none">
                  <span class="sr-only">Date d'inscription</span>
                  <CalendarDaysIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm leading-6 text-gray-500">
                  <span>création de compte le : </span>
                  <div><time :datetime="trainee.joinedDate">{{
    moment(trainee.user?.created_at).format("Do MMM YYYY") }}</time></div>
                </dd>
              </div>
              <div class="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt class="flex-none">
                  <span class="sr-only">Email</span>
                  <EnvelopeIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm leading-6 text-gray-500">{{ trainee.user?.email }}</dd>
              </div>
              <div class="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt class="flex-none">
                  <span class="sr-only">Phone</span>
                  <PhoneIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm leading-6 text-gray-500">{{ trainee.phone }}</dd>
              </div>
              <div class="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt class="flex-none">
                  <span class="sr-only">Status</span>
                  <TruckIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm leading-6 text-gray-500">{{ trainee.vehicle_category_details?.category }}</dd>
              </div>
              <div class="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt class="flex-none">
                  <span class="sr-only">Status</span>
                  <IdentificationIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm leading-6 text-gray-500"><a :href="trainee.temporary_vehicle_registration_url"
                    v-if="trainee.temporary_vehicle_registration_url" alt="carte-grise" target="_blank">Voir la carte
                    grise</a></dd>
              </div>
            </dl>
            <div class="mt-6 border-t border-gray-900/5 px-6 py-6">
              <a :href="'mailto:' + trainee.user?.email" class="text-sm font-semibold leading-6 text-gray-900">Contacter
                <span aria-hidden="true">&rarr;</span></a>
            </div>
          </div>
          <div class="mt-12">
            <AccountManagement :profile="trainee"
              v-if="trainee.is_active === 1 && (currentUserRole.role?.slug == 'gestionnaire' || currentUserRole.role?.slug == 'administrateur')"
              @confirm="disableAccount" />
          </div>
          <router-link :to="'/equipe/modifier-un-compte/' + trainee.id"><button class="btn-secondary mt-5"
              v-if="(profile.role?.slug === 'responsable-pedagogique' || profile.role?.slug === 'administrateur' || profile.role?.slug === 'intervenant') && trainee.role.slug === 'intervenant'">modifier
              le compte</button></router-link>
        </div>

        <!-- History -->
        <div
          class="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">

          <div class="flex items-center mb-10">
            <span class="relative inline-block">
              <img class="h-16 w-16 rounded-full" v-if="trainee.temporary_profile_picture_url"
                :src="trainee.temporary_profile_picture_url" alt="" />
              <UserCircleIcon class="h-16 w-16 text-gray-400" v-else />
              <span class="absolute bottom-0 right-0 block h-4 w-4 rounded-full ring-2 ring-white"
                :class="trainee.profile_completion === 100 ? 'bg-green-400' : 'bg-red-400'" />
            </span>
            <h1 class="font-bold ml-3 text-2xl text-gray-900">{{ trainee.user?.name }}</h1>
          </div>
          <dl class="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
            <div class="sm:pr-4">
              <dt class="inline text-gray-500">Dernière mise à jour le</dt>
              <dd> {{ moment(trainee.updated_at).format("Do MMM YY -HH:mm") }}</dd>
              <dd class="inline text-gray-700">
                <time :datetime="trainee.lastUpdate">{{ trainee.lastUpdateFormatted }}</time>
              </dd>
            </div>
            <div class="mt-2 sm:mt-0 sm:pl-4">
              <div v-if="profile?.role?.slug === 'stagiaire'">
                <dt class="inline text-gray-500">Syndicat : </dt>
                {{ " " }}
                <dd class="inline text-gray-700"> <span class="status status3 ml-2">{{ trainee.current_union?.name
                    }}</span>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
              <dt class="font-semibold text-gray-900">Adresse</dt>
              <dd class="mt-2 text-gray-500">
                <span class="font-medium text-gray-900">{{ trainee.full_address }}</span>
              </dd>
            </div>
            <div class="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
              <div v-if="profile?.role?.slug === 'stagiaire' && trainee.company_name">
                <dt class="font-semibold text-gray-900">Employeur</dt>
                <dd class="mt-2 text-gray-500">
                  <span
                    class="mb-2 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium bg-blue-100 text-blue-700">{{
    trainee.company_name }}</span><br />

                  <span class="font-medium text-gray-900"></span><br />Taille
                  de l'entreprise : {{ trainee.company_size?.name }}
                </dd>
              </div>
            </div>
          </dl>
          <table class="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
            <colgroup>
              <col class="w-full" />
              <col />
              <col />
              <col />
            </colgroup>
            <thead class="border-b border-gray-200 text-gray-900">
              <tr>
                <th scope="col" class="px-0 py-3 font-semibold">Formations <span
                    v-if="trainee.role?.slug === 'stagiaire'">suivies par le stagaire</span><span
                    v-else>associées</span></th>
                <th scope="col" class="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">Dates</th>
              </tr>
            </thead>
            <tbody v-if="trainee.role?.slug === 'stagiaire'">
              <tr v-for="item in _.orderBy(trainee.trainings, 'start_date', 'desc')" :key="item.id"
                class="border-b border-gray-100">
                <td class="max-w-0 px-0 py-5 align-top">
                  <router-link :to="'/formations/' + item.id">
                    <div class="truncate font-semibold text-gray-900">{{ item.title }}</div>
                    <div class="truncate text-gray-500">{{ displayPlace(item.training_place_id) }}</div>
                  </router-link>
                </td>
                <td class="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                  <div>{{
    moment(item.start_date).format("Do MMM YY") }}</div>
                  <div>{{
    moment(item.end_date).format("Do MMM YY") }}</div>
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr v-for="item in _.orderBy(relevantTrainings, 'start_date', 'desc')" :key="item.id"
                class="border-b border-gray-100">
                <td class="max-w-0 px-0 py-5 align-top">
                  <router-link :to="'/formations/' + item.id">
                    <div class="truncate font-semibold text-gray-900">{{ item.title }}</div>
                    <div class="truncate text-gray-500">{{ displayPlace(item.training_place_id) }}</div>
                  </router-link>
                </td>
                <td class="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                  <div>{{
    moment(item.start_date).format("Do MMM YY") }}</div>
                  <div>{{
    moment(item.end_date).format("Do MMM YY") }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/vue/20/solid';
import { EnvelopeIcon, IdentificationIcon, PhoneIcon, TruckIcon } from '@heroicons/vue/24/outline';
import AccountManagement from '@/components/trainees/AccountManagement.vue';
import { defineProps } from 'vue';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import { orderBy } from 'lodash';
const _ = require("lodash");
import client from '@/api/client';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale
  ('fr');

const props = defineProps({
  trainee: [Object, Array],
  currentUserRole: [Object, Array],
});
const emit = defineEmits(['confirm']);
const store = inject('store');
const componentKey = ref(0);
const relevantPlaces = ref([]);
const profile = ref([]);
const relevantTrainings = ref([]);

const disableAccount = () => {
  console.log('disableAccount');
  emit('confirm');
};
const displayPlace = (id) => {
  const place = relevantPlaces.value.find((item) => item.id === id);
  return place ? place.name : '';
};
onMounted(async () => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  componentKey.value += 1;
  fetchTrainingsServices.fetchTrainingPlaces().then((data) => {
    relevantPlaces.value = data;
  });
  if (props.trainee?.role?.slug !== 'stagiaire') {
    try {
      const response = await client.get(`/api/profiles/${props.trainee.id}/trainings`);
      relevantTrainings.value = response.data.trainings;
    } catch (error) {
      console.error('Failed to fetch trainings:', error);
    }
  }
});
</script>
