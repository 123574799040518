<template>
  <AppLayout>
    <div>
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Choisissez un onglet</label>
        <select id="tabs" name="tabs"
          class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          @change="updateCurrentTab($event.target.value)">
          <option v-for="tab in menuProfileItems" :key="tab.name" :value="tab.name" :selected="tab.current">
            {{ tab.name }}
          </option>
        </select>
      </div>
      <div class="hidden sm:block">

        <nav class="flex justify-center space-x-4" aria-label="Tabs"><span v-for="tab in menuProfileItems"
            :key="tab.name" href="javascript:void(0)">
            <button v-if="tab.profile.includes(profile?.role.slug)" @click.prevent="updateCurrentTab(tab.name)" :class="[
            tab.current
              ? 'bg-indigo-100 text-indigo-700'
              : 'text-gray-500 hover:text-gray-700',
            'rounded-md px-3 py-2 text-sm font-medium',
          ]" :aria-current="tab.current ? 'page' : undefined">{{
            tab.name }}</button></span>
        </nav>
      </div>
      <div v-if="currentTab.name">
        <PersonalInformations v-if="currentTab.name === 'Informations personnelles'" :trainee="trainee"
          :key="componentKey" @confirm="fetchTraineeDetails(route.params.id)" :currentUserRole="profile" />
        <UnionsMandates v-else-if="currentTab.name === 'Informations professionelles'" :trainee="trainee" />
        <TraineesExpenses v-else-if="currentTab.name === 'Remboursements et justificatifs'" :trainee="trainee" />
        <BankData v-else-if="currentTab.name === 'Coordonnées bancaires'" :trainee="trainee" />
        <TeacherTrainingsList v-else-if="currentTab.name === 'Formations'" :teacher="trainee" :key="componentKey" />
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import AppLayout from '@/layouts/AppLayout.vue';
import { ref, computed, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import PersonalInformations from '@/components/trainees/PersonalInformations.vue';
import UnionsMandates from '@/components/trainees/UnionsMandates.vue';
import TraineesExpenses from '@/components/trainees/TraineesExpenses.vue';
import BankData from '@/components/trainees/BankData.vue';
import client from '@/api/client';
import TeacherTrainingsList from '@/components/teachers/TeacherTrainingsList.vue';

const tabs = ref([
  { name: 'Informations personnelles', href: '#', current: true, auth: 'all', profile: ['intervenant', 'responsable-pedagogique', 'administrateur', 'gestionnaire'] },
  { name: 'Informations professionelles', href: '#', current: false, auth: ['stagiaire'], profile: ['intervenant', 'responsable-pedagogique', 'administrateur', 'gestionnaire'] },
  {
    name: 'Remboursements et justificatifs', href: '#', current: false, auth: 'all', profile: ['administrateur', 'gestionnaire'],
  },
  { name: 'Coordonnées bancaires', href: '#', current: false, auth: ['intervenant', 'responsable-pedagogique', 'stagiaire'], profile: ['administrateur'] },
  { name: 'Formations', href: '#', current: false, auth: ['intervenant', 'responsable-pedagogique'], profile: ['intervenant', 'responsable-pedagogique', 'administrateur', 'gestionnaire'] },
]);
const store = inject('store');
const profile = ref();
const menuProfileItems = ref([]);
const componentKey = ref(0);

const currentTab = computed(() => {
  const activeTab = menuProfileItems.value.find((tab) => tab.current);
  return activeTab || tabs.value[0];
});

const updateCurrentTab = (selectedTabName) => {
  menuProfileItems.value = menuProfileItems.value.map((tab) => ({
    ...tab,
    current: tab.name === selectedTabName,
  }));
};

const trainee = ref(null);
const route = useRoute();

const fetchTraineeDetails = async (id) => {
  try {
    const response = await client.get(`/api/profiles/${id}`);
    trainee.value = response.data;
    componentKey.value += 1;
    checkMenuItems();
  } catch (error) {
    console.error('Failed to fetch trainee details:', error);
  }
};

const checkMenuItems = () => {
  let slug = null;
  if (trainee.value) {
    slug = trainee.value.role.slug;
  }
  menuProfileItems.value = tabs.value.filter(item => {
    return item.auth === 'all' || item.auth.includes(slug)
  });
};

onMounted(() => {
  let { user } = store;
  if (!user) {
    user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      store.setUser(user);
    }
  }

  if (user) {
    profile.value = user;
  }
  const traineeId = route.params.id;
  if (traineeId) {
    fetchTraineeDetails(traineeId);
  }
});
</script>
