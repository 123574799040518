<template>
  <SimpleNotification :show="showNotification" :type="notificationType" message="Copie réussie !"
    detail="L'IBAN a été copié dans le presse-papiers." @close="showNotification = false" />
  <main>
    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
      <div class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            Compte de {{ trainee.user.name }}
          </h3>
          <div class="mt-5">
            <div class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
              <h4 class="sr-only">Visa</h4>
              <div class="sm:flex sm:items-start">
                <div class="sm:flex-shrink-0">
                  <BanknotesIcon class="h-8 w-auto sm:h-6 sm:flex-shrink-0" />
                </div>
                <div class="mt-3 sm:ml-4 sm:mt-0">
                  <div class="text-sm font-medium text-gray-900">
                    {{ trainee.iban }} - {{ trainee.bic }}
                  </div>
                </div>
              </div>
              <div class="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                <button type="button" @click="copyToClipboard(iban.number)"
                  class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Copier
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { BanknotesIcon } from '@heroicons/vue/20/solid';
import { ref } from 'vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

defineProps({
  trainee: Object,
});

const iban = ref({ number: 'NL25INGB5660751954', date: '2 janvier 2021' });
const showNotification = ref(false);

const notificationType = ref('success');

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    notificationType.value = 'success';
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
  } catch (err) {
    notificationType.value = 'error';
    showNotification.value = true;
    console.error('Erreur lors de la copie : ', err);
  }
};

</script>
