<template>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">
        <div class="mx-auto max-w-12xl">
            <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900"><span v-if="totalItems > 0"
                        class="badge badge_unselected !bg-purple-100">{{
                            totalItems }}</span> Formations en cours</h3>
            </div>
            <div class="my-2">
                <SmallLoader v-if="isLoading" :text="'Chargement en cours'" />
            </div>
            <ul role="list" class="divide-y divide-gray-100" v-if="relevantTrainings.length > 0">
                <li v-for="training in relevantTrainings" :key="training.id"
                    class="relative flex justify-between gap-x-6 py-5">
                    <div>
                        <router-link :to="'/formations/' + training.id">
                            <p class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">
                                <a :href="training.href" class="">{{ training.title }}</a>

                            </p>
                        </router-link>
                        <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p class="flex items-center mb-1">
                                <MapPinIcon class="h-4 w-4 mr-1" /><a class=" leading-4">{{
                                    training.place?.name
                                    }}</a>
                            </p>
                        </div>
                        <p class="text-xs text-gray-500 flex items-center mb-1">
                            <CalendarIcon class="h-4 w-4 mr-1" />
                            du {{ moment(training?.start_date).format("Do MMM YY") }} au {{
                                moment(training?.end_date).format("Do MMM YY") }}
                        </p>
                    </div>
                    <p class="truncate"><span class="status status3">{{ training.registered_count }} inscrits /
                            {{ training.max_attendees }}
                            places</span></p>
                    <router-link :to="'/formations/' + training.id"><button type="button"
                            class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                            Voir<span class="sr-only">, {{ training.training_title }}</span></button></router-link>
                </li>
            </ul>
            <AlertMessage type="info" title="Aucune formation pour le moment" class="mb-4 m"
                v-if="!isLoading && relevantTrainings.length === 0" />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import SmallLoader from '@/components/app/SmallLoader.vue';
import { ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/vue/20/solid';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import moment from 'moment';
import 'moment/locale/fr';
import { MapPinIcon, CalendarIcon, UserIcon } from '@heroicons/vue/24/outline';

moment.locale('fr');

const profile = ref(null);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 7;
const relevantTrainings = ref([]);
const isLoading = ref(false);
const router = useRouter();

const gotoTrainingCreation = () => {
    router.push('/formations/creer-une-formation');
};

const changePage = async (page) => {
    isLoading.value = true;
    try {
        fetchTrainingsServices.fetchTrainings(page, pageSize, 'ongoing').then((data) => {
            relevantTrainings.value = data.data;
            currentPage.value = Number(data.current_page);
            isLoading.value = false;
            totalPages.value = data.last_page;
            totalItems.value = data.total;
        });
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};
onMounted(() => {
    changePage(1);
});
</script>
